import React, { useEffect, useState } from "react";
import "../Advertisement/Advertisement.css";
import "../Classes Management/ClassManagement.css";
import { Table } from "antd";
import { connect, useDispatch } from "react-redux";
import { Spin } from "antd";
import { AiOutlineEdit } from "react-icons/ai";
import { Drawer, Button, Modal } from "antd";
import { Form, Input } from "antd";
import { Select, Tooltip } from "antd";
import TableSkeleton from "../../Components/Table Skeleton/TableSkeleton";
import EditLoyaltyPoints from "./EditLoyaltyPoints";
import SiderDemo from "../Layout/SiderDemo";
import moment from "moment";

const { Option } = Select;

const Advertisement = ({ LoyaltyPoints }) => {
  const dispatch = useDispatch();
  const [loyaltyId, setLoyaltyId] = useState();
  const [form] = Form.useForm();
  const [activeData, setActiveData] = useState();
  const [visible, setVisible] = useState(false);
  const [size, setSize] = useState("large");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const onFinish = (values) => {
    console.log("Received values of form: ", values);

    dispatch({
      type: "ADD_LOYALTY_REQUEST",
      payload: values,
    });
    setIsModalVisible(false);
    form.resetFields();
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onClose = () => {
    setVisible(false);
  };

  useEffect(() => {
    dispatch({
      type: "GET_LOYALTY_REQUEST",
      payload: {
        page: 1,
        pageSize: 10,
      },
    });
  }, [LoyaltyPoints.isLoyaltyPointsChanged]);

  const sharedOnCell = (_, index) => {
    if (index === 4) {
      return { colSpan: 0 };
    }
  };

  const columns = [
    {
      title: "Scheme Name",
      dataIndex: "schemeName",

      onCell: sharedOnCell,
      width: 300,
      align: "center",
    },

    {
      title: "Loyalty Points",
      dataIndex: "loyaltyPoints",
      onCell: sharedOnCell,
      width: 250,
      align: "center",
    },
    // {
    //   title: "Equivalent Currency",
    //   dataIndex: "equivalentCurrency",
    //   onCell: sharedOnCell,
    //   width: 150,
    //   align: "center",
    // },
    // {
    //   title: "Currency Type",
    //   dataIndex: "currencyType",
    //   onCell: sharedOnCell,
    //   width: 150,
    //   align: "center",
    // },
    {
      title: "Added At",
      dataIndex: "addedDate",
      onCell: sharedOnCell,
      width: 250,
      align: "center",
    },
    {
      title: "Added By",
      dataIndex: "addedAdminFullName",
      onCell: sharedOnCell,
      width: 200,
      align: "center",
    },

    // {
    //   title: "Image",
    //   dataIndex: "image",
    //   onCell: sharedOnCell,
    //   width: 250,
    //   align: "center",
    // },
    // {
    //   title: "Promotion Type",
    //   dataIndex: "promotionType",
    //   onCell: sharedOnCell,
    //   width: 250,
    //   align: "center",
    // },
    {
      title: "Actions",
      dataIndex: "actions",
      onCell: sharedOnCell,
      align: "center",
      width: 250,
    },
  ];
  console.log(LoyaltyPoints.loyaltyPoints);
  const data =
    LoyaltyPoints.loyaltyPoints.data &&
    LoyaltyPoints.loyaltyPoints.data.map((loyal) => {
      return {
        schemeName: loyal.schemeName,
        loyaltyPoints: loyal.loyaltyPoints,
        equivalentCurrency: loyal.equivalentCurrency,
        currencyType: loyal.currencyType,
        //   active: !ad.active ? (
        //     <Tag color="red">Not Active</Tag>
        //   ) : (
        //     <Tag color="green">Active</Tag>
        //   ),
        addedDate: moment(loyal.addedDate).format("YYYY-MM-DD"),
        addedAdminFullName: loyal.addedAdminFullName,

        actions: (
          <div className="action">
            {" "}
            <Tooltip title="Edit Loyalty Point">
              <AiOutlineEdit
                className="icon"
                onClick={() => {
                  setVisible(true);
                  setLoyaltyId(loyal.id);
                  setActiveData(loyal);
                }}
              />
            </Tooltip>
          </div>
        ),
      };
    });

  return (
    <>
      <div className="container">
        <Modal
          style={{ marginTop: "-3rem" }}
          title="New Scheme"
          visible={isModalVisible}
          onCancel={handleCancel}
          footer={null}
        >
          <Spin spinning={LoyaltyPoints.isLoading}>
            <Form
              name="form"
              encType="multipart/form-data"
              form={form}
              className="login-form"
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
            >
              <Form.Item
                label="Scheme Name"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  width: "100%",
                }}
                name="schemeName"
                rules={[
                  {
                    required: true,
                    message: "Please input scheme name!",
                  },
                ]}
              >
                <Select placeholder="Scheme Name">
                  <Option value="REFERRER_CUSTOMER">REFERRER_CUSTOMER</Option>
                  <Option value="REFERRED_CUSTOMER">REFERRED_CUSTOMER</Option>
                </Select>
              </Form.Item>
              <Form.Item
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  width: "100%",
                }}
                label="Loyalty Points"
                name="loyaltyPoints"
                rules={[
                  {
                    required: true,
                    message: "Please input loyalty points!",
                  },
                ]}
              >
                <Input placeholder="Loyalty Points" style={{ width: "100%" }} />
              </Form.Item>

              <Button
                type="primary"
                htmlType="submit"
                style={{ margin: "auto", width: "100%" }}
                className="login-form-button"
              >
                Add
              </Button>
            </Form>
          </Spin>
        </Modal>

        <SiderDemo>
          <div>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: "2rem",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {" "}
                <h2 className="title-course">Loyalty Points Scheme</h2>
                <Button
                  type="primary"
                  style={{ background: "#FF4D4F" }}
                  danger
                  onClick={showModal}
                >
                  Add
                </Button>
              </div>
            </div>

            {/* <nav class="menu"></nav> */}
            {LoyaltyPoints.isLoading ? (
              <TableSkeleton />
            ) : (
              <Table
                columns={columns}
                dataSource={data}
                pagination={false}
                bordered
                className="table-course"
              />
            )}

            <Drawer
              title={<span style={{}}>Edit Loyalty Point</span>}
              placement="right"
              width={"85%"}
              size={size}
              onClose={onClose}
              visible={visible}
            >
              <EditLoyaltyPoints
                onClose={onClose}
                data={activeData}
                loyaltyPoints={LoyaltyPoints}
              />
            </Drawer>
          </div>
        </SiderDemo>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    LoyaltyPoints: state.LoyaltyPoints,
  };
};

export default connect(mapStateToProps, {})(Advertisement);
