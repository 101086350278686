import React, { useEffect, useState } from "react";
import "../Advertisement/Advertisement.css";
import "../Classes Management/ClassManagement.css";
import { Button, Card } from "antd";
import { connect, useDispatch, useSelector } from "react-redux";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import SiderDemo from "../Layout/SiderDemo";

const TermsAndCondition = ({ TermsAndPrivacy }) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState(
    useSelector(
      (state) => state.TermsAndPrivacy.termsAndCondition.termsAndCondition
    )
  );

  useEffect(() => {
    dispatch({
      type: "GET_TERMS_REQUEST",
    });
    setValue(TermsAndPrivacy.termsAndCondition.termsAndCondition);
  }, [TermsAndPrivacy.isTermsAndPrivacyChanged]);

  const updateTermsHandler = () => {
    dispatch({
      type: "ADD_PRIVACY_TERMS_REQUEST",
      payload: {
        text: value,
        textFor: "TERMS",
      },
    });
  };
  console.log(value);
  return (
    <>
      <div className="container">
        <SiderDemo>
          <div>
            <Card
              className="card"
              style={{ width: "100%", padding: "5px" }}
              loading={TermsAndPrivacy.isLoading}
            >
              {" "}
              <div
                style={{
                  background: "#5D3BED",
                  height: "50px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  paddingLeft: "20px",
                  color: "white",
                  fontWeight: "normal",
                }}
              >
                Terms & Conditions
              </div>
              <ReactQuill
                style={{ background: "white" }}
                modules={{
                  toolbar: {
                    container: [
                      [{ header: [1, 2, 3, 4, 5, 6, false] }],
                      ["bold", "italic", "underline"],
                      [{ list: "ordered" }, { list: "bullet" }],
                      [{ align: [] }],
                      ["link", "image"],
                      ["clean"],
                      [{ color: [] }],
                    ],
                  },
                }}
                theme="snow"
                value={value}
                onChange={(e) => {
                  setValue(e);
                }}
              />
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {" "}
                <Button
                  type="primary"
                  htmlType="submit"
                  onClick={updateTermsHandler}
                  style={{ width: "30%", marginTop: "0.5rem" }}
                  className="login-form-button "
                >
                  Update Terms & Condition
                </Button>
              </div>
            </Card>
          </div>
        </SiderDemo>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    TermsAndPrivacy: state.TermsAndPrivacy,
  };
};

export default connect(mapStateToProps, {})(TermsAndCondition);
