import { openNotificationWithIcon } from "../../Components/Notification/Success";

const initialState = {
  isLoading: false,
  isTutoringChanged: false,
  tutorings: {},
  tutoring: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case "GET_TUTORING_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_TUTORING_SUCCESS":
      return {
        ...state,
        isLoading: false,
        tutorings: payload,
        isTutoringChanged: state.isTutoringChanged ? false : true,
      };
    case "GET_TUTORING_FAILURE":
      return {
        ...state,
        isLoading: false,
      };

    case "GET_TUTORING_DETAIL_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_TUTORING_DETAIL_SUCCESS":
      //   openNotificationWithIcon("success", payload.message);
      return {
        ...state,
        isLoading: false,
        tutoring: payload,
      };
    case "GET_TUTORING_DETAIL_FAILURE":
      //   openNotificationWithIcon("error", payload.data.message);
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
}
