import React, { useEffect } from "react";
import "./App.css";
import "./Responsive.css";
import "antd/dist/antd.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./Pages/Login/Login";
import Store from "./Redux/store";
import { Provider } from "react-redux";
import { notification } from "antd";
import Dashboard from "./Pages/Dashboard/Dashboard";
import ClassManagement from "./Pages/Classes Management/ClassManagement";
import ClientManagement from "./Pages/Client Management/ClientManagement";
import ClassDetail from "./Pages/Classes Management/ClassDetail";
import LoyaltyPoints from "./Pages/Loyalty Points/LoyaltyPoints";
import Commission from "./Pages/Commission/Commission";
import CouponCode from "./Pages/Coupon code/CouponCode";
import MassNotification from "./Pages/Mass Notification/MassNotification";
import TermsAndCondition from "./Pages/Terms and  Condition/TermsAndCondition";
import PrivacyPolicy from "./Pages/Privacy Policy/PrivacyPolicy";
import MyProfile from "./Pages/My Profile/MyProfile";
import ChangePassword from "./Pages/My Profile/ChangePassword";
import RegisterAdmin from "./Pages/My Profile/RegisterAdmin";
import AdminList from "./Pages/Admin List/AdminList";
import CustomerSupport from "./Pages/Customer Support/CustomerSupport";
import RolesPermission from "./Pages/Roles & Permissions/RolesPermission";
import TutoringManagement from "./Pages/Tutoring Management/TutoringManagement";
import TutoringDetails from "./Pages/Tutoring Management/TutoringDetails";
import ExamManagement from "./Pages/Exam Management/ExamManagement";
import ExamSubject from "./Pages/Exam Management/ExamSubject";
import AddQuestion from "./Pages/Exam Management/AddQuestion";
import QuestionListSubject from "./Pages/Exam Management/QuestionListSubject";
import QuestionListSubSubject from "./Pages/Exam Management/QuestionListSubSubject";
import SubjectExamSet from "./Pages/Exam Management/SubjectExamSet";
import SubSubjectExamSet from "./Pages/Exam Management/SubSubjectExamSet";
import ReportReason from "./Pages/Report Reason/ReportReason";
import ResourceManagement from "./Pages/Resource Management/ResourceManagement";
import SubjectDirectoryList from "./Pages/Resource Management/SubjectDirectoryList";
import DirectoryList from "./Pages/Resource Management/DirectoryList";
import Report from "./Pages/Report/Report";
import Payment from "./Pages/Payment/Payment";
import PrivateRoute from "./Helpers/PrivateRoute";
import Advertisement from "./Pages/Advertisement/Advertisement";

const Context = React.createContext({ name: "Default" });

function App() {
  const [api, contextHolder] = notification.useNotification();

  return (
    <Provider store={Store()}>
      <div className="App">
        <BrowserRouter>
          {/* <div style={{ display: "flex", gap: "1rem" }}>
            <div style={{ width: "90%", padding: "30px" }}> */}
          <Routes>
            <Route path="/" element={<Login />} />
            <Route
              path="/dashboard"
              element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              }
            />
            <Route
              path="/client-management"
              element={
                <PrivateRoute>
                  <ClientManagement />
                </PrivateRoute>
              }
            />
            <Route
              path="/classes-management"
              element={
                <PrivateRoute>
                  <ClassManagement />
                </PrivateRoute>
              }
            />
            <Route
              path="/advertisement"
              element={
                <PrivateRoute>
                  <Advertisement />
                </PrivateRoute>
              }
            />
            <Route
              path="/loyalty-points"
              element={
                <PrivateRoute>
                  <LoyaltyPoints />
                </PrivateRoute>
              }
            />
            <Route
              path="/class-detail/:id"
              element={
                <PrivateRoute>
                  <ClassDetail />
                </PrivateRoute>
              }
            />
            <Route
              path="/commission"
              element={
                <PrivateRoute>
                  <Commission />
                </PrivateRoute>
              }
            />
            <Route
              path="/coupon-code"
              element={
                <PrivateRoute>
                  <CouponCode />
                </PrivateRoute>
              }
            />
            <Route
              path="/mass-notification"
              element={
                <PrivateRoute>
                  <MassNotification />
                </PrivateRoute>
              }
            />
            <Route
              path="/terms-and-condition"
              element={
                <PrivateRoute>
                  <TermsAndCondition />
                </PrivateRoute>
              }
            />
            <Route
              path="/privacy-policy"
              element={
                <PrivateRoute>
                  <PrivacyPolicy />
                </PrivateRoute>
              }
            />
            <Route
              path="/my-profile"
              element={
                <PrivateRoute>
                  <MyProfile />
                </PrivateRoute>
              }
            />
            <Route
              path="/change-password"
              element={
                <PrivateRoute>
                  <ChangePassword />
                </PrivateRoute>
              }
            />
            <Route
              path="/register-admin"
              element={
                <PrivateRoute>
                  <RegisterAdmin />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin-list"
              element={
                <PrivateRoute>
                  <AdminList />
                </PrivateRoute>
              }
            />
            <Route
              path="/customer-support"
              element={
                <PrivateRoute>
                  <CustomerSupport />
                </PrivateRoute>
              }
            />
            <Route
              path="/roles-and-permission"
              element={
                <PrivateRoute>
                  <RolesPermission />
                </PrivateRoute>
              }
            />
            <Route
              path="/tutoring-management"
              element={
                <PrivateRoute>
                  <TutoringManagement />
                </PrivateRoute>
              }
            />
            <Route
              path="/tutoring-detail/:id"
              element={
                <PrivateRoute>
                  <TutoringDetails />
                </PrivateRoute>
              }
            />
            <Route
              path="/exam-management"
              element={
                <PrivateRoute>
                  <ExamManagement />
                </PrivateRoute>
              }
            />
            <Route
              path="/exam-management/:id"
              element={
                <PrivateRoute>
                  <ExamSubject />
                </PrivateRoute>
              }
            />
            <Route
              path="/add-question"
              element={
                <PrivateRoute>
                  <AddQuestion />
                </PrivateRoute>
              }
            />

            <Route
              path="/question-list-subject/:id"
              element={
                <PrivateRoute>
                  <QuestionListSubject />
                </PrivateRoute>
              }
            />
            <Route
              path="/question-list-sub-subject/:id"
              element={
                <PrivateRoute>
                  <QuestionListSubSubject />
                </PrivateRoute>
              }
            />
            <Route
              path="/subject-exam-set/:id"
              element={
                <PrivateRoute>
                  <SubjectExamSet />
                </PrivateRoute>
              }
            />
            <Route
              path="/sub-subject-exam-set/:id"
              element={
                <PrivateRoute>
                  <SubSubjectExamSet />
                </PrivateRoute>
              }
            />
            <Route
              path="/report-reason"
              element={
                <PrivateRoute>
                  <ReportReason />
                </PrivateRoute>
              }
            />
            <Route
              path="/resources-management"
              element={
                <PrivateRoute>
                  <ResourceManagement />
                </PrivateRoute>
              }
            />
            <Route
              path="/subject-list/:id"
              element={
                <PrivateRoute>
                  <SubjectDirectoryList />
                </PrivateRoute>
              }
            />
            <Route
              path="/directory-list/:id"
              element={
                <PrivateRoute>
                  <DirectoryList />
                </PrivateRoute>
              }
            />
            <Route
              path="/reports"
              element={
                <PrivateRoute>
                  <Report />
                </PrivateRoute>
              }
            />
            <Route
              path="/payment"
              element={
                <PrivateRoute>
                  <Payment />
                </PrivateRoute>
              }
            />
          </Routes>
          {/* </div>
          </div> */}
        </BrowserRouter>
      </div>
    </Provider>
  );
}

export default App;
