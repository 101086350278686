import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import Sidebar from "../../Components/Sidebar/Sidebar";
import Breadcrump from "../../Components/Breadcrump/Breadcrump";
import { Descriptions, Badge, Spin, Tag, Segmented } from "antd";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import { Collapse, Card } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import Navbar from "../../Components/Navbar/Navbar";
import SiderDemo from "../Layout/SiderDemo";
import moment from "moment";
const { Panel } = Collapse;

const TutoringDetails = ({ Class, Tutoring }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch({
      type: "GET_TUTORING_DETAIL_REQUEST",
      payload: {
        id: id,
      },
    });
  }, []);
  return (
    <div className="container">
      <SiderDemo>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Segmented style={{ height: "30px", fontSize: "11px" }} />
          <Card style={{ width: 900 }} loading={Tutoring.isLoading}>
            <div
              style={{
                background: "#F0F2F5",
                height: "40px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingLeft: "20px",
                color: "black",

                fontSize: "17px",
              }}
            >
              Tutoring Details
            </div>
            <Descriptions>
              <Descriptions.Item
                label="Student Name"
                style={{ padding: "10px" }}
              >
                {Tutoring.tutoring.tutoring &&
                  Tutoring.tutoring.tutoring.studentName}
              </Descriptions.Item>
              <Descriptions.Item
                label="Requesting Name"
                style={{ padding: "10px" }}
              >
                {Tutoring.tutoring.tutoring &&
                  Tutoring.tutoring.tutoring.requestingClientName}
              </Descriptions.Item>
              <Descriptions.Item
                label="Teacher Name"
                style={{ padding: "10px" }}
              >
                {Tutoring.tutoring.tutoring &&
                  Tutoring.tutoring.tutoring.teacherName}
              </Descriptions.Item>
              <Descriptions.Item label="Class Name" style={{ padding: "10px" }}>
                {Tutoring.tutoring.tutoring &&
                  Tutoring.tutoring.tutoring.className}
              </Descriptions.Item>
              <Descriptions.Item
                label="Subject Name"
                style={{ padding: "10px" }}
              >
                {Tutoring.tutoring.tutoring &&
                  Tutoring.tutoring.tutoring.subjectCategoryName}
              </Descriptions.Item>
              <Descriptions.Item label="Study Time" style={{ padding: "10px" }}>
                {Tutoring.tutoring.tutoring &&
                  Tutoring.tutoring.tutoring.studyTimeValue}
              </Descriptions.Item>
              {/* <Descriptions.Item label="Start Date" style={{ padding: "10px" }}>
                {Tutoring.tutoring.tutoring &&
                    Tutoring.tutoring.tutoring.startDate}
                </Descriptions.Item> */}
              <Descriptions.Item
                span={3}
                label="Why this course?"
                style={{ padding: "10px" }}
              >
                {Tutoring.tutoring.tutoring &&
                  Tutoring.tutoring.tutoring.whyTakeThisCourse}
              </Descriptions.Item>

              <Descriptions.Item
                span={3}
                label={
                  <span style={{ fontWeight: "bold" }}>Subject Prices</span>
                }
                style={{ padding: "10px", justifySelf: "center" }}
              ></Descriptions.Item>

              {Tutoring.tutoring.tutoring &&
                Tutoring.tutoring.tutoring.subjectPrices.map((el) => {
                  return (
                    <>
                      <Descriptions.Item
                        label="Name"
                        style={{ padding: "10px" }}
                      >
                        {el.subSubjectName}
                      </Descriptions.Item>
                      <Descriptions.Item
                        label="Price"
                        style={{ padding: "10px" }}
                      >
                        ${el.price}
                      </Descriptions.Item>
                      <Descriptions.Item
                        label="Credit Hour"
                        style={{ padding: "10px" }}
                      >
                        {el.creditHour} hrs
                      </Descriptions.Item>
                    </>
                  );
                })}
            </Descriptions>

            <div
              style={{
                background: "#F0F2F5",
                height: "40px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingLeft: "20px",
                color: "black",
                fontWeight: "normal",
                marginTop: "1.5rem",
                fontSize: "17px",
              }}
            >
              Teacher Payment Details
            </div>
            <Descriptions>
              <Descriptions.Item
                label="Payment Date"
                style={{ padding: "10px" }}
              >
                {Tutoring.tutoring.teacherPayment?.paymentDate &&
                  moment(Tutoring.tutoring.teacherPayment.paymentDate).format(
                    "YYYY-MM-DD"
                  )}
              </Descriptions.Item>
              <Descriptions.Item
                label="Payment Completed"
                style={{ padding: "10px" }}
              >
                {Tutoring.tutoring.teacherPayment &&
                Tutoring.tutoring.teacherPayment.paymentComplete ? (
                  <Tag color="green">Yes</Tag>
                ) : (
                  <Tag color="red">No</Tag>
                )}
              </Descriptions.Item>
              <Descriptions.Item
                label="Payment Cancelled"
                style={{ padding: "10px" }}
              >
                {Tutoring.tutoring.teacherPayment &&
                Tutoring.tutoring.teacherPayment.paymentCancelled ? (
                  <Tag color="green">Yes</Tag>
                ) : (
                  <Tag color="red">No</Tag>
                )}
              </Descriptions.Item>
              <Descriptions.Item label="Price" style={{ padding: "10px" }}>
                $
                {Tutoring.tutoring.teacherPayment &&
                  Tutoring.tutoring.teacherPayment.price}
              </Descriptions.Item>
              <Descriptions.Item label="Discount" style={{ padding: "10px" }}>
                $
                {Tutoring.tutoring.teacherPayment &&
                  Tutoring.tutoring.teacherPayment.discount}
              </Descriptions.Item>
              <Descriptions.Item label="GST" style={{ padding: "10px" }}>
                $
                {Tutoring.tutoring.teacherPayment &&
                  Tutoring.tutoring.teacherPayment.gst}
              </Descriptions.Item>
              <Descriptions.Item label="Insurance" style={{ padding: "10px" }}>
                $
                {Tutoring.tutoring.teacherPayment &&
                  Tutoring.tutoring.teacherPayment.insurance}
              </Descriptions.Item>
              <Descriptions.Item
                label="Service Fee"
                style={{ padding: "10px" }}
              >
                $
                {Tutoring.tutoring.teacherPayment &&
                  Tutoring.tutoring.teacherPayment.serviceFee}
              </Descriptions.Item>

              <Descriptions.Item
                label="Credit To User"
                style={{ padding: "10px" }}
              >
                {Tutoring.tutoring.teacherPayment &&
                Tutoring.tutoring.teacherPayment.creditToUser ? (
                  <Tag color="green">Yes</Tag>
                ) : (
                  <Tag color="red">No</Tag>
                )}
              </Descriptions.Item>
            </Descriptions>
            <div
              style={{
                background: "#F0F2F5",
                height: "40px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingLeft: "20px",
                color: "black",
                fontWeight: "normal",
                marginTop: "1.5rem",
                fontSize: "17px",
              }}
            >
              Student Payment Details
            </div>
            <Descriptions>
              <Descriptions.Item
                label="Payment Date"
                style={{ padding: "10px" }}
              >
                {Tutoring.tutoring.studentPayment &&
                  moment(Tutoring.tutoring.studentPayment.paymentDate).format(
                    "YYYY-MM-DD"
                  )}
              </Descriptions.Item>
              <Descriptions.Item
                label="Payment Completed"
                style={{ padding: "10px" }}
              >
                {Tutoring.tutoring.studentPayment &&
                Tutoring.tutoring.studentPayment.paymentComplete ? (
                  <Tag color="green">Yes</Tag>
                ) : (
                  <Tag color="red">No</Tag>
                )}
              </Descriptions.Item>
              <Descriptions.Item
                label="Payment Cancelled"
                style={{ padding: "10px" }}
              >
                {Tutoring.tutoring.studentPayment &&
                Tutoring.tutoring.studentPayment.paymentCancelled ? (
                  <Tag color="green">Yes</Tag>
                ) : (
                  <Tag color="red">No</Tag>
                )}
              </Descriptions.Item>
              <Descriptions.Item label="Price" style={{ padding: "10px" }}>
                {Tutoring.tutoring.studentPayment &&
                  Tutoring.tutoring.studentPayment.price}
              </Descriptions.Item>
              <Descriptions.Item label="Discount" style={{ padding: "10px" }}>
                $
                {Tutoring.tutoring.studentPayment &&
                  Tutoring.tutoring.studentPayment.discount}
              </Descriptions.Item>
              <Descriptions.Item label="GST" style={{ padding: "10px" }}>
                $
                {Tutoring.tutoring.studentPayment &&
                  Tutoring.tutoring.studentPayment.gst}
              </Descriptions.Item>
              <Descriptions.Item label="Insurance" style={{ padding: "10px" }}>
                $
                {Tutoring.tutoring.studentPayment &&
                  Tutoring.tutoring.studentPayment.insurance}
              </Descriptions.Item>
              <Descriptions.Item
                label="Service Fee"
                style={{ padding: "10px" }}
              >
                $
                {Tutoring.tutoring.studentPayment &&
                  Tutoring.tutoring.studentPayment.serviceFee}
              </Descriptions.Item>
              <Descriptions.Item
                label="Coupon Code"
                style={{ padding: "10px" }}
              >
                {Tutoring.tutoring.studentPayment &&
                  Tutoring.tutoring.studentPayment.couponCode}
              </Descriptions.Item>
              <Descriptions.Item
                label="Credit To User"
                style={{ padding: "10px" }}
              >
                {Tutoring.tutoring.studentPayment &&
                Tutoring.tutoring.studentPayment.creditToUser ? (
                  <Tag color="green">Yes</Tag>
                ) : (
                  <Tag color="red">No</Tag>
                )}
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </div>
      </SiderDemo>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    Class: state.Class,
    Tutoring: state.Tutoring,
  };
};

export default connect(mapStateToProps, {})(TutoringDetails);
