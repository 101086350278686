import React, { useEffect, useState } from "react";
import "./Advertisement.css";
import "../Classes Management/ClassManagement.css";
import { Table } from "antd";
import { connect, useDispatch } from "react-redux";
import { Tag, Spin } from "antd";
import { AiOutlineEdit } from "react-icons/ai";
import { Drawer, Button, Modal } from "antd";
import { Form, Input } from "antd";
import { Select, Tooltip } from "antd";
import { MdDisabledVisible } from "react-icons/md";
import EditAdvertisement from "./EditAdvertisement";
import TableSkeleton from "../../Components/Table Skeleton/TableSkeleton";
import SiderDemo from "../Layout/SiderDemo";
import { openNotificationWithIcon } from "../../Components/Notification/Success";

const { Option } = Select;
const { Search } = Input;

const Advertisement = ({ Advertisement }) => {
  const dispatch = useDispatch();
  const [advertisementId, setAdvertisementId] = useState();
  const [form] = Form.useForm();
  const [fileName, setFileName] = useState();
  const [imageFile, setImageFile] = useState();
  const [visible, setVisible] = useState(false);
  const [size, setSize] = useState("large");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const onFinish = (values) => {
    console.log("Received values of form: ", values);
    const advertisement = new FormData();
    advertisement.append("name", values.name);
    advertisement.append("companyName", values.companyName);
    advertisement.append("companyUrl", values.companyUrl);
    advertisement.append("userType", values.userType);
    advertisement.append("file", imageFile);
    advertisement.append("isActive", values.isActive);

    if (!imageFile) {
      openNotificationWithIcon("error", "Please upload an image");
      return;
    }
    dispatch({
      type: "ADD_ADVERTISEMENT_REQUEST",
      payload: advertisement,
    });
    setIsModalVisible(false);
    form.resetFields();
    setFileName("");
  };
  const imageChangeHandler = (e) => {
    setImageFile(e.target.files[0]);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onClose = () => {
    setVisible(false);
  };

  useEffect(() => {
    dispatch({
      type: "GET_ADVERTISEMENT_REQUEST",
    });
  }, [Advertisement.isAdvertisementChanged]);

  const disableAdHandler = (id) => {
    dispatch({
      type: "DISABLE_AD_REQUEST",
      payload: {
        id: id,
      },
    });
  };

  const columns = [
    {
      title: "Promotion Name",
      dataIndex: "promotionName",

      width: 250,
      align: "center",
    },
    {
      title: "Company Name",
      dataIndex: "companyName",

      width: 150,
      align: "center",
    },
    {
      title: "Company URL",
      dataIndex: "companyUrl",

      width: 250,
      align: "center",
    },
    {
      title: "Active",
      dataIndex: "active",

      width: 100,
      align: "center",
    },

    {
      title: "Image",
      dataIndex: "image",

      width: 250,
      align: "center",
    },
    {
      title: "Promotion Type",
      dataIndex: "promotionType",

      width: 250,
      align: "center",
    },
    {
      title: "Actions",
      dataIndex: "actions",

      align: "center",
      width: 250,
    },
  ];

  const data = Advertisement.advertisements.map((ad) => {
    return {
      id: ad.id,
      promotionName: ad.promotionName,
      companyName: ad.companyName,
      companyUrl: ad.companyUrl,
      active: !ad.active ? (
        <Tag color="red">Not Active</Tag>
      ) : (
        <Tag color="green">Active</Tag>
      ),
      image: <img src={ad.image} style={{ height: "50px", width: "50px" }} />,
      promotionType: ad.promotionType,

      actions: (
        <div className="action">
          {" "}
          <Tooltip title="Edit Advertisement">
            <AiOutlineEdit
              className="icon"
              onClick={() => {
                setVisible(true);
                setAdvertisementId(ad.id);
              }}
            />
          </Tooltip>
          {ad.active ? (
            <Tooltip title="Disable Advertisement">
              <MdDisabledVisible
                className="icon"
                onClick={() => {
                  disableAdHandler(ad.id);
                }}
              />
            </Tooltip>
          ) : (
            <Tooltip title="Enable Advertisement">
              <MdDisabledVisible
                className="icon"
                onClick={() => {
                  disableAdHandler(ad.id);
                }}
              />
            </Tooltip>
          )}
        </div>
      ),
    };
  });
  const searchAdHandler = (e) => {
    dispatch({
      type: "SEARCH_ADVERTISEMENT_REQUEST",
      payload: {
        keyword: e,
      },
    });
  };

  return (
    <>
      <div className="container">
        <Modal
          style={{ marginTop: "-3rem" }}
          title="New Advertisement"
          visible={isModalVisible}
          onCancel={handleCancel}
          footer={null}
        >
          <Spin spinning={Advertisement.isLoading}>
            <Form
              name="form"
              encType="multipart/form-data"
              form={form}
              className="login-form"
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
            >
              <div style={{ display: "flex", gap: "1rem" }}>
                {" "}
                <Form.Item
                  label="Name"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    width: "100%",
                  }}
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Please input  name!",
                    },
                  ]}
                >
                  <Input placeholder="Name" />
                </Form.Item>
                <Form.Item
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    width: "100%",
                  }}
                  label="Company Name"
                  name="companyName"
                  rules={[
                    {
                      required: true,
                      message: "Please input company name!",
                    },
                  ]}
                >
                  <Input placeholder="Company Name" style={{ width: "100%" }} />
                </Form.Item>
              </div>
              <div style={{ display: "flex", gap: "1rem" }}>
                {" "}
                <Form.Item
                  name="companyUrl"
                  label="Company URL"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    width: "100%",
                  }}
                  rules={[
                    {
                      required: true,
                      message: "Please input company url!",
                    },
                  ]}
                >
                  <Input placeholder="Company URL" />
                </Form.Item>
                <Form.Item
                  label="User Type"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    width: "100%",
                  }}
                  name="userType"
                  rules={[
                    {
                      required: true,
                      message: "Please input user type!",
                    },
                  ]}
                >
                  <Select placeholder="Select User Type">
                    <Option value="STUDENT">Student</Option>
                    <Option value="TEACHER">Teacher</Option>
                    <Option value="all">Both</Option>
                  </Select>
                </Form.Item>
              </div>

              <Form.Item
                label="Active"
                name="isActive"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  width: "100%",
                }}
                rules={[
                  {
                    required: true,
                    message: "Please input active type!",
                  },
                ]}
              >
                <Select placeholder="Select Active">
                  <Option value="TRUE">Yes</Option>
                  <Option value="FALSE">No</Option>
                </Select>
              </Form.Item>
              <Form.Item
                label="Image"
                name="file"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  width: "100%",
                }}
              >
                <input
                  onChange={(e) => imageChangeHandler(e)}
                  type="file"
                  className="fileinput"
                />
                <p>{fileName && fileName}</p>
              </Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{ margin: "auto", width: "100%" }}
                className="login-form-button"
              >
                Add
              </Button>
            </Form>
          </Spin>
        </Modal>
        <SiderDemo>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: "2rem",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {" "}
                <h2 className="title-course">Advertisement</h2>
                <Button
                  type="primary"
                  style={{ background: "#FF4D4F" }}
                  danger
                  onClick={showModal}
                >
                  Add
                </Button>
              </div>
              <Search
                className="s"
                style={{ width: "35%", borderRadius: "10px" }}
                placeholder="Search for advertisements."
                allowClear
                size="large"
                onChange={(e) => searchAdHandler(e.target.value)}
                // onSearch={onSearch}
              />
            </div>

            {/* <nav class="menu"></nav> */}
            {Advertisement.isLoading ? (
              <TableSkeleton />
            ) : (
              <Table
                pagination={false}
                columns={columns}
                dataSource={data}
                bordered
                className="table-course"
              />
            )}
            {Advertisement.advertisements.totalPage && (
              <Pagination
                style={{
                  marginTop: "1rem",
                  marginBottom: "1rem",
                  alignSelf: "flex-end",
                }}
                showSizeChanger
                onShowSizeChange={onShowSizeChange}
                defaultCurrent={1}
                onChange={onShowSizeChange}
                total={Advertisement.advertisements.totalPage * 10}
              />
            )}

            <Drawer
              title={<span style={{}}>Edit Advertisement</span>}
              placement="right"
              width={"85%"}
              size={size}
              onClose={onClose}
              visible={visible}
            >
              <EditAdvertisement id={advertisementId} onClose={onClose} />
            </Drawer>
          </div>
        </SiderDemo>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    Advertisement: state.Advertisement,
  };
};

export default connect(mapStateToProps, {})(Advertisement);
