import { Input, Collapse, DatePicker } from "antd";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import "../Classes Management/ClassManagement.css";
import { Select } from "antd";
import { List, Tag, Popconfirm, Typography, Spin } from "antd";
import { CaretRightOutlined, PlusOutlined } from "@ant-design/icons";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import { Tooltip, Button } from "antd";
import { Upload, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { Form, Checkbox } from "antd";
import moment from "moment";

const { Option } = Select;
const { Panel } = Collapse;
const { Paragraph } = Typography;

const UpdateProfile = (props) => {
  const dispatch = useDispatch();
  const [activeData, setActiveData] = useState();
  const [defaultDate, setDefaultDate] = useState(props.data.dateOfBirth);
  const [form] = Form.useForm();
  const onFinish = (values) => {
    dispatch({
      type: "UPDATE_PROFILE_REQUEST",
      payload: { ...values, dob: defaultDate },
    });
    form.resetFields();
    props.onClose();
  };

  useEffect(() => {
    setActiveData(props.data);
  }, [props.data]);
  //   let rateType;
  //   const exactData = {
  //     ...props.commissions.map((com) => {
  //       rateType = com.rateType;
  //       return { rateType: {} };
  //     }),
  //   };

  return (
    <div style={{ width: "50%", margin: "auto" }}>
      <Spin spinning={props.LoyaltyPoints.isLoading}>
        <Form
          name="form"
          fields={[
            {
              name: ["fullName"],
              value: props.data.fullName,
            },
            {
              name: ["phoneNumber"],
              value: props.data.phoneNo,
            },
            {
              name: ["gender"],
              value: props.data.gender,
            },
          ]}
          encType="multipart/form-data"
          form={form}
          name="normal_login"
          className="login-form"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
        >
          <Form.Item
            label="Full Name"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              width: "100%",
            }}
            name="fullName"
            rules={[
              {
                required: true,
                message: "Please input name!",
              },
            ]}
          >
            <Input placeholder="Full Name" />
          </Form.Item>
          <Form.Item
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              width: "100%",
            }}
            label="Phone Number"
            name="phoneNumber"
            rules={[
              {
                required: true,
                message: "Please input phone number!",
              },
            ]}
          >
            <Input placeholder="Phone Number" />
          </Form.Item>
          <Form.Item
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              width: "100%",
            }}
            label="Gender"
            name="gender"
            rules={[
              {
                required: true,
                message: "Please input your gender!",
              },
            ]}
          >
            <Select>
              <Option value="MALE">Male</Option>
              <Option value="FEMALE">Female</Option>
              <Option value="OTHER">Others</Option>
            </Select>
          </Form.Item>
          <Form.Item
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              width: "100%",
            }}
            label="Date Of Birth"
            name="dob"
            // rules={[
            //   {
            //     required: true,
            //     message: "Please input your date of birth!",
            //   },
            // ]}
          >
            <DatePicker
              disabledDate={(d) =>
                !d || d.isAfter("2002-12-31") || d.isSameOrBefore("1960-01-01")
              }
              defaultValue={moment(props.data.dateOfBirth)}
              value={moment(props.data.dateOfBirth)}
              onChange={(e) => setDefaultDate(moment(e).format("YYYY-MM-DD"))}
            />
          </Form.Item>

          <Button
            type="primary"
            htmlType="submit"
            style={{ margin: "auto", width: "100%" }}
            className="login-form-button"
          >
            Update
          </Button>
        </Form>
      </Spin>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    Class: state.Class,
    Advertisement: state.Advertisement,
    LoyaltyPoints: state.LoyaltyPoints,
  };
};

export default connect(mapStateToProps, {})(UpdateProfile);
