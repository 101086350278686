import React, { useEffect, useState } from "react";
import "../Classes Management/ClassManagement.css";
import { Table } from "antd";
import { connect, useDispatch } from "react-redux";
import { Tag } from "antd";
import { Pagination } from "antd";
import { Tooltip, Segmented } from "antd";
import { TiTick } from "react-icons/ti";
import SiderDemo from "../Layout/SiderDemo";

import TableSkeleton from "../../Components/Table Skeleton/TableSkeleton";

const CustomerSupport = ({ Support }) => {
  const dispatch = useDispatch();
  const [status, setStatus] = useState("");
  const [userType, setUserType] = useState("");

  const markSupportHandler = (id) => {
    dispatch({
      type: "MARK_SUPPORT_REQUEST",
      payload: {
        id: id,
      },
    });
  };

  useEffect(() => {
    dispatch({
      type: "GET_SUPPORT_REQUEST",
      payload: {
        page: 1,
        pageSize: 10,
        status: status,
        userType: userType,
      },
    });
  }, [Support.isSupportChanged]);

  const columns = [
    {
      title: "Ticket No",
      dataIndex: "ticketNo",

      width: 300,
      align: "center",
    },

    {
      title: "Problem Type",
      dataIndex: "problemType",

      width: 250,
      align: "center",
    },
    {
      title: "Problem Category",
      dataIndex: "problemCategory",

      width: 150,
      align: "center",
    },
    {
      title: "Message",
      dataIndex: "message",

      width: 250,
      align: "center",
    },

    {
      title: "Client Name",
      dataIndex: "clientName",

      width: 250,
      align: "center",
    },
    {
      title: "Status",
      dataIndex: "supportStatus",

      width: 250,
      align: "center",
    },
    {
      title: "Actions",
      dataIndex: "actions",

      align: "center",
      width: 350,
    },
  ];

  const data =
    Support.supports.data &&
    Support.supports.data.map((support) => {
      return {
        ticketNo: support.ticketNo,
        problemType: support.problemType,
        supportStatus:
          support.supportStatus == "PENDING" ? (
            <Tag color="red">Pending</Tag>
          ) : (
            <Tag color="green">Solved</Tag>
          ),
        problemCategory: support.problemCategory,
        message: support.message,
        clientName: support.clientName,
        actions: (
          <div className="action">
            {support.supportStatus == "PENDING" && (
              <Tooltip title="Mark as Solved">
                <TiTick
                  className="icon"
                  style={{ fontSize: "16px" }}
                  onClick={() => {
                    markSupportHandler(support.supportId);
                  }}
                />
              </Tooltip>
            )}
          </div>
        ),
      };
    });
  function onShowSizeChange(current, pageSize) {
    window.scrollTo(0, 0);
    dispatch({
      type: "GET_SUPPORT_REQUEST",
      payload: {
        page: current,
        pageSize: pageSize,
        status: status,
      },
    });
  }
  const onStatusChangeHandler = (e) => {
    if (e == "All") {
      dispatch({
        type: "GET_SUPPORT_REQUEST",
        payload: {
          pageNo: 1,
          pageSize: 10,
          status: "",
          userType: userType == "Both" ? "" : userType.toUpperCase(),
        },
      });
    } else {
      dispatch({
        type: "GET_SUPPORT_REQUEST",
        payload: {
          pageNo: 1,
          pageSize: 10,
          status: e.toUpperCase(),
          userType: userType == "Both" ? "" : userType.toUpperCase(),
        },
      });
    }
  };
  const onStatusChangeHandler1 = (e) => {
    dispatch({
      type: "GET_SUPPORT_REQUEST",
      payload: {
        pageNo: 1,
        pageSize: 10,
        status: status == "All" ? "" : status.toUpperCase(),
        userType: e == "Both" ? "" : e.toUpperCase(),
      },
    });
  };

  return (
    <>
      <div className="container">
        <SiderDemo>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: "2rem",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {" "}
                <h2 className="title-course">Customer Support</h2>
                <Segmented
                  style={{ height: "30px", fontSize: "11px" }}
                  options={["All", "Pending", "Solved"]}
                  onChange={(e) => {
                    setStatus(e);
                    onStatusChangeHandler(e);
                  }}
                />
                <Segmented
                  style={{ height: "30px", fontSize: "11px" }}
                  options={["Both", "Student", "Teacher"]}
                  onChange={(e) => {
                    setUserType(e);
                    onStatusChangeHandler1(e);
                  }}
                />
              </div>
            </div>

            {/* <nav class="menu"></nav> */}
            {Support.isLoading ? (
              <TableSkeleton />
            ) : (
              <Table
                pagination={false}
                columns={columns}
                dataSource={data}
                bordered
                className="table-course"
              />
            )}
            {Support.supports.totalPage && (
              <Pagination
                style={{
                  marginTop: "1rem",
                  marginBottom: "1rem",
                  alignSelf: "flex-end",
                }}
                showSizeChanger
                onShowSizeChange={onShowSizeChange}
                defaultCurrent={1}
                onChange={onShowSizeChange}
                total={Support.supports.totalPage * 10}
              />
            )}
          </div>
        </SiderDemo>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    Class: state.Class,
    Support: state.Support,
  };
};

export default connect(mapStateToProps, {})(CustomerSupport);
