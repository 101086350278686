import { openNotificationWithIcon } from "../../Components/Notification/Success";

const initialState = {
  isLoading: false,
  isNotificationChanged: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case "ADD_MASS_NOTIFICATION_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "ADD_MASS_NOTIFICATION_SUCCESS":
      openNotificationWithIcon("success", payload.message);
      return {
        ...state,
        isLoading: false,
        isNotificationChanged: state.isNotificationChanged ? false : true,
      };
    case "ADD_MASS_NOTIFICATION_FAILURE":
      openNotificationWithIcon("error", payload.data.message);
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
}
