import "../Advertisement/Advertisement.css";
import "../Classes Management/ClassManagement.css";
import { Form, Input, Button, Spin, Select } from "antd";
import { connect, useDispatch } from "react-redux";
import "./MassNotification.css";
import SiderDemo from "../Layout/SiderDemo";

const { TextArea } = Input;
const { Option } = Select;

const MassNotification = ({ MassNotification }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const onFinish = (values) => {
    console.log("Recieved Values of form", values);
    dispatch({
      type: "ADD_MASS_NOTIFICATION_REQUEST",
      payload: {
        ...values,
        userType: values.userType == "null" ? null : values.userType,
      },
    });
    form.resetFields();
  };
  return (
    <>
      <div className="container">
        <SiderDemo>
          <div>
            <Spin spinning={MassNotification.isLoading}>
              <div
                className="mass-container"
                style={{ width: "90%", background: "white" }}
              >
                <div
                  style={{
                    background: "#5D3BED",
                    height: "50px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    paddingLeft: "20px",
                    color: "white",
                    fontWeight: "normal",
                  }}
                >
                  Add Mass Notification
                </div>
                <Form
                  name="form"
                  encType="multipart/form-data"
                  form={form}
                  className="login-form notification-form"
                  initialValues={{
                    remember: true,
                  }}
                  onFinish={onFinish}
                >
                  <Form.Item
                    label="Notification Title"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      width: "100%",
                    }}
                    name="title"
                    rules={[
                      {
                        required: true,
                        message: "Please input notification title!",
                      },
                    ]}
                  >
                    <Input placeholder="Notification Title" />
                  </Form.Item>
                  <Form.Item
                    label="User Type"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      width: "100%",
                    }}
                    name="userType"
                    rules={[
                      {
                        required: true,
                        message: "Please input user type!",
                      },
                    ]}
                  >
                    <Select placeholder="User Type">
                      <Option value="STUDENT">Student</Option>
                      <Option value="TEACHER">Teacher</Option>
                      <Option value={"null"}>Both</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      width: "100%",
                    }}
                    label="Notification Body"
                    name="body"
                    rules={[
                      {
                        required: true,
                        message: "Please input Notification body!",
                      },
                    ]}
                  >
                    <TextArea rows={4} placeholder="Notification Body" />
                  </Form.Item>

                  <div className="button-container">
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ width: "100%" }}
                      className="login-form-button notification-button"
                    >
                      Add
                    </Button>
                  </div>
                </Form>
              </div>
            </Spin>
          </div>
        </SiderDemo>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    MassNotification: state.MassNotification,
  };
};

export default connect(mapStateToProps, {})(MassNotification);
