import React, { useEffect, useState } from "react";
import "../Advertisement/Advertisement.css";
import "../Classes Management/ClassManagement.css";
import { Table } from "antd";
import { connect, useDispatch } from "react-redux";
import { Spin } from "antd";
import { AiOutlineEdit } from "react-icons/ai";
import { Drawer, Button, Modal } from "antd";
import { Form, Input, Pagination } from "antd";
import { Select, Tooltip } from "antd";
import EditCommission from "./EditCommission";
import TableSkeleton from "../../Components/Table Skeleton/TableSkeleton";
import SiderDemo from "../Layout/SiderDemo";

const { Option } = Select;

const Commission = ({ Commission }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [activeData, setActiveData] = useState();
  const [visible, setVisible] = useState(false);
  const [size, setSize] = useState("large");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const onFinish = (values) => {
    console.log("Received values of form: ", values);
    dispatch({
      type: "ADD_COMMISSION_REQUEST",
      payload: values,
    });
    form.resetFields();
    setIsModalVisible(false);
  };
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onClose = () => {
    setVisible(false);
  };

  useEffect(() => {
    dispatch({
      type: "GET_COMMISSION_REQUEST",
      payload: {
        page: 1,
        pageSize: 10,
      },
    });
  }, [Commission.isCommissionChanged]);

  const sharedOnCell = (_, index) => {
    if (index === 4) {
      return { colSpan: 0 };
    }
  };

  const columns = [
    {
      title: "Commission Type",
      dataIndex: "rateType",

      onCell: sharedOnCell,
      width: 300,
      align: "center",
    },

    {
      title: "Amount",
      dataIndex: "amount",
      onCell: sharedOnCell,
      width: 250,
      align: "center",
    },
    {
      title: "Type",
      dataIndex: "type",
      onCell: sharedOnCell,
      width: 150,
      align: "center",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      onCell: sharedOnCell,
      align: "center",
      width: 150,
    },
  ];

  const data =
    Commission.commissions.data &&
    Commission.commissions.data.map((commission, i) => {
      console.log(i);
      console.log(commission);
      return {
        rateType: commission.rateType,
        amount: commission.amount,
        type: commission.type,
        actions: (
          <div className="action" key={i}>
            <Tooltip title="Edit Commission">
              <AiOutlineEdit
                className="icon"
                onClick={() => {
                  setActiveData(commission);
                  setVisible(true);
                }}
              />
            </Tooltip>
          </div>
        ),
      };
    });

  function onShowSizeChange(current, pageSize) {
    window.scrollTo(0, 0);
    dispatch({
      type: "GET_COMMISSION_REQUEST",
      payload: {
        page: current,
        pageSize: pageSize,
      },
    });
  }
  return (
    <>
      <div className="container">
        <Modal
          style={{ marginTop: "-3rem" }}
          title="New Commission"
          visible={isModalVisible}
          onCancel={handleCancel}
          footer={null}
        >
          <Spin spinning={Commission.isLoading}>
            <Form
              name="form"
              encType="multipart/form-data"
              form={form}
              className="login-form"
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
            >
              <Form.Item
                label="Commission Type"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  width: "100%",
                }}
                name="rateType"
                rules={[
                  {
                    required: true,
                    message: "Please input commission type!",
                  },
                ]}
              >
                <Select placeholder="Rate Type">
                  <Option value="commission">Commission</Option>
                  <Option value="gst">GST</Option>
                  <Option value="insured">Insured</Option>
                </Select>
              </Form.Item>
              <Form.Item
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  width: "100%",
                }}
                label="Amount"
                name="amount"
                rules={[
                  {
                    required: true,
                    message: "Please input amount!",
                  },
                ]}
              >
                <Input placeholder="Amount" style={{ width: "100%" }} />
              </Form.Item>
              <Form.Item
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  width: "100%",
                }}
                label="Rate Type"
                name="type"
                rules={[
                  {
                    required: true,
                    message: "Please input amount!",
                  },
                ]}
              >
                <Select placeholder="Type">
                  <Option value="flat">Flat</Option>
                  <Option value="percentage">Percentage</Option>
                </Select>
              </Form.Item>

              <Button
                type="primary"
                htmlType="submit"
                style={{ margin: "auto", width: "100%" }}
                className="login-form-button"
              >
                Add
              </Button>
            </Form>
          </Spin>
        </Modal>

        <SiderDemo>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: "2rem",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {" "}
                <h2 className="title-course">Commission and services</h2>
                <Button
                  type="primary"
                  style={{ background: "#FF4D4F" }}
                  danger
                  onClick={showModal}
                >
                  Add
                </Button>
              </div>
            </div>

            {/* <nav class="menu"></nav> */}
            {Commission.isLoading ? (
              <TableSkeleton />
            ) : (
              <Table
                pagination={false}
                columns={columns}
                dataSource={data}
                bordered
                className="table-course"
              />
            )}
            {Commission.commissions.totalPage && (
              <Pagination
                style={{
                  marginTop: "1rem",
                  marginBottom: "1rem",
                  alignSelf: "flex-end",
                }}
                showSizeChanger
                onShowSizeChange={onShowSizeChange}
                defaultCurrent={1}
                onChange={onShowSizeChange}
                total={Commission.commissions.totalPage * 10}
              />
            )}

            <Drawer
              title={<span style={{}}>Edit Commission</span>}
              placement="right"
              width={"85%"}
              size={size}
              onClose={onClose}
              visible={visible}
            >
              <EditCommission
                onClose={onClose}
                data={activeData}
                commissions={
                  Commission.commissions.data && Commission.commissions.data
                }
              />
            </Drawer>
          </div>
        </SiderDemo>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    Commission: state.Commission,
  };
};

export default connect(mapStateToProps, {})(Commission);
