import React, { useEffect, useState } from "react";
import "../Advertisement/Advertisement.css";
import Breadcrump from "../../Components/Breadcrump/Breadcrump";
import "../Classes Management/ClassManagement.css";
import {
  Table,
  Popconfirm,
  Form,
  Input,
  Button,
  Spin,
  Card,
  Tag,
  Tooltip,
  Select,
  Modal,
  Segmented,
} from "antd";
import Sidebar from "../../Components/Sidebar/Sidebar";
import { connect, useDispatch } from "react-redux";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Navbar from "../../Components/Navbar/Navbar";
import SiderDemo from "../Layout/SiderDemo";
import { List, Typography, Divider } from "antd";
import { Link, useParams } from "react-router-dom";
import "./ExamSubject.css";
import { PlusOutlined } from "@ant-design/icons";
import { AiFillEye } from "react-icons/ai";
import { RiFolderSettingsFill } from "react-icons/ri";

const { TextArea } = Input;
const { Option } = Select;

const ExamSubject = ({ TermsAndPrivacy, Exam, Class }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { id } = useParams();
  const [isSubjectModalVisible, setSubjectModalVisible] = useState(false);
  const [isSubSubjectModalVisible, setSubSubjectModalVisible] = useState(false);
  const [subjectId, setSubjectId] = useState(null);
  const [subSubjectId, setSubSubjectId] = useState(null);
  const [subjectSetId, setSubjectSetId] = useState(null);
  const [subSubjectSetId, setSubSubjectSetId] = useState(null);
  const [value, setValue] = useState(
    TermsAndPrivacy.termsAndCondition.termsAndCondition
  );

  useEffect(() => {
    dispatch({
      type: "GET_SUBJECT_CATEGORY_REQUEST",
      payload: {
        id: id,
      },
    });
  }, [Class.isClassChanged, Exam.isExamChanged]);

  const onFinish = (values) => {
    console.log("Recieved Values of form", values);
    dispatch({
      type: "ADD_MASS_NOTIFICATION_REQUEST",
      payload: values,
    });
    form.resetFields();
  };
  const updateTermsHandler = () => {
    dispatch({
      type: "ADD_PRIVACY_TERMS_REQUEST",
      payload: {
        text: value,
        textFor: "TERMS",
      },
    });
  };

  const showModalSubject = () => {
    setSubjectModalVisible(true);
  };
  const showModalSubSubject = () => {
    setSubSubjectModalVisible(true);
  };

  const handleCancelSubject = () => {
    setSubjectModalVisible(false);
  };
  const handleCancelSubSubject = () => {
    setSubSubjectModalVisible(false);
  };
  const data =
    Class.classes.data &&
    Class.classes.data.map((classItem) => {
      return { cl: classItem.classStandard, id: classItem.subjectClassId };
    });

  console.log(subjectId, subSubjectId);

  useEffect(() => {
    if (subjectId) {
      dispatch({
        type: "GET_SUBJECT_SETS_REQUEST",
        payload: {
          id: subjectId,
        },
      });
    }
  }, [subjectId]);
  useEffect(() => {
    if (subSubjectId) {
      dispatch({
        type: "GET_SUB_SUBJECT_SETS_REQUEST",
        payload: {
          id: subSubjectId,
        },
      });
    }
  }, [subSubjectId]);
  return (
    <>
      <div className="container">
        <Modal
          title="Select Exam Set"
          visible={isSubjectModalVisible}
          footer={null}
          onCancel={handleCancelSubject}
        >
          <Select
            placeholder="Exam Set"
            style={{ width: "100%" }}
            onChange={(e) => setSubjectSetId(e)}
          >
            {Exam.subjectSets?.map((el) => (
              <Option value={el.examTimeId}>{el.questionSet}</Option>
            ))}
          </Select>

          <div className="button-container">
            <Link
              to={
                subjectSetId
                  ? `/add-question?setId=${subjectSetId}&subjectId=${subjectId}`
                  : ""
              }
            >
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%", marginTop: "1rem" }}
                className="login-form-button notification-button"
              >
                Continue
              </Button>
            </Link>
          </div>
        </Modal>
        <Modal
          footer={null}
          title="Select Exam sets"
          visible={isSubSubjectModalVisible}
          onCancel={handleCancelSubSubject}
        >
          <Select
            placeholder="Exam Set"
            style={{ width: "100%" }}
            onChange={(e) => setSubSubjectSetId(e)}
          >
            {Exam.subSubjectSets?.map((el) => (
              <Option value={el.examTimeId}>{el.questionSet}</Option>
            ))}
          </Select>
          <div className="button-container">
            <Link
              to={
                subSubjectSetId
                  ? `/add-question?setId=${subSubjectSetId}&subSubjectId=${subSubjectId}`
                  : ""
              }
            >
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%", marginTop: "1rem" }}
                className="login-form-button notification-button"
              >
                Continue
              </Button>
            </Link>
          </div>
        </Modal>
        <SiderDemo>
          <Segmented
            style={{ height: "0px", fontSize: "11px", display: "none" }}
          />
          <div>
            <Card
              className="card"
              style={{ width: "100%", padding: "5px" }}
              loading={Class.isLoading}
            >
              {" "}
              <div
                style={{
                  background: "#5D3BED",
                  height: "50px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  paddingLeft: "20px",
                  color: "white",
                  fontWeight: "normal",
                }}
              >
                Start Exam
              </div>
              <Divider orientation="left">
                Please Select Subject or SubSubject
              </Divider>
              <div
                grid={{
                  xs: 1,
                  sm: 2,
                  md: 4,
                  lg: 4,
                  xl: 6,
                  xxl: 3,
                }}
                style={{
                  display: "flex",
                  width: "100%",
                  gap: "1rem",

                  flexWrap: "wrap",
                }}
              >
                {Class.subjects.length > 0 ? (
                  Class.subjects.map((subject, index) => {
                    return (
                      <Card
                        className="start-exam"
                        title={
                          <div className="top-subject-title">
                            <span>{subject.categoryName}</span>
                            <div
                              className="action-container"
                              style={{
                                display: "flex",
                              }}
                            >
                              <Tooltip title="Add Question">
                                <Button
                                  style={{
                                    border: "none",

                                    color: "#3FCF8D",
                                    borderRadius: "50%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                  icon={
                                    <PlusOutlined
                                      onClick={() => {
                                        showModalSubject();
                                        setSubjectId(subject.subjectCategoryId);
                                      }}
                                    />
                                  }
                                ></Button>
                              </Tooltip>
                              <Tooltip title="See Exam Set">
                                <Link
                                  to={`/subject-exam-set/${subject.subjectCategoryId}`}
                                >
                                  <Button
                                    style={{
                                      border: "none",

                                      color: "#FA8559",
                                      borderRadius: "50%",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                    icon={
                                      <RiFolderSettingsFill
                                        onClick={() => {
                                          showModalSubject();
                                          setSubjectId(
                                            subject.subjectCategoryId
                                          );
                                        }}
                                      />
                                    }
                                  ></Button>
                                </Link>
                              </Tooltip>
                              <Tooltip title="See Question List">
                                <Link
                                  to={`/question-list-subject/${subject.subjectCategoryId}`}
                                >
                                  <Button
                                    style={{
                                      border: "none",

                                      color: "#FF618C",
                                      borderRadius: "50%",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                    icon={
                                      <AiFillEye
                                        onClick={() => {
                                          showModalSubject();
                                          setSubjectId(
                                            subject.subjectCategoryId
                                          );
                                        }}
                                      />
                                    }
                                  ></Button>
                                </Link>
                              </Tooltip>
                            </div>
                          </div>
                        }
                        style={{ background: "white" }}
                      >
                        {Class.subjectSubCategory[index].length > 0
                          ? Class.subjectSubCategory[index].map((sub) => (
                              <div className="top-subject-title">
                                <span>{sub}</span>
                                <div
                                  style={{ display: "flex" }}
                                  className="action-container"
                                >
                                  <Tooltip title="Add Question">
                                    <Button
                                      style={{
                                        border: "none",

                                        color: "#3FCF8D",
                                        borderRadius: "50%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                      icon={
                                        <PlusOutlined
                                          onClick={() => {
                                            showModalSubSubject();
                                            setSubSubjectId(
                                              subject.subjectSubCategoryDetails.filter(
                                                (el) =>
                                                  el.subjectSubCategoryName ===
                                                  sub
                                              )[0].subjectSubCategoryId
                                            );
                                          }}
                                        />
                                      }
                                    ></Button>
                                  </Tooltip>{" "}
                                  <Tooltip title="See Exam Set">
                                    <Link
                                      to={`/sub-subject-exam-set/${
                                        subject.subjectSubCategoryDetails.filter(
                                          (el) =>
                                            el.subjectSubCategoryName === sub
                                        )[0].subjectSubCategoryId
                                      }`}
                                    >
                                      <Button
                                        style={{
                                          border: "none",

                                          color: "#FA8559",
                                          borderRadius: "50%",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                        icon={
                                          <RiFolderSettingsFill
                                            onClick={() => {
                                              showModalSubject();
                                              setSubjectId(
                                                subject.subjectCategoryId
                                              );
                                            }}
                                          />
                                        }
                                      ></Button>
                                    </Link>
                                  </Tooltip>
                                  <Tooltip title="See Question List">
                                    <Link
                                      to={`/question-list-sub-subject/${
                                        subject.subjectSubCategoryDetails.filter(
                                          (el) =>
                                            el.subjectSubCategoryName === sub
                                        )[0].subjectSubCategoryId
                                      }`}
                                    >
                                      <Button
                                        style={{
                                          border: "none",

                                          color: "#FF618C",
                                          borderRadius: "50%",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                        icon={
                                          <AiFillEye
                                            onClick={() => {
                                              showModalSubject();
                                              setSubjectId(
                                                subject.subjectCategoryId
                                              );
                                            }}
                                          />
                                        }
                                      ></Button>
                                    </Link>
                                  </Tooltip>
                                </div>
                              </div>
                            ))
                          : "No Sub Categories!"}
                      </Card>
                    );
                  })
                ) : (
                  <p>No Subjects in this class.</p>
                )}
              </div>
              {/* <ReactQuill
                style={{ background: "white" }}
                modules={{
                  toolbar: {
                    container: [
                      [{ header: [1, 2, 3, 4, 5, 6, false] }],
                      ["bold", "italic", "underline"],
                      [{ list: "ordered" }, { list: "bullet" }],
                      [{ align: [] }],
                      ["link", "image"],
                      ["clean"],
                      [{ color: [] }],
                    ],
                  },
                }}
                theme="snow"
                value={value ? value : ""}
                onChange={(e) => {
                  setValue(e);
                }}
              /> */}
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {" "}
                {/* <Button
                  type="primary"
                  htmlType="submit"
                  onClick={updateTermsHandler}
                  style={{ width: "30%", marginTop: "0.5rem" }}
                  className="login-form-button "
                >
                  Update Terms & Condition
                </Button> */}
              </div>
            </Card>
          </div>
        </SiderDemo>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    MassNotification: state.MassNotification,
    TermsAndPrivacy: state.TermsAndPrivacy,
    Exam: state.Exam,
    Class: state.Class,
  };
};

export default connect(mapStateToProps, {})(ExamSubject);
