import { openNotificationWithIcon } from "../../Components/Notification/Success";

const initialState = {
  loyaltyPoints: [],
  isLoading: false,
  isLoyaltyPointsChanged: false,
  loyaltyPoint: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case "GET_LOYALTY_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_LOYALTY_SUCCESS":
      //   openNotificationWithIcon("success", payload.message);
      return {
        ...state,
        isLoading: false,
        loyaltyPoints: payload,
      };
    case "GET_LOYALTY_FAILURE":
      openNotificationWithIcon("error", payload.data.message);
      return {
        ...state,
        isLoading: false,
      };

    case "ADD_LOYALTY_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "ADD_LOYALTY_SUCCESS":
      openNotificationWithIcon("success", payload.message);
      return {
        ...state,
        isLoading: false,
        isLoyaltyPointsChanged: state.isLoyaltyPointsChanged ? false : true,
      };
    case "ADD_LOYALTY_FAILURE":
      openNotificationWithIcon("error", payload.data.message);
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
}
