import React from "react";
import "./TableSkeleton.css";
const TableSkeleton = () => {
  return (
    <table class="tg">
      <tr>
        <th class="tg-cly1">
          <div class="line"></div>
        </th>
        <th class="tg-cly1">
          <div class="line"></div>
        </th>
        <th class="tg-cly1">
          <div class="line"></div>
        </th>
        <th class="tg-cly1">
          <div class="line"></div>
        </th>
        <th class="tg-cly1">
          <div class="line"></div>
        </th>
        <th class="tg-cly1">
          <div class="line"></div>
        </th>
        <th class="tg-cly1">
          <div class="line"></div>
        </th>
        <th class="tg-cly1">
          <div class="line"></div>
        </th>
      </tr>
      <tr>
        <td class="tg-cly1">
          <div class="line"></div>
        </td>
        <td class="tg-cly1">
          <div class="line"></div>
        </td>
        <td class="tg-cly1">
          <div class="line"></div>
        </td>
        <td class="tg-cly1">
          <div class="line"></div>
        </td>
        <td class="tg-cly1">
          <div class="line"></div>
        </td>
        <td class="tg-cly1">
          <div class="line"></div>
        </td>
        <td class="tg-cly1">
          <div class="line"></div>
        </td>
        <td class="tg-cly1">
          <div class="line"></div>
        </td>
      </tr>
      <tr>
        <td class="tg-cly1">
          <div class="line"></div>
        </td>
        <td class="tg-cly1">
          <div class="line"></div>
        </td>
        <td class="tg-cly1">
          <div class="line"></div>
        </td>
        <td class="tg-cly1">
          <div class="line"></div>
        </td>
        <td class="tg-cly1">
          <div class="line"></div>
        </td>
        <td class="tg-cly1">
          <div class="line"></div>
        </td>
        <td class="tg-cly1">
          <div class="line"></div>
        </td>
        <td class="tg-cly1">
          <div class="line"></div>
        </td>
      </tr>
      <tr>
        <td class="tg-cly1">
          <div class="line"></div>
        </td>
        <td class="tg-cly1">
          <div class="line"></div>
        </td>
        <td class="tg-cly1">
          <div class="line"></div>
        </td>
        <td class="tg-cly1">
          <div class="line"></div>
        </td>
        <td class="tg-cly1">
          <div class="line"></div>
        </td>
        <td class="tg-cly1">
          <div class="line"></div>
        </td>
        <td class="tg-cly1">
          <div class="line"></div>
        </td>
        <td class="tg-cly1">
          <div class="line"></div>
        </td>
      </tr>
      <tr>
        <td class="tg-cly1">
          <div class="line"></div>
        </td>
        <td class="tg-cly1">
          <div class="line"></div>
        </td>
        <td class="tg-cly1">
          <div class="line"></div>
        </td>
        <td class="tg-cly1">
          <div class="line"></div>
        </td>
        <td class="tg-cly1">
          <div class="line"></div>
        </td>
        <td class="tg-cly1">
          <div class="line"></div>
        </td>
        <td class="tg-cly1">
          <div class="line"></div>
        </td>
        <td class="tg-cly1">
          <div class="line"></div>
        </td>
      </tr>
    </table>
  );
};

export default TableSkeleton;
