import React, { useEffect, useState } from "react";
import Breadcrump from "../../Components/Breadcrump/Breadcrump";
import "../Classes Management/ClassManagement.css";
import { Table, Popconfirm } from "antd";
import Sidebar from "../../Components/Sidebar/Sidebar";
import { connect, useDispatch } from "react-redux";
import { Tag, Spin, Segmented } from "antd";
import { AiOutlineEdit } from "react-icons/ai";
import { AiOutlineDelete, AiOutlineEye } from "react-icons/ai";
import history from "../../Helpers/history";
import { useNavigate, useParams } from "react-router-dom";
import { Drawer, Button, Modal, TimePicker } from "antd";

import { AiFillPlusCircle } from "react-icons/ai";
import { AiTwotonePlusCircle } from "react-icons/ai";
import { Form, Input, Checkbox, Pagination } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { MdOutlineSubject } from "react-icons/md";
import { HiOutlineAcademicCap } from "react-icons/hi";
import { Select, Tooltip } from "antd";
import { MdDisabledVisible } from "react-icons/md";

import TableSkeleton from "../../Components/Table Skeleton/TableSkeleton";
import { PlusOutlined } from "@ant-design/icons";
import Navbar from "../../Components/Navbar/Navbar";
import SiderDemo from "../Layout/SiderDemo";
import EditSubjectExamSet from "./EditSubjectExamSet";
import moment from "moment";

const { Search } = Input;
const { Option } = Select;

const SubjectExamSet = ({ Class, Exam }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [classId, setClassId] = useState();
  const [form] = Form.useForm();
  const [searchValue, setSearchValue] = useState("");
  const [visible, setVisible] = useState(false);
  const [size, setSize] = useState("large");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { id } = useParams();
  const [activeData, setActiveData] = useState();

  const onFinish = (values) => {
    console.log("Received values of form: ", {
      ...values,
      duration: moment(values.duration).diff(
        moment().startOf("day"),
        "seconds"
      ),
    });
    dispatch({
      type: "ADD_SUBJECT_SET_REQUEST",
      payload: [
        {
          ...values,
          duration: moment(values.duration).diff(
            moment().startOf("day"),
            "seconds"
          ),
          id: id,
        },
      ],
    });
    setIsModalVisible(false);
    form.resetFields();
  };
  const enableDisableClassHandler = (id) => {
    dispatch({
      type: "ENABLE_DISABLE_CLASS_REQUEST",
      payload: {
        id: id,
      },
    });
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onClose = () => {
    setVisible(false);
  };

  useEffect(() => {
    dispatch({
      type: "GET_CLASSES_REQUEST",
      payload: {
        page: 1,
        pageSize: 10,
      },
    });
    dispatch({
      type: "GET_SUBJECT_SETS_REQUEST",
      payload: {
        id: id,
      },
    });
  }, [Exam.isExamChanged]);

  const deleteSetHandler = (id) => {
    dispatch({
      type: "DELETE_QUESTION_SET_REQUEST",
      payload: {
        id: id,
      },
    });
  };
  const detailClickHandler = (id, name) => {
    navigate(`/class-detail/${id}`, { state: name });
  };

  const sharedOnCell = (_, index) => {
    if (index === 4) {
      return { colSpan: 0 };
    }
  };
  function handleChange(value) {
    console.log(`selected ${value}`);
  }

  const columns = [
    {
      title: "Question Set",
      dataIndex: "questionSet",

      onCell: sharedOnCell,
      width: 300,
      align: "center",
    },

    {
      title: "Duration",
      dataIndex: "duration",
      onCell: sharedOnCell,
      width: 250,
      align: "center",
    },
    {
      title: "Added By",
      dataIndex: "addedBy",
      onCell: sharedOnCell,
      width: 150,
      align: "center",
    },
    {
      title: "Added At",
      dataIndex: "addedDate",
      onCell: sharedOnCell,
      width: 250,
      align: "center",
    },

    {
      title: "Actions",
      dataIndex: "actions",
      onCell: sharedOnCell,
      align: "center",
      width: 250,
    },
  ];

  const data =
    Exam.subjectSets &&
    Exam.subjectSets.map((el) => {
      return {
        questionSet: el.questionSet,
        duration: moment.utc(el.duration * 1000).format("HH:mm:ss"),
        addedBy: el.addedBy,
        addedDate: moment(el.addedDate).format("YYYY-MM-DD"),
        actions: (
          <div className="action">
            <Tooltip title="Delete Question">
              <Popconfirm
                title="Are you sure want to delete this exam set?"
                onConfirm={() => deleteSetHandler(el.examTimeId)}
              >
                <AiOutlineDelete className="icon" />
              </Popconfirm>
            </Tooltip>
            <Tooltip title="Edit Question Set">
              <AiOutlineEdit
                className="icon"
                onClick={() => {
                  setVisible(true);

                  setActiveData(el);
                }}
              />
            </Tooltip>
          </div>
        ),
      };
    });
  function onShowSizeChange(current, pageSize) {
    window.scrollTo(0, 0);
    dispatch({
      type: "GET_CLASSES_REQUEST",
      payload: {
        page: current,
        pageSize: pageSize,
      },
    });
  }
  const onSearch = (e) => {
    dispatch({
      type: "SEARCH_CLASSES_REQUEST",
      payload: {
        pageNo: 1,
        pageSize: 10,
        keyword: e,
      },
    });
  };
  return (
    <>
      <div className="container">
        <Modal
          title="New Question Set"
          visible={isModalVisible}
          onCancel={handleCancel}
          footer={null}
        >
          <Spin spinning={Exam.isLoading}>
            <Form
              name="form"
              form={form}
              className="login-form"
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
            >
              <Form.Item
                label="Question Set"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
                name="questionSet"
                rules={[
                  {
                    required: true,
                    message: "Please input question set!",
                  },
                ]}
              >
                <Input placeholder="Question set" />
              </Form.Item>
              <Form.Item
                label="Duration"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
                name="duration"
                rules={[
                  {
                    required: true,
                    message: "Please input duration!",
                  },
                ]}
              >
                <TimePicker />
              </Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{ margin: "auto", width: "100%" }}
                className="login-form-button"
              >
                Add
              </Button>
            </Form>
          </Spin>
        </Modal>
        <SiderDemo>
          <Segmented
            style={{ height: "0px", fontSize: "11px", display: "none" }}
          />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: "2rem",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {" "}
                <h2 className="title-course">Exam Sets</h2>
                <Button
                  type="primary"
                  style={{ background: "#FF4D4F" }}
                  danger
                  onClick={showModal}
                >
                  Add
                </Button>
              </div>
            </div>

            {/* <nav class="menu"></nav> */}
            {Exam.isLoading ? (
              <TableSkeleton />
            ) : (
              <Table
                pagination={false}
                columns={columns}
                dataSource={data}
                bordered
                className="table-course"
              />
            )}
            {/* {Class.classes.totalPage && (
              <Pagination
                style={{
                  marginTop: "1rem",
                  marginBottom: "1rem",
                  alignSelf: "flex-end",
                }}
                showSizeChanger
                onShowSizeChange={onShowSizeChange}
                defaultCurrent={1}
                onChange={onShowSizeChange}
                total={Class.classes.totalPage * 10}
              />
            )} */}
            <Drawer
              title={<span style={{}}>Edit Exam Set</span>}
              placement="right"
              width={"85%"}
              size={size}
              onClose={onClose}
              visible={visible}
            >
              <EditSubjectExamSet
                onClose={onClose}
                data={activeData}
                id={id}
                Exam={Exam}
              />
            </Drawer>
          </div>
        </SiderDemo>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    Class: state.Class,
    Exam: state.Exam,
  };
};

export default connect(mapStateToProps, {})(SubjectExamSet);
