import { Input, Collapse, DatePicker } from "antd";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import "../Classes Management/ClassManagement.css";
import { Select } from "antd";
import { List, Tag, Popconfirm, Typography, Spin } from "antd";
import { CaretRightOutlined, PlusOutlined } from "@ant-design/icons";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import { Tooltip, Button } from "antd";
import { Upload, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { Form, Checkbox } from "antd";
import "moment/locale/zh-cn";
import locale from "antd/lib/locale/zh_CN";
import moment from "moment";
import TextArea from "antd/lib/input/TextArea";
const { Option } = Select;
const { Panel } = Collapse;
const { Paragraph } = Typography;
const { RangePicker } = DatePicker;

const EditCouponCode = (props) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [fileName, setFileName] = useState();
  const onFinish = (values) => {
    console.log("Received values of form: ", values);

    dispatch({
      type: "UPDATE_COUPON_REQUEST",
      payload: {
        ...values,
        couponCodeId: props.id,
        startDate: values.Picker[0].format("YYYY-MM-DD HH:mm:ss"),
        endDate: values.Picker[1].format("YYYY-MM-DD HH:mm:ss"),
      },
    });
    props.onClose();
    form.resetFields();
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  useEffect(() => {
    dispatch({
      type: "GET_SINGLE_COUPON_REQUEST",
      payload: {
        id: props.id,
      },
    });
  }, [props.id]);
  function handleChange(value) {
    console.log(`selected ${value}`);
  }
  const data = [
    "Racing car sprays burning fuel into crowd.",
    "Japanese princess to wed commoner.",
    "Australian walks 100km after outback crash.",
    "Man charged over missing wedding girl.",
    "Los Angeles battles huge wildfires.",
  ];

  const [ad, setAd] = useState(
    props.Advertisement.advertisement &&
      props.Advertisement.advertisement.userType
  );
  const prop = {
    onChange(info) {
      setFileName(info.file.name);
    },
  };
  function onChange(value, dateString) {
    console.log("Selected Time: ", value);
    console.log("Formatted Selected Time: ", dateString);
  }

  function onOk(value) {
    console.log("onOk: ", value);
  }
  return (
    <div style={{ width: "50%", margin: "auto" }}>
      <Spin spinning={props.CouponCode.isLoading}>
        <Form
          name="form"
          fields={[
            {
              name: ["code"],
              value: props.CouponCode.coupon.code,
            },
            {
              name: ["discountAmount"],
              value: props.CouponCode.coupon.discountAmount,
            },
            {
              name: ["couponType"],
              value: props.CouponCode.coupon.couponType,
            },
            {
              name: ["applicableToTeacher"],
              value: props.CouponCode.coupon.applicableToTeacher
                ? "true"
                : "false",
            },
            {
              name: ["applicableToStudent"],
              value: props.CouponCode.coupon.applicableToStudent
                ? "true"
                : "false",
            },
            {
              name: ["isDisabled"],
              value: props.CouponCode.coupon.isDisabled ? "true" : "false",
            },
            {
              name: ["isVisibleToUser"],
              value: props.CouponCode.coupon.isVisibleToUser ? "true" : "false",
            },
            {
              name: ["sendNotification"],
              value: props.CouponCode.coupon.sendNotification
                ? "true"
                : "false",
            },
            {
              name: ["description"],
              value: props.CouponCode.coupon.description,
            },
            {
              name: ["Picker"],
              value: [
                moment(props.CouponCode.coupon.startDate),
                moment(props.CouponCode.coupon.endDate),
              ],
            },
            // {
            //   name: ["startDate"],
            //   value: props.CouponCode.coupon.startDate,
            // },
            // {
            //   name: ["endDate"],
            //   value: props.CouponCode.coupon.endDate,
            // },
          ]}
          encType="multipart/form-data"
          form={form}
          className="login-form"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
        >
          <Form.Item
            label="Coupon Code"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              width: "100%",
            }}
            name="code"
            rules={[
              {
                required: true,
                message: "Please input  code!",
              },
            ]}
          >
            <Input placeholder="Coupon code" />
          </Form.Item>
          <Form.Item
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              width: "100%",
            }}
            label="Discount Amount"
            name="discountAmount"
            rules={[
              {
                required: true,
                message: "Please input discount amount!",
              },
            ]}
          >
            <Input placeholder="Discount Amount" />
          </Form.Item>
          <Form.Item
            name="couponType"
            label="Coupon Type"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              width: "100%",
            }}
            rules={[
              {
                required: true,
                message: "Please input coupon type!",
              },
            ]}
          >
            <Select>
              <Option value="FLAT">Flat</Option>
              <Option value="PERCENTAGE">Percentage</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Applicable to Teacher"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              width: "100%",
            }}
            name="applicableToTeacher"
            rules={[
              {
                required: true,
                message: "Please input applicable to teacher!",
              },
            ]}
          >
            <Select>
              <Option value="true">Yes</Option>
              <Option value="false">No</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Applicable to Student"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              width: "100%",
            }}
            name="applicableToStudent"
            rules={[
              {
                required: true,
                message: "Please input applicable to student!",
              },
            ]}
          >
            <Select>
              <Option value="true">Yes</Option>
              <Option value="false">No</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Date Range:"
            name="Picker"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              width: "100%",
            }}
            rules={[
              {
                required: true,
                message: "Please input dateRange!",
              },
            ]}
          >
            <RangePicker
              showTime={{ format: "HH:mm" }}
              format="YYYY-MM-DD HH:mm:ss"
              onChange={onChange}
              onOk={onOk}
            />
          </Form.Item>
          <Form.Item
            name="isDisabled"
            label="Is Disabled"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              width: "100%",
            }}
            rules={[
              {
                required: true,
                message: "Please input is disabled!",
              },
            ]}
          >
            <Select>
              <Option value="true">Yes</Option>
              <Option value="false">No</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="isVisibleToUser"
            label="Is Visible To User"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              width: "100%",
            }}
            rules={[
              {
                required: true,
                message: "Please input is visible to user!",
              },
            ]}
          >
            <Select>
              <Option value="true">Yes</Option>
              <Option value="false">No</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="sendNotification"
            label="Send Notification"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              width: "100%",
            }}
            rules={[
              {
                required: true,
                message: "Please input send notification!",
              },
            ]}
          >
            <Select>
              <Option value="true">Yes</Option>
              <Option value="false">No</Option>
            </Select>
          </Form.Item>
          <Form.Item
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              width: "100%",
            }}
            label="Description"
            name="description"
            rules={[
              {
                required: true,
                message: "Please input description!",
              },
            ]}
          >
            <TextArea placeholder="Description" />
          </Form.Item>

          <Button
            type="primary"
            htmlType="submit"
            style={{ margin: "auto", width: "100%" }}
            className="login-form-button"
          >
            Update
          </Button>
        </Form>
      </Spin>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    Class: state.Class,
    Advertisement: state.Advertisement,
  };
};

export default connect(mapStateToProps, {})(EditCouponCode);
