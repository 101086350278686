import { Input, Collapse } from "antd";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import "../Classes Management/ClassManagement.css";
import { Select } from "antd";
import { List, Tag, Popconfirm, Typography, Spin } from "antd";
import { CaretRightOutlined, PlusOutlined } from "@ant-design/icons";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import { Tooltip, Button } from "antd";
import { Upload, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import moment from "moment";
import { Form, Checkbox, TimePicker } from "antd";

const { Option } = Select;
const { Panel } = Collapse;
const { Paragraph } = Typography;

const EditSubjectExamSet = (props) => {
  const dispatch = useDispatch();
  const [activeData, setActiveData] = useState();
  const [form] = Form.useForm();
  const [defaultDate, setDefaultDate] = useState(props.data.duration);
  const onFinish = (values) => {
    console.log("Received values of form: ", values);
    dispatch({
      type: "UPDATE_SUBJECT_QUESTION_SET_REQUEST",
      payload: [
        {
          ...values,
          duration: values.duration
            ? moment(values.duration).diff(moment().startOf("day"), "seconds")
            : props.data.duration,
          id: props.id,
          examTimeId: props.data.examTimeId,
        },
      ],
    });
    form.resetFields();
    props.onClose();
  };

  useEffect(() => {
    setActiveData(props.data);
  }, [props.data]);

  return (
    <div style={{ width: "50%", margin: "auto" }}>
      <Spin spinning={props.Exam.isLoading}>
        <Form
          name="form"
          fields={[
            {
              name: ["questionSet"],
              value: props.data.questionSet,
            },
          ]}
          encType="multipart/form-data"
          form={form}
          className="login-form"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
        >
          <Form.Item
            label="Question Set"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
            name="questionSet"
            rules={[
              {
                required: true,
                message: "Please input question set!",
              },
            ]}
          >
            <Input placeholder="Question set" />
          </Form.Item>
          <Form.Item
            label="Duration"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
            name="duration"
            // rules={[
            //   {
            //     required: true,
            //     message: "Please input duration!",
            //   },
            // ]}
          >
            <TimePicker
              // defaultValue={moment(props.data.duration)}
              value={moment(
                moment.utc(props.data.duration * 1000).format("HH:mm:ss"),
                "HH:mm:ss"
              )}
              defaultValue={moment(
                moment.utc(props.data.duration * 1000).format("HH:mm:ss"),
                "HH:mm:ss"
              )}
              format={"HH:mm:ss"}
            />
          </Form.Item>

          <Button
            type="primary"
            htmlType="submit"
            style={{ margin: "auto", width: "100%" }}
            className="login-form-button"
          >
            Update
          </Button>
        </Form>
      </Spin>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    Class: state.Class,
    Advertisement: state.Advertisement,
    LoyaltyPoints: state.LoyaltyPoints,
  };
};

export default connect(mapStateToProps, {})(EditSubjectExamSet);
