import { Input, Collapse } from "antd";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import "./ClassManagement.css";
import { Select } from "antd";
import { List, Tag, Popconfirm, Typography, Spin } from "antd";
import { CaretRightOutlined, PlusOutlined } from "@ant-design/icons";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import { Tooltip, Button, Form, Modal } from "antd";
import Modals from "./Modal";
import SubjectModal from "./SubjectModal";
const { Option } = Select;
const { Panel } = Collapse;

const { Paragraph } = Typography;

const EditClass = (props) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [isSubjectModalVisible, setIsSubjectModalVisible] = useState(false);
  const [subjectModalVisible, setSubjectModalVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [categoryName, setCategoryName] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [classId, setClassId] = useState("");
  const [subjectSubCategoryId, setSubjectCategoryId] = useState("");
  const [newSubject, setNewSubject] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [isAddSubSubjectOpen, setIsAddSubSubjectOpen] = useState(false);
  const [defaultClass, setDefaultCLass] = useState(
    props.Class.class.classStandard
  );

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  useEffect(() => {
    dispatch({
      type: "GET_CLASS_REQUEST",
      payload: {
        id: props.id,
      },
    });

    dispatch({
      type: "GET_SUBJECT_CATEGORY_REQUEST",
      payload: {
        id: props.id,
      },
    });
  }, [props.id, props.Class.isClassChanged]);
  function handleChange(value) {
    console.log(`selected ${value}`);
  }
  const data = [
    "Racing car sprays burning fuel into crowd.",
    "Japanese princess to wed commoner.",
    "Australian walks 100km after outback crash.",
    "Man charged over missing wedding girl.",
    "Los Angeles battles huge wildfires.",
  ];
  const onFinish = (values) => {
    console.log("Received values of form: ", values);
    dispatch({
      type: "UPDATE_CLASS_REQUEST",
      payload: { ...values, subjectClassId: props.id },
    });
    props.onClose();
  };
  const handleCancelSubject = () => {
    setIsSubjectModalVisible(false);
  };
  const handleAddSubject = () => {
    dispatch({
      type: "ADD_SUBJECT_REQUEST",
      payload: {
        subjectClassId: props.id,
        categoryName: newSubject,
        classStandard: props.Class.class.classStandard,
        classType: props.Class.class.classType,
      },
    });
    setNewSubject("");
    setIsSubjectModalVisible(false);
  };
  const handleAddSubSubject = () => {
    dispatch({
      type: "ADD_SUB_SUBJECT_REQUEST",
      payload: {
        subjectCategoryId: categoryId,
        categoryName: newSubject,
      },
    });
    setNewSubject("");
    setIsSubjectModalVisible(false);
  };
  return (
    <div style={{ width: "60%", margin: "auto" }}>
      <Modal
        title={isAddSubSubjectOpen ? "Add Sub Subject" : "Add Subject"}
        visible={isSubjectModalVisible}
        okText="Add"
        onCancel={handleCancelSubject}
        onOk={isAddSubSubjectOpen ? handleAddSubSubject : handleAddSubject}
      >
        <Spin spinning={props.Class.isLoading}>
          <Input
            placeholder={
              isAddSubSubjectOpen ? "Enter Sub Subject" : "Enter subject name"
            }
            value={newSubject}
            onChange={(e) => setNewSubject(e.target.value)}
          />
        </Spin>
      </Modal>
      <Modals
        showModal={showModal}
        handleCancel={handleCancel}
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        categoryName={categoryName}
        classId={classId}
        categoryId={categoryId}
        subCategory={subCategory}
        subjectCategoryId={subjectSubCategoryId}
      />
      <div
        className="edit-class-container"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <Spin spinning={props.Class.isLoading}>
          <Form
            fields={[
              {
                name: ["classStandard"],
                value: props.Class.class.classStandard,
              },
              {
                name: ["classType"],
                value: props.Class.class.classType,
              },
            ]}
            form={form}
            onFinish={onFinish}
          >
            <Form.Item
              label=" Class Name"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                width: "100%",
              }}
              name="classStandard"
              rules={[
                {
                  required: true,
                  message: "Please input  class name!",
                },
              ]}
            >
              <Input placeholder="Name" />
            </Form.Item>

            <Form.Item
              label="Class Type"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                width: "100%",
              }}
              name="classType"
              rules={[
                {
                  required: true,
                  message: "Please input class type!",
                },
              ]}
            >
              <Select>
                <Option value="ACADEMIC">ACADEMIC</Option>
                <Option value="NON_ACADEMIC">NON_ACADEMIC</Option>
              </Select>
            </Form.Item>
            <div className="label-and-input">
              <List
                size="small"
                header={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <span>Subjects</span>
                    <Tooltip title="Add Subject">
                      <Button
                        onClick={() => {
                          setIsAddSubSubjectOpen(false);
                          setIsSubjectModalVisible(true);
                        }}
                        type="primary"
                        shape="circle"
                        icon={<PlusOutlined />}
                      />
                    </Tooltip>
                  </div>
                }
                bordered
                dataSource={data}
              >
                {props.Class.subjects.length > 0 ? (
                  props.Class.subjects.map((subject, index) => {
                    return (
                      <Collapse
                        expandIcon={({ isActive }) => (
                          <CaretRightOutlined rotate={isActive ? 90 : 0} />
                        )}
                        className="site-collapse-custom-collapse"
                      >
                        <Panel
                          header={
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",

                                width: "100%",
                                alignItems: "flex-start",
                              }}
                            >
                              {subject.categoryName}

                              <div
                                className="action"
                                style={{ display: "flex", margin: "5px" }}
                              >
                                {" "}
                                <AiOutlineEdit
                                  className="icon"
                                  onClick={() => {
                                    setCategoryName(subject.categoryName);
                                    setCategoryId(subject.subjectCategoryId);
                                    setClassId(subject.subjectClassId);
                                    setSubCategory("");
                                    showModal();
                                  }}
                                />{" "}
                                <Popconfirm
                                  title="Are you sure want to delete?"
                                  onConfirm={() => {
                                    dispatch({
                                      type: "DELETE_SUBJECT_REQUEST",
                                      payload: {
                                        id: subject.subjectCategoryId,
                                      },
                                    });
                                  }}
                                >
                                  <AiOutlineDelete className="icon" />
                                </Popconfirm>
                              </div>
                            </div>
                          }
                          key="1"
                          className="site-collapse-custom-panel"
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "10px",
                            }}
                          >
                            <Tooltip title="Add Sub Subject">
                              <Button
                                onClick={() => {
                                  setCategoryId(subject.subjectCategoryId);
                                  setIsAddSubSubjectOpen(true);
                                  setIsSubjectModalVisible(true);
                                }}
                                type="primary"
                                shape="circle"
                                icon={<PlusOutlined />}
                              />
                            </Tooltip>
                            {props.Class.subjectSubCategory[index].length > 0
                              ? props.Class.subjectSubCategory[index].map(
                                  (sub) => (
                                    <Tag
                                      style={{
                                        marginRight: "1rem",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        padding: "5px",
                                      }}
                                      color="blue"
                                    >
                                      {sub}

                                      <div
                                        className="action"
                                        style={{ display: "flex" }}
                                      >
                                        {" "}
                                        <AiOutlineEdit
                                          className="icon"
                                          onClick={() => {
                                            setSubCategory(sub);
                                            setSubjectCategoryId(
                                              subject.subjectSubCategoryDetails.filter(
                                                (el) =>
                                                  el.subjectSubCategoryName ===
                                                  sub
                                              )[0].subjectSubCategoryId
                                            );
                                            setCategoryId(
                                              subject.subjectSubCategoryDetails.filter(
                                                (el) =>
                                                  el.subjectSubCategoryName ===
                                                  sub
                                              )[0].subjectCategoryId
                                            );
                                            setCategoryName("");
                                            showModal();
                                          }}
                                        />{" "}
                                        <Popconfirm
                                          title="Are you sure want to delete?"
                                          onConfirm={() => {
                                            dispatch({
                                              type: "DELETE_SUB_SUBJECT_REQUEST",
                                              payload: {
                                                id: subject.subjectSubCategoryDetails.filter(
                                                  (el) =>
                                                    el.subjectSubCategoryName ===
                                                    sub
                                                )[0].subjectSubCategoryId,
                                              },
                                            });
                                          }}
                                        >
                                          <AiOutlineDelete className="icon" />
                                        </Popconfirm>
                                      </div>
                                    </Tag>
                                  )
                                )
                              : "No Sub Categories!"}
                          </div>
                        </Panel>
                      </Collapse>
                    );
                  })
                ) : (
                  <p>No Subjects in this class.</p>
                )}
              </List>
            </div>

            <Button
              type="primary"
              htmlType="submit"
              style={{ margin: "auto", width: "100%", marginTop: "1rem" }}
              className="login-form-button"
            >
              Update
            </Button>
          </Form>
        </Spin>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    Class: state.Class,
  };
};

export default connect(mapStateToProps, {})(EditClass);
