import React, { useEffect, useState } from "react";
import "../Advertisement/Advertisement.css";
import "../Classes Management/ClassManagement.css";
import { Button, Card, Segmented, Pagination } from "antd";
import { connect, useDispatch } from "react-redux";
import SiderDemo from "../Layout/SiderDemo";
import { Divider, Input } from "antd";
import { Link } from "react-router-dom";
import "../Exam Management/ExamSubject.css";

const { Search } = Input;

const ExamManagement = ({ TermsAndPrivacy, Exam, Class }) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    dispatch({
      type: "GET_CLASSES_REQUEST",
      payload: {
        page: page,
        pageSize: pageSize,
      },
    });
  }, [Class.isClassChanged, Exam.isExamChanged]);

  const data =
    Class.classes.data &&
    Class.classes.data.map((classItem) => {
      return { cl: classItem.classStandard, id: classItem.subjectClassId };
    });
  function onShowSizeChange(current, pageSize) {
    window.scrollTo(0, 0);
    dispatch({
      type: "GET_CLASSES_REQUEST",
      payload: {
        page: current,
        pageSize: pageSize,
      },
    });
  }

  const onSearch = (e) => {
    dispatch({
      type: "SEARCH_CLASSES_REQUEST",
      payload: {
        pageNo: 1,
        pageSize: 10,
        keyword: e,
      },
    });
  };
  return (
    <>
      <div className="container">
        {" "}
        <SiderDemo>
          <Segmented
            style={{ height: "0px", fontSize: "11px", display: "none" }}
          />
          <div>
            <Card
              className="card"
              style={{ width: "100%", padding: "5px" }}
              loading={TermsAndPrivacy.isLoading}
            >
              <div
                style={{
                  background: "#5D3BED",
                  height: "50px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  paddingLeft: "20px",
                  color: "white",
                  fontWeight: "normal",
                }}
              >
                Resources Management
              </div>
              <div
                style={{
                  display: "flex",
                  margin: "1rem",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <span style={{ fontSize: "16px" }}>Please Select Class</span>
                <Search
                  style={{ width: "35%", borderRadius: "10px" }}
                  placeholder="Search for classes."
                  allowClear
                  size="large"
                  onChange={(e) => onSearch(e.target.value)}
                />
              </div>

              <div className="class-card-container">
                {" "}
                {data &&
                  data.map((item) => {
                    return (
                      <Card
                        className="start-exam"
                        style={{
                          display: "flex",
                          marginBottom: "1rem",
                          justifyContent: "space-between",
                          width: "48%",
                          position: "relative",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                            height: "100%",
                            alignItems: "center",
                          }}
                        >
                          {" "}
                          {item.cl}{" "}
                          <Link to={`/subject-list/${item.id}`}>
                            <Button style={{ border: "1px solid #FF4D50" }}>
                              Continue{" "}
                            </Button>
                          </Link>
                        </div>
                      </Card>
                    );
                  })}
              </div>
            </Card>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              {Class.classes.totalPage && (
                <Pagination
                  style={{
                    marginTop: "1rem",
                    marginBottom: "1rem",
                    alignSelf: "flex-end",
                  }}
                  showSizeChanger
                  onShowSizeChange={onShowSizeChange}
                  defaultCurrent={1}
                  onChange={onShowSizeChange}
                  total={Class.classes.totalPage * 10}
                />
              )}
            </div>
          </div>
        </SiderDemo>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    MassNotification: state.MassNotification,
    TermsAndPrivacy: state.TermsAndPrivacy,
    Exam: state.Exam,
    Class: state.Class,
  };
};

export default connect(mapStateToProps, {})(ExamManagement);
