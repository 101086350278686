import { openNotificationWithIcon } from "../../Components/Notification/Success";

const initialState = {
  supports: [],
  isLoading: false,
  isSupportChanged: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case "GET_SUPPORT_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_SUPPORT_SUCCESS":
      //   openNotificationWithIcon("success", payload.message);
      return {
        ...state,
        isLoading: false,
        supports: payload,
      };
    case "GET_SUPPORT_FAILURE":
      openNotificationWithIcon("error", payload.data.message);
      return {
        ...state,
        isLoading: false,
      };

    case "MARK_SUPPORT_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "MARK_SUPPORT_SUCCESS":
      openNotificationWithIcon("success", payload.message);
      return {
        ...state,
        isLoading: false,
        isSupportChanged: state.isSupportChanged ? false : true,
      };
    case "MARK_SUPPORT_FAILURE":
      openNotificationWithIcon("error", payload.data.message);
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
}
