import { Input, Collapse } from "antd";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import "../Classes Management/ClassManagement.css";
import { Select } from "antd";
import { List, Tag, Popconfirm, Typography, Spin } from "antd";
import { CaretRightOutlined, PlusOutlined } from "@ant-design/icons";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import { Tooltip, Button } from "antd";
import { Upload, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { Form, Checkbox } from "antd";

const { Option } = Select;
const { Panel } = Collapse;
const { Paragraph } = Typography;

const EditAdvertisement = (props) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [fileName, setFileName] = useState();
  const [imageUrl, setImageUrl] = useState();
  const onFinish = (values) => {
    console.log("Received values of form: ", values);
    const advertisement = new FormData();
    advertisement.append("name", values.name);
    advertisement.append("companyName", values.companyName);
    advertisement.append("companyUrl", values.companyUrl);
    advertisement.append("userType", values.userType);
    imageUrl && advertisement.append("file", imageUrl);
    advertisement.append("isActive", values.isActive);
    advertisement.append("id", props.id);

    dispatch({
      type: "UPDATE_ADVERTISEMENT_REQUEST",
      payload: advertisement,
    });
    props.onClose();
  };
  const imageChangeHandler = (e) => {
    setImageUrl(e.target.files[0]);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  useEffect(() => {
    dispatch({
      type: "GET_SINGLE_AD_REQUEST",
      payload: {
        id: props.id,
      },
    });
  }, [props.id, props.Advertisement.isAdvertisementChanged]);
  function handleChange(value) {
    console.log(`selected ${value}`);
  }
  const data = [
    "Racing car sprays burning fuel into crowd.",
    "Japanese princess to wed commoner.",
    "Australian walks 100km after outback crash.",
    "Man charged over missing wedding girl.",
    "Los Angeles battles huge wildfires.",
  ];

  const [ad, setAd] = useState(
    props.Advertisement.advertisement &&
      props.Advertisement.advertisement.userType
  );

  return (
    <div style={{ width: "50%", margin: "auto" }}>
      <Spin spinning={props.Advertisement.isLoading}>
        <Form
          name="form"
          fields={[
            {
              name: ["name"],
              value: props.Advertisement.advertisement.promotionName,
            },
            {
              name: ["companyName"],
              value: props.Advertisement.advertisement.companyName,
            },
            {
              name: ["companyUrl"],
              value: props.Advertisement.advertisement.companyUrl,
            },
            {
              name: ["userType"],
              value: props.Advertisement.advertisement.promotionType,
            },
            {
              name: ["isActive"],
              value: props.Advertisement.advertisement.active
                ? "TRUE"
                : "FALSE",
            },
          ]}
          encType="multipart/form-data"
          form={form}
          name="normal_login"
          className="login-form"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
        >
          <Form.Item
            label="Name"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              width: "100%",
            }}
            name="name"
            rules={[
              {
                required: true,
                message: "Please input  name!",
              },
            ]}
          >
            <Input placeholder="Name" />
          </Form.Item>
          <Form.Item
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              width: "100%",
            }}
            label="Company Name"
            name="companyName"
            rules={[
              {
                required: true,
                message: "Please input company name!",
              },
            ]}
          >
            <Input placeholder="Company Name" />
          </Form.Item>
          <Form.Item
            name="companyUrl"
            label="Company URL"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              width: "100%",
            }}
            rules={[
              {
                required: true,
                message: "Please input company url!",
              },
            ]}
          >
            <Input placeholder="Company URL" />
          </Form.Item>
          <Form.Item
            initialValue="STUDENT"
            label="User Type"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              width: "100%",
            }}
            name="userType"
            rules={[
              {
                required: true,
                message: "Please input user type!",
              },
            ]}
          >
            <Select>
              <Option value="STUDENT">Student</Option>
              <Option value="TEACHER">Teacher</Option>
              <Option value="all">Both</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Active"
            name="isActive"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              width: "100%",
            }}
            rules={[
              {
                required: true,
                message: "Please input active type!",
              },
            ]}
          >
            <Select>
              <Option value="TRUE">True</Option>
              <Option value="FALSE">False</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Image"
            name="file"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              width: "100%",
            }}
          >
            <img
              src={props.Advertisement.advertisement.image}
              style={{ height: "70px", marginRight: "1rem" }}
              alt=""
            />
            <input
              style={{ display: "inline" }}
              onChange={(e) => imageChangeHandler(e)}
              type="file"
              className="fileinput"
            />
          </Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            style={{ margin: "auto", width: "100%" }}
            className="login-form-button"
          >
            Update
          </Button>
        </Form>
      </Spin>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    Class: state.Class,
    Advertisement: state.Advertisement,
  };
};

export default connect(mapStateToProps, {})(EditAdvertisement);
