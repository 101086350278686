import { Input, Collapse } from "antd";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import "../Classes Management/ClassManagement.css";
import { Select } from "antd";
import { List, Tag, Popconfirm, Typography, Spin } from "antd";
import { CaretRightOutlined, PlusOutlined } from "@ant-design/icons";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import { Tooltip, Button } from "antd";
import { Upload, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { Form, Checkbox } from "antd";

const { Option } = Select;
const { Panel } = Collapse;
const { Paragraph } = Typography;

const EditAdvertisement = (props) => {
  const dispatch = useDispatch();
  const [activeData, setActiveData] = useState();
  const [form] = Form.useForm();
  const onFinish = (values) => {
    dispatch({
      type: "ADD_COMMISSION_REQUEST",
      payload: values,
    });
    form.resetFields();
    props.onClose();
  };

  useEffect(() => {
    setActiveData(props.data);
  }, [props.data]);
  //   let rateType;
  //   const exactData = {
  //     ...props.commissions.map((com) => {
  //       rateType = com.rateType;
  //       return { rateType: {} };
  //     }),
  //   };

  return (
    <div style={{ width: "50%", margin: "auto" }}>
      <Spin spinning={props.Advertisement.isLoading}>
        <Form
          name="form"
          fields={[
            {
              name: ["rateType"],
              value: props.data.rateType,
            },
            {
              name: ["amount"],
              value: props.data.amount,
            },

            {
              name: ["type"],
              value: props.data.type,
            },
          ]}
          encType="multipart/form-data"
          form={form}
          name="normal_login"
          className="login-form"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
        >
          <Form.Item
            label="Name"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              width: "100%",
            }}
            name="rateType"
            rules={[
              {
                required: true,
                message: "Please input  name!",
              },
            ]}
          >
            <Input placeholder="Name" disabled={true} />
          </Form.Item>
          <Form.Item
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              width: "100%",
            }}
            label="Amount"
            name="amount"
            rules={[
              {
                required: true,
                message: "Please input amount!",
              },
            ]}
          >
            <Input placeholder="Amount" />
          </Form.Item>
          <Form.Item
            label="Discount Type"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              width: "100%",
            }}
            name="type"
            rules={[
              {
                required: true,
                message: "Please input type!",
              },
            ]}
          >
            <Select>
              <Option value="FLAT">FLAT</Option>
              <Option value="PERCENTAGE">PERCENTAGE</Option>
            </Select>
          </Form.Item>

          <Button
            type="primary"
            htmlType="submit"
            style={{ margin: "auto", width: "100%" }}
            className="login-form-button"
          >
            Update
          </Button>
        </Form>
      </Spin>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    Class: state.Class,
    Advertisement: state.Advertisement,
  };
};

export default connect(mapStateToProps, {})(EditAdvertisement);
