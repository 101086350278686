import React, { useEffect, useState } from "react";
import "../Advertisement/Advertisement.css";
import "../Classes Management/ClassManagement.css";
import { Table, Popconfirm, DatePicker } from "antd";
import { connect, useDispatch } from "react-redux";
import { Tag, Spin } from "antd";
import { AiOutlineEdit } from "react-icons/ai";
import { AiOutlineDelete } from "react-icons/ai";
import { Drawer, Button, Modal } from "antd";
import { Form, Input } from "antd";
import { Select, Tooltip } from "antd";
import { MdDisabledVisible } from "react-icons/md";
import { Pagination } from "antd";
import EditCouponCode from "./EditCouponCode";
import TableSkeleton from "../../Components/Table Skeleton/TableSkeleton";
import SiderDemo from "../Layout/SiderDemo";
import moment from "moment";

const { Option } = Select;
const { Search } = Input;
const { RangePicker } = DatePicker;

const CouponCode = ({ CouponCode }) => {
  const dispatch = useDispatch();
  const [couponId, setCouponId] = useState();
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [size, setSize] = useState("large");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const onFinish = (values) => {
    console.log("Received values of form: ", values);
    dispatch({
      type: "ADD_COUPON_REQUEST",
      payload: {
        ...values,
        startDate: values.dateRange[0].format("YYYY-MM-DD HH:mm:ss"),
        endDate: values.dateRange[1].format("YYYY-MM-DD HH:mm:ss"),
      },
    });
    setIsModalVisible(false);
    form.resetFields();
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onClose = () => {
    setVisible(false);
  };

  useEffect(() => {
    dispatch({
      type: "GET_COUPON_CODE_REQUEST",
      payload: {
        page: 1,
        pageSize: 10,
      },
    });
  }, [CouponCode.isCouponCodeChanged]);

  const disableAdHandler = (id) => {
    dispatch({
      type: "DISABLE_COUPON_REQUEST",
      payload: {
        id: id,
      },
    });
  };
  const deleteClassHandler = (id) => {
    dispatch({
      type: "DELETE_COUPON_REQUEST",
      payload: {
        id: id,
      },
    });
  };

  function handleChange(value) {
    console.log(`selected ${value}`);
  }

  const columns = [
    {
      title: "Coupon Code",
      dataIndex: "code",

      width: 300,
      align: "center",
    },

    {
      title: "Discount Amount",
      dataIndex: "discountAmount",

      width: 250,
      align: "center",
    },
    {
      title: "Coupon Type",
      dataIndex: "couponType",

      width: 150,
      align: "center",
    },
    {
      title: "Applicable to teacher",
      dataIndex: "applicableToTeacher",

      width: 250,
      align: "center",
    },
    {
      title: "Applicable to student",
      dataIndex: "applicableToStudent",

      width: 100,
      align: "center",
    },

    {
      title: "Start Date",
      dataIndex: "startDate",

      width: 300,
      align: "center",
    },
    {
      title: "End Date",
      dataIndex: "endDate",

      width: 300,
      align: "center",
    },
    {
      title: "Active",
      dataIndex: "isDisabled",

      width: 250,
      align: "center",
    },
    {
      title: "Actions",
      dataIndex: "actions",

      align: "center",
      width: 700,
    },
  ];

  const data =
    CouponCode.couponCodes.data &&
    CouponCode.couponCodes.data.map((couponCode) => {
      return {
        code: couponCode.code,
        discountAmount: couponCode.discountAmount,
        couponType: couponCode.couponType,
        applicableToTeacher: !couponCode.applicableToTeacher ? (
          <Tag color="red">No</Tag>
        ) : (
          <Tag color="green">Yes</Tag>
        ),
        applicableToStudent: !couponCode.applicableToStudent ? (
          <Tag color="red">No</Tag>
        ) : (
          <Tag color="green">Yes</Tag>
        ),
        isDisabled: couponCode.isDisabled ? (
          <Tag color="red">Not Active</Tag>
        ) : (
          <Tag color="green">Active</Tag>
        ),
        startDate:
          couponCode.startDate &&
          moment(couponCode.startDate).format("YYYY-MM-DD HH:mm:ss"),
        endDate:
          couponCode.endDate &&
          moment(couponCode.endDate).format("YYYY-MM-DD HH:mm:ss"),

        actions: (
          <div className="action">
            {" "}
            <Tooltip title="Edit Coupon Code">
              <AiOutlineEdit
                className="icon"
                onClick={() => {
                  setVisible(true);
                  setCouponId(couponCode.couponCodeId);
                }}
              />
            </Tooltip>
            <Tooltip title="Delete Coupon Code">
              <Popconfirm
                title="Are you sure want to delete?"
                onConfirm={() => deleteClassHandler(couponCode.couponCodeId)}
              >
                {" "}
                <AiOutlineDelete className="icon" />
              </Popconfirm>
            </Tooltip>
            {couponCode.isDisabled ? (
              <Tooltip title="Enable Coupon Code">
                <MdDisabledVisible
                  className="icon"
                  onClick={() => {
                    disableAdHandler(couponCode.couponCodeId);
                  }}
                />
              </Tooltip>
            ) : (
              <Tooltip title="Disable Coupon code">
                <MdDisabledVisible
                  className="icon"
                  onClick={() => {
                    disableAdHandler(couponCode.couponCodeId);
                  }}
                />
              </Tooltip>
            )}
          </div>
        ),
      };
    });
  function onShowSizeChange(current, pageSize) {
    dispatch({
      type: "GET_COUPON_CODE_REQUEST",
      payload: {
        page: current,
        pageSize: pageSize,
      },
    });
  }
  const onSearch = (e) => {
    dispatch({
      type: "SEARCH_COUPON_CODE_REQUEST",
      payload: {
        pageNo: 1,
        pageSize: 10,
        keyword: e,
      },
    });
  };
  function onChange(value, dateString) {
    console.log("Selected Time: ", value);
    console.log("Formatted Selected Time: ", dateString);
  }

  function onOk(value) {
    console.log("onOk: ", value);
  }
  return (
    <>
      <div className="container">
        <Modal
          style={{ marginTop: "-3rem" }}
          title="New Coupon Code"
          visible={isModalVisible}
          onCancel={handleCancel}
          footer={null}
        >
          <Spin spinning={CouponCode.isLoading}>
            <Form
              name="form"
              encType="multipart/form-data"
              form={form}
              className="login-form"
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
            >
              <div style={{ display: "flex", gap: "1rem" }}>
                {" "}
                <Form.Item
                  label="Coupon Code"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    width: "100%",
                  }}
                  name="code"
                  rules={[
                    {
                      required: true,
                      message: "Please input code!",
                    },
                  ]}
                >
                  <Input placeholder="Coupon Code" />
                </Form.Item>
                <Form.Item
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    width: "100%",
                  }}
                  label="Discount Amount"
                  name="discountAmount"
                  rules={[
                    {
                      required: true,
                      message: "Please input discount amount!",
                    },
                  ]}
                >
                  <Input
                    placeholder="Discount Amount"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </div>
              <div style={{ display: "flex", gap: "1rem" }}>
                <Form.Item
                  label="Coupon Type"
                  name="couponType"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    width: "100%",
                  }}
                  rules={[
                    {
                      required: true,
                      message: "Please input coupon type!",
                    },
                  ]}
                >
                  <Select placeholder="Select Counpon Type">
                    <Option value="FLAT">Flat</Option>
                    <Option value="PERCENTAGE">Percentage</Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Applicable to Teacher"
                  name="applicableToTeacher"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    width: "100%",
                  }}
                  rules={[
                    {
                      required: true,
                      message: "Please input applicable to teacher!",
                    },
                  ]}
                >
                  <Select placeholder="Select Applicable">
                    <Option value="true">Yes</Option>
                    <Option value="false">No</Option>
                  </Select>
                </Form.Item>
              </div>
              <Form.Item
                label="Date Range:"
                name="dateRange"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  width: "100%",
                }}
                rules={[
                  {
                    required: true,
                    message: "Please input dateRange!",
                  },
                ]}
              >
                <RangePicker
                  showTime={{ format: "HH:mm" }}
                  format="YYYY-MM-DD HH:mm:ss"
                  onChange={onChange}
                  onOk={onOk}
                />
              </Form.Item>

              {/* <div style={{ display: "flex" }}>
                <Form.Item
                  name="startDate"
                  label="Start Date"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    width: "100%",
                  }}
                  rules={[
                    {
                      required: true,
                      message: "Please input start date!",
                    },
                  ]}
                >
                  <DatePicker format="YYYY/MM/DD" />
                </Form.Item>
                <Form.Item
                  label="End Date"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    width: "100%",
                  }}
                  name="endDate"
                  rules={[
                    {
                      required: true,
                      message: "Please input end date!",
                    },
                  ]}
                >
                  <DatePicker format="YYYY/MM/DD" />
                </Form.Item>
              </div> */}

              <div style={{ display: "flex", gap: "1rem" }}>
                {" "}
                <Form.Item
                  label="Applicable to Student"
                  name="applicableToStudent"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    width: "100%",
                  }}
                  rules={[
                    {
                      required: true,
                      message: "Please input applicable to student!",
                    },
                  ]}
                >
                  <Select placeholder="Select Applicable">
                    <Option value="true">Yes</Option>
                    <Option value="false">No</Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Is Visible to User"
                  name="isVisibleToUser"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    width: "100%",
                  }}
                  rules={[
                    {
                      required: true,
                      message: "Please input is visible to user!",
                    },
                  ]}
                >
                  <Select placeholder="Select is visible">
                    <Option value="true">Yes</Option>
                    <Option value="false">No</Option>
                  </Select>
                </Form.Item>
              </div>
              <Form.Item
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  width: "100%",
                }}
                label="Send Notification"
                name="sendNotification"
                rules={[
                  {
                    required: true,
                    message: "Please input send notification!",
                  },
                ]}
              >
                <Select placeholder="Send Notification">
                  <Option value="true">Yes</Option>
                  <Option value="false">No</Option>
                </Select>
              </Form.Item>

              <div style={{ display: "flex", gap: "1rem" }}>
                {" "}
                <Form.Item
                  label="Is Disable"
                  name="isDisabled"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    width: "100%",
                  }}
                  rules={[
                    {
                      required: true,
                      message: "Please input is disabled!",
                    },
                  ]}
                >
                  <Select placeholder="Select is disabled">
                    <Option value="true">Yes</Option>
                    <Option value="false">No</Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    width: "100%",
                  }}
                  label="Description"
                  name="description"
                  rules={[
                    {
                      required: true,
                      message: "Please input description!",
                    },
                  ]}
                >
                  <Input placeholder="Description" style={{ width: "100%" }} />
                </Form.Item>
              </div>

              <Button
                type="primary"
                htmlType="submit"
                style={{ margin: "auto", width: "100%" }}
                className="login-form-button"
              >
                Add
              </Button>
            </Form>
          </Spin>
        </Modal>

        <SiderDemo>
          <div style={{ display: "flex", flexDirection: "column" }}>
            {" "}
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: "2rem",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {" "}
                <h2 className="title-course">Coupon Codes </h2>
                <Button
                  type="primary"
                  style={{ background: "#FF4D4F" }}
                  danger
                  onClick={showModal}
                >
                  Add
                </Button>
              </div>
              <Search
                className="s"
                style={{ width: "35%", borderRadius: "10px" }}
                placeholder="Search for coupon code."
                allowClear
                size="large"
                // onSearch={onSearch}
                onChange={(e) => onSearch(e.target.value)}
              />
            </div>
            {/* <nav class="menu"></nav> */}
            {CouponCode.isLoading ? (
              <TableSkeleton />
            ) : (
              <Table
                columns={columns}
                dataSource={data}
                bordered
                pagination={false}
                className="table-course"
              />
            )}
            {CouponCode.couponCodes.totalPage && (
              <Pagination
                style={{
                  marginTop: "1rem",
                  marginBottom: "1rem",
                  alignSelf: "flex-end",
                }}
                showSizeChanger
                onShowSizeChange={onShowSizeChange}
                defaultCurrent={1}
                onChange={onShowSizeChange}
                total={CouponCode.couponCodes.totalPage * 10}
              />
            )}
            <Drawer
              title={<span style={{}}>Edit Coupon Code</span>}
              placement="right"
              width={"85%"}
              size={size}
              onClose={onClose}
              visible={visible}
            >
              <EditCouponCode
                id={couponId}
                CouponCode={CouponCode}
                onClose={onClose}
              />
            </Drawer>
          </div>
        </SiderDemo>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    CouponCode: state.CouponCode,
  };
};

export default connect(mapStateToProps, {})(CouponCode);
