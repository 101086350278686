import React, { useEffect, useState } from "react";
import "../Advertisement/Advertisement.css";
import Breadcrump from "../../Components/Breadcrump/Breadcrump";
import "../Classes Management/ClassManagement.css";
import {
  Table,
  Popconfirm,
  Form,
  Input,
  Button,
  Spin,
  Card,
  Tag,
  DatePicker,
  Select,
} from "antd";
import Sidebar from "../../Components/Sidebar/Sidebar";
import { connect, useDispatch } from "react-redux";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Navbar from "../../Components/Navbar/Navbar";
import "./MyProfile.css";
import SiderDemo from "../Layout/SiderDemo";

const { TextArea } = Input;
const { Option } = Select;

const RegisterAdmin = ({ profile }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const onFinish = (values) => {
    console.log({
      ...values,
      dateOfBirth: values.dateOfBirth.format("YYYY-MM-DD"),
    });
    dispatch({
      type: "REGISTER_ADMIN_REQUEST",
      payload: {
        ...values,
        dateOfBirth: values.dateOfBirth.format("YYYY-MM-DD"),
        deviceIP: "test",
        deviceMac: "test",
      },
    });
    form.resetFields();
  };

  useEffect(() => {
    dispatch({
      type: "GET_PROFILE_REQUEST",
    });
    dispatch({
      type: "GET_ROLES_REQUEST",
    });
  }, []);
  console.log(profile.roles);

  return (
    <>
      <div className="container">
        <SiderDemo>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Card style={{ width: 600 }} loading={profile.isLoading}>
              <div
                style={{
                  background: "#5D3BED",
                  height: "50px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  paddingLeft: "20px",
                  color: "white",
                  fontWeight: "normal",
                }}
              >
                Register New Admin
              </div>
              <Form
                name="form"
                encType="multipart/form-data"
                form={form}
                name="normal_login"
                className="login-form"
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
              >
                <div style={{ display: "flex", gap: "1rem" }}>
                  <Form.Item
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      width: "100%",
                    }}
                    label="Full Name"
                    name="fullName"
                    rules={[
                      {
                        required: true,
                        message: "Please input new full name!",
                      },
                    ]}
                  >
                    <Input placeholder="Full Name" />
                  </Form.Item>{" "}
                  <Form.Item
                    label="Email"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      width: "100%",
                    }}
                    name="emailId"
                    rules={[
                      {
                        required: true,
                        message: "Please input email!",
                      },
                    ]}
                  >
                    <Input placeholder="Email" />
                  </Form.Item>
                </div>
                <div style={{ display: "flex", gap: "1rem" }}>
                  <Form.Item
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      width: "100%",
                    }}
                    label="Password"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please input password!",
                      },
                    ]}
                  >
                    <Input placeholder="password" type="password" />
                  </Form.Item>
                  <Form.Item
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      width: "100%",
                    }}
                    label="Mobile Number"
                    name="mobileNumber"
                    rules={[
                      {
                        required: true,
                        message: "Please input mobile number!",
                      },
                    ]}
                  >
                    <Input placeholder="Mobile Number" type="number" />
                  </Form.Item>
                </div>
                <div style={{ display: "flex", gap: "1rem" }}>
                  <Form.Item
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      width: "100%",
                    }}
                    label="Date of Birth"
                    name="dateOfBirth"
                    rules={[
                      {
                        required: true,
                        message: "Please input Date of Birth!",
                      },
                    ]}
                  >
                    <DatePicker style={{ width: "100%" }} format="YYYY/MM/DD" />
                  </Form.Item>
                  <Form.Item
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      width: "100%",
                    }}
                    label="Gender"
                    name="gender"
                    rules={[
                      {
                        required: true,
                        message: "Please input gender!",
                      },
                    ]}
                  >
                    <Select placeholder="Gender">
                      <Option value="MALE">Male</Option>
                      <Option value="FEMALE">Female</Option>
                    </Select>
                  </Form.Item>
                </div>

                <Form.Item
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    width: "100%",
                  }}
                  label="Role"
                  name="roleId"
                  rules={[
                    {
                      required: true,
                      message: "Please input role!",
                    },
                  ]}
                >
                  <Select placeholder="Role">
                    {profile.roles &&
                      profile.roles.map((role) => {
                        return <Option value={role.id}>{role.name}</Option>;
                      })}
                  </Select>
                </Form.Item>

                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ margin: "auto", width: "100%" }}
                  className="login-form-button"
                >
                  Register
                </Button>
              </Form>
            </Card>
          </div>
        </SiderDemo>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    profile: state.profile,
  };
};

export default connect(mapStateToProps, {})(RegisterAdmin);
