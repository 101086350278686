import { filter, tap, mapTo, mergeMap, catchError } from "rxjs/operators";
import { ofType } from "redux-observable";
import { merge, of, from } from "rxjs";
import { API } from "../Helpers/BaseUrlProvider";

export const getAdminRoleEpic = (action$) =>
  action$.pipe(
    ofType("GET_ROLE_REQUEST"),
    mergeMap((action) =>
      from(API.get("/admin/role/drop-down")).pipe(
        mergeMap((response) =>
          of({
            type: "GET_ROLE_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "GET_ROLE_FAILURE",
            payload: error.responses,
          })
        )
      )
    )
  );

export const authenticateUserEpic = (action$) =>
  action$.pipe(
    ofType("AUTHENTICATE_USER_REQUEST"),
    mergeMap((action) =>
      from(API.post("/admin/login", action.payload)).pipe(
        mergeMap((response) =>
          of({
            type: "AUTHENTICATE_USER_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "AUTHENTICATE_USER_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
// export const registerUserEpic = (action$) =>
//   action$.pipe(
//     ofType("REGISTER_USER_REQUEST"),
//     mergeMap((action) =>
//       from(API.post("/v2/signup", action.payload)).pipe(
//         mergeMap((response) =>
//           of({
//             type: "OTP_SEND_SUCCESSFULL",
//             payload: {
//               data: response.data,
//               email: action.payload.email,
//             },
//           })
//         ),
//         catchError((error) =>
//           of({
//             type: "REGISTER_USER_FAILURE",
//             payload: error.response,
//           })
//         )
//       )
//     )
//   );

export const getProfileEpic = (action$) =>
  action$.pipe(
    ofType("GET_PROFILE_REQUEST"),
    mergeMap((action) =>
      from(API.get("admin/get-my-admin-profile")).pipe(
        mergeMap((response) =>
          of({
            type: "GET_PROFILE_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "GET_PROFILE_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const getClassesEpic = (action$) =>
  action$.pipe(
    ofType("GET_CLASSES_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(
          `subject-class/admin/list?page=${action.payload.page}&pageSize=${action.payload.pageSize}`
        )
      ).pipe(
        mergeMap((response) =>
          of({
            type: "GET_CLASSES_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "GET_CLASSES_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const searchClassesEpic = (action$) =>
  action$.pipe(
    ofType("SEARCH_CLASSES_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(
          `subject-class/admin/search-class?keyword=${action.payload.keyword}`
        )
      ).pipe(
        mergeMap((response) =>
          of({
            type: "GET_CLASSES_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "GET_CLASSES_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const searchClientEpic = (action$) =>
  action$.pipe(
    ofType("SEARCH_CLIENT_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(
          `admin/search-client?keyword=${action.payload.keyword}&userType=${action.payload.type}`
        )
      ).pipe(
        mergeMap((response) =>
          of({
            type: "GET_CLIENT_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "GET_CLIENT_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const getSubjectEpic = (action$) =>
  action$.pipe(
    ofType("GET_SUBJECT_CATEGORY_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(`subject-category/list?subjectClassId=${action.payload.id}`)
      ).pipe(
        mergeMap((response) =>
          of({
            type: "GET_SUBJECT_CATEGORY_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "GET_SUBJECT_CATEGORY_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const getClassEpic = (action$) =>
  action$.pipe(
    ofType("GET_CLASS_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(`subject-class/admin?subjectClassId=${action.payload.id}`)
      ).pipe(
        mergeMap((response) =>
          of({
            type: "GET_CLASS_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "GET_CLASS_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );

export const deleteClassEpic = (action$) =>
  action$.pipe(
    ofType("DELETE_CLASS_REQUEST"),
    mergeMap((action) =>
      from(
        API.delete(`subject-class/admin?subjectClassId=${action.payload.id}`)
      ).pipe(
        mergeMap((response) =>
          of({
            type: "DELETE_CLASS_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "DELETE_CLASS_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );

export const updateSubjectEpic = (action$) =>
  action$.pipe(
    ofType("UPDATE_SUBJECT_REQUEST"),
    mergeMap((action) =>
      from(API.put(`admin/subject-category`, action.payload)).pipe(
        mergeMap((response) =>
          of({
            type: "UPDATE_SUBJECT_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "UPDATE_SUBJECT_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );

export const updateSubSubjectEpic = (action$) =>
  action$.pipe(
    ofType("UPDATE_SUB_SUBJECT_REQUEST"),
    mergeMap((action) =>
      from(
        API.put(`subject-sub-category/subject-sub-category`, action.payload)
      ).pipe(
        mergeMap((response) =>
          of({
            type: "UPDATE_SUB_SUBJECT_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "UPDATE_SUB_SUBJECT_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );

export const deleteSubjectEpic = (action$) =>
  action$.pipe(
    ofType("DELETE_SUBJECT_REQUEST"),
    mergeMap((action) =>
      from(
        API.delete(`subject-category?subjectCategoryId=${action.payload.id}`)
      ).pipe(
        mergeMap((response) =>
          of({
            type: "DELETE_SUBJECT_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "DELETE_SUBJECT_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const deleteSubSubjectEpic = (action$) =>
  action$.pipe(
    ofType("DELETE_SUB_SUBJECT_REQUEST"),
    mergeMap((action) =>
      from(
        API.delete(
          `subject-sub-category/subject-sub-category?subjectSubCategoryId=${action.payload.id}`
        )
      ).pipe(
        mergeMap((response) =>
          of({
            type: "DELETE_SUB_SUBJECT_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "DELETE_SUB_SUBJECT_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const addClassEpic = (action$) =>
  action$.pipe(
    ofType("ADD_CLASS_REQUEST"),
    mergeMap((action) =>
      from(API.post(`subject-class/admin`, action.payload)).pipe(
        mergeMap((response) =>
          of({
            type: "ADD_CLASS_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "ADD_CLASS_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const addSubjectEpic = (action$) =>
  action$.pipe(
    ofType("ADD_SUBJECT_REQUEST"),
    mergeMap((action) =>
      from(API.post(`admin/subject-category`, action.payload)).pipe(
        mergeMap((response) =>
          of({
            type: "ADD_SUBJECT_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "ADD_SUBJECT_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const addSubSubjectEpic = (action$) =>
  action$.pipe(
    ofType("ADD_SUB_SUBJECT_REQUEST"),
    mergeMap((action) =>
      from(
        API.post(`subject-sub-category/subject-sub-category`, action.payload)
      ).pipe(
        mergeMap((response) =>
          of({
            type: "ADD_SUB_SUBJECT_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "ADD_SUB_SUBJECT_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const updateClassEpic = (action$) =>
  action$.pipe(
    ofType("UPDATE_CLASS_REQUEST"),
    mergeMap((action) =>
      from(API.put(`subject-class/admin`, action.payload)).pipe(
        mergeMap((response) =>
          of({
            type: "UPDATE_CLASS_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "UPDATE_CLASS_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const enableDisableClassEpic = (action$) =>
  action$.pipe(
    ofType("ENABLE_DISABLE_CLASS_REQUEST"),
    mergeMap((action) =>
      from(
        API.patch(`subject-class/admin?subjectClassId=${action.payload.id}`)
      ).pipe(
        mergeMap((response) =>
          of({
            type: "ENABLE_DISABLE_CLASS_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "ENABLE_DISABLE_CLASS_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const getAdvertisementEpic = (action$) =>
  action$.pipe(
    ofType("GET_ADVERTISEMENT_REQUEST"),
    mergeMap((action) =>
      from(API.get(`promotion`)).pipe(
        mergeMap((response) =>
          of({
            type: "GET_ADVERTISEMENT_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "GET_ADVERTISEMENT_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const searchAdvertisementEpic = (action$) =>
  action$.pipe(
    ofType("SEARCH_ADVERTISEMENT_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(
          `promotion/search-advertisement?keyword=${action.payload.keyword}`
        )
      ).pipe(
        mergeMap((response) =>
          of({
            type: "GET_ADVERTISEMENT_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "GET_ADVERTISEMENT_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const getSingleAdEpic = (action$) =>
  action$.pipe(
    ofType("GET_SINGLE_AD_REQUEST"),
    mergeMap((action) =>
      from(API.get(`promotion/${action.payload.id}`)).pipe(
        mergeMap((response) =>
          of({
            type: "GET_SINGLE_AD_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "GET_SINGLE_AD_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const addAdvertisementEpic = (action$) =>
  action$.pipe(
    ofType("ADD_ADVERTISEMENT_REQUEST"),
    mergeMap((action) =>
      from(API.post(`promotion`, action.payload)).pipe(
        mergeMap((response) =>
          of({
            type: "ADD_ADVERTISEMENT_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "ADD_ADVERTISEMENT_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const updateAdvertisementEpic = (action$) =>
  action$.pipe(
    ofType("UPDATE_ADVERTISEMENT_REQUEST"),
    mergeMap((action) =>
      from(API.put(`promotion`, action.payload)).pipe(
        mergeMap((response) =>
          of({
            type: "UPDATE_ADVERTISEMENT_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "UPDATE_ADVERTISEMENT_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const disableAdvertisementEpic = (action$) =>
  action$.pipe(
    ofType("DISABLE_AD_REQUEST"),
    mergeMap((action) =>
      from(API.patch(`promotion/${action.payload.id}`)).pipe(
        mergeMap((response) =>
          of({
            type: "DISABLE_AD_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "DISABLE_AD_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const getLoyaltyEpic = (action$) =>
  action$.pipe(
    ofType("GET_LOYALTY_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(
          `loyalty-points/get-all?page=${action.payload.page}&pageSize=${action.payload.pageSize}`
        )
      ).pipe(
        mergeMap((response) =>
          of({
            type: "GET_LOYALTY_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "GET_LOYALTY_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const addLoyaltyEpic = (action$) =>
  action$.pipe(
    ofType("ADD_LOYALTY_REQUEST"),
    mergeMap((action) =>
      from(API.post(`loyalty-points`, action.payload)).pipe(
        mergeMap((response) =>
          of({
            type: "ADD_LOYALTY_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "ADD_LOYALTY_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const getCommissionEpic = (action$) =>
  action$.pipe(
    ofType("GET_COMMISSION_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(
          `commission-gst?page=${action.payload.page}&pageSize=${action.payload.pageSize}`
        )
      ).pipe(
        mergeMap((response) =>
          of({
            type: "GET_COMMISSION_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "GET_COMMISSION_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const addCommissionEpic = (action$) =>
  action$.pipe(
    ofType("ADD_COMMISSION_REQUEST"),
    mergeMap((action) =>
      from(API.post(`/commission-gst`, action.payload)).pipe(
        mergeMap((response) =>
          of({
            type: "ADD_COMMISSION_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "ADD_COMMISSION_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const getCouponCodeEpic = (action$) =>
  action$.pipe(
    ofType("GET_COUPON_CODE_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(
          `coupon-code/list?page=${action.payload.page}&pageSize=${action.payload.pageSize}`
        )
      ).pipe(
        mergeMap((response) =>
          of({
            type: "GET_COUPON_CODE_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "GET_COUPON_CODE_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );

export const searchCouponCodeEpic = (action$) =>
  action$.pipe(
    ofType("SEARCH_COUPON_CODE_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(
          `coupon-code/search-coupon-code?keyword=${action.payload.keyword}`
        )
      ).pipe(
        mergeMap((response) =>
          of({
            type: "GET_COUPON_CODE_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "GET_COUPON_CODE_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );

export const addCouponEpic = (action$) =>
  action$.pipe(
    ofType("ADD_COUPON_REQUEST"),
    mergeMap((action) =>
      from(API.post(`coupon-code`, action.payload)).pipe(
        mergeMap((response) =>
          of({
            type: "ADD_COUPON_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "ADD_COUPON_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );

export const disableCouponEpic = (action$) =>
  action$.pipe(
    ofType("DISABLE_COUPON_REQUEST"),
    mergeMap((action) =>
      from(API.patch(`coupon-code/${action.payload.id}`)).pipe(
        mergeMap((response) =>
          of({
            type: "DISABLE_COUPON_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "DISABLE_COUPON_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const deleteCouponEpic = (action$) =>
  action$.pipe(
    ofType("DELETE_COUPON_REQUEST"),
    mergeMap((action) =>
      from(API.delete(`coupon-code/${action.payload.id}`)).pipe(
        mergeMap((response) =>
          of({
            type: "DELETE_COUPON_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "DELETE_COUPON_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const getSingleCouponEpic = (action$) =>
  action$.pipe(
    ofType("GET_SINGLE_COUPON_REQUEST"),
    mergeMap((action) =>
      from(API.get(`coupon-code/${action.payload.id}`)).pipe(
        mergeMap((response) =>
          of({
            type: "GET_SINGLE_COUPON_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "GET_SINGLE_COUPON_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const updateCouponEpic = (action$) =>
  action$.pipe(
    ofType("UPDATE_COUPON_REQUEST"),
    mergeMap((action) =>
      from(API.put(`coupon-code`, action.payload)).pipe(
        mergeMap((response) =>
          of({
            type: "UPDATE_COUPON_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "UPDATE_COUPON_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const getClientEpic = (action$) =>
  action$.pipe(
    ofType("GET_CLIENT_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(
          `admin/customer/get-client-list?page=${action.payload.pageNo}&pageSize=${action.payload.pageSize}&userType=${action.payload.type}`
        )
      ).pipe(
        mergeMap((response) =>
          of({
            type: "GET_CLIENT_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "GET_CLIENT_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );

export const disableClientEpic = (action$) =>
  action$.pipe(
    ofType("DISABLE_CLIENT_REQUEST"),
    mergeMap((action) =>
      from(API.patch(`customer/status?clientId=${action.payload.id}`)).pipe(
        mergeMap((response) =>
          of({
            type: "DISABLE_CLIENT_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "DISABLE_CLIENT_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const addNotificationEpic = (action$) =>
  action$.pipe(
    ofType("ADD_MASS_NOTIFICATION_REQUEST"),
    mergeMap((action) =>
      from(API.post(`notification/mass-notification`, action.payload)).pipe(
        mergeMap((response) =>
          of({
            type: "ADD_MASS_NOTIFICATION_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "ADD_MASS_NOTIFICATION_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );

export const getTermsEpic = (action$) =>
  action$.pipe(
    ofType("GET_TERMS_REQUEST"),
    mergeMap((action) =>
      from(API.get(`privacy-terms`)).pipe(
        mergeMap((response) =>
          of({
            type: "GET_TERMS_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "GET_TERMS_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const addPrivacyTermsEpic = (action$) =>
  action$.pipe(
    ofType("ADD_PRIVACY_TERMS_REQUEST"),
    mergeMap((action) =>
      from(API.post(`privacy-terms/admin/add`, action.payload)).pipe(
        mergeMap((response) =>
          of({
            type: "ADD_PRIVACY_TERMS_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "ADD_PRIVACY_TERMS_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const changePasswordEpic = (action$) =>
  action$.pipe(
    ofType("CHANGE_PASSWORD_REQUEST"),
    mergeMap((action) =>
      from(API.put(`admin/change-password`, action.payload)).pipe(
        mergeMap((response) =>
          of({
            type: "CHANGE_PASSWORD_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "CHANGE_PASSWORD_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const getRolesEpic = (action$) =>
  action$.pipe(
    ofType("GET_ROLES_REQUEST"),
    mergeMap((action) =>
      from(API.get(`admin/role/drop-down`)).pipe(
        mergeMap((response) =>
          of({
            type: "GET_ROLES_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "GET_ROLES_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const registerAdminEpic = (action$) =>
  action$.pipe(
    ofType("REGISTER_ADMIN_REQUEST"),
    mergeMap((action) =>
      from(API.post(`admin/register`, action.payload)).pipe(
        mergeMap((response) =>
          of({
            type: "REGISTER_ADMIN_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "REGISTER_ADMIN_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const getAdminEpic = (action$) =>
  action$.pipe(
    ofType("GET_ADMIN_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(
          `admin/list-admin?page=${action.payload.page}&pageSize=${action.payload.pageSize}`
        )
      ).pipe(
        mergeMap((response) =>
          of({
            type: "GET_ADMIN_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "GET_ADMIN_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const activateAdminEpic = (action$) =>
  action$.pipe(
    ofType("ENABLE_ADMIN_REQUEST"),
    mergeMap((action) =>
      from(API.get(`admin/activate-admin/${action.payload.id}`)).pipe(
        mergeMap((response) =>
          of({
            type: "ENABLE_ADMIN_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "ENABLE_ADMIN_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const disableAdminEpic = (action$) =>
  action$.pipe(
    ofType("DISABLE_ADMIN_REQUEST"),
    mergeMap((action) =>
      from(API.get(`admin/deactivate-admin/${action.payload.id}`)).pipe(
        mergeMap((response) =>
          of({
            type: "DISABLE_ADMIN_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "DISABLE_ADMIN_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const updateAdminEpic = (action$) =>
  action$.pipe(
    ofType("UPDATE_PROFILE_REQUEST"),
    mergeMap((action) =>
      from(API.put(`admin/update-profile`, action.payload)).pipe(
        mergeMap((response) =>
          of({
            type: "UPDATE_PROFILE_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "UPDATE_PROFILE_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const getDashboardEpic = (action$) =>
  action$.pipe(
    ofType("GET_DASHBOARD_REQUEST"),
    mergeMap((action) =>
      from(API.get(`dashboard/admin`)).pipe(
        mergeMap((response) =>
          of({
            type: "GET_DASHBOARD_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "GET_DASHBOARD_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const getSupportEpic = (action$) =>
  action$.pipe(
    ofType("GET_SUPPORT_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(
          !action.payload.status && !action.payload.userType
            ? `admin/support/list`
            : `admin/support/list?${
                action.payload.status &&
                `supportStatus=${action.payload.status}`
              }${
                action.payload.userType &&
                `&userType=${action.payload.userType}`
              } `
        )
      ).pipe(
        mergeMap((response) =>
          of({
            type: "GET_SUPPORT_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "GET_SUPPORT_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const markSupportEpic = (action$) =>
  action$.pipe(
    ofType("MARK_SUPPORT_REQUEST"),
    mergeMap((action) =>
      from(API.patch(`admin/support?supportId=${action.payload.id}`)).pipe(
        mergeMap((response) =>
          of({
            type: "MARK_SUPPORT_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "MARK_SUPPORT_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const getTutoringEpic = (action$) =>
  action$.pipe(
    ofType("GET_TUTORING_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(
          `tutoring/admin/list?status=${action.payload.status}&page=${action.payload.page}&pageSize=${action.payload.pageSize}`
        )
      ).pipe(
        mergeMap((response) =>
          of({
            type: "GET_TUTORING_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "GET_TUTORING_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const getTutoringDetailEpic = (action$) =>
  action$.pipe(
    ofType("GET_TUTORING_DETAIL_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(`tutoring/admin/detail?tutoringId=${action.payload.id}`)
      ).pipe(
        mergeMap((response) =>
          of({
            type: "GET_TUTORING_DETAIL_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "GET_TUTORING_DETAIL_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );

export const getRoleEpic = (action$) =>
  action$.pipe(
    ofType("GET_ROLE_REQUEST"),
    mergeMap((action) =>
      from(API.get(`admin/role/${action.payload.id}`)).pipe(
        mergeMap((response) =>
          of({
            type: "GET_ROLE_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "GET_ROLE_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const addRoleEpic = (action$) =>
  action$.pipe(
    ofType("ADD_ROLE_REQUEST"),
    mergeMap((action) =>
      from(API.post(`admin/role/add`, action.payload)).pipe(
        mergeMap((response) =>
          of({
            type: "ADD_ROLE_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "ADD_ROLE_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const getRolesListEpic = (action$) =>
  action$.pipe(
    ofType("GET_ROLE_LIST_REQUEST"),
    mergeMap((action) =>
      from(API.get(`admin/role/list`)).pipe(
        mergeMap((response) =>
          of({
            type: "GET_ROLE_LIST_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "GET_ROLE_LIST_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const getPermissionEpic = (action$) =>
  action$.pipe(
    ofType("GET_PERMISSION_LIST_REQUEST"),
    mergeMap((action) =>
      from(API.get(`admin/permission/list`)).pipe(
        mergeMap((response) =>
          of({
            type: "GET_PERMISSION_LIST_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "GET_PERMISSION_LIST_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const updatePermissionEpic = (action$) =>
  action$.pipe(
    ofType("UPDATE_PERMISSION_REQUEST"),
    mergeMap((action) =>
      from(API.post(`admin/roles-and-permissions/edit`, action.payload)).pipe(
        mergeMap((response) =>
          of({
            type: "UPDATE_PERMISSION_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "UPDATE_PERMISSION_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const addSubjectSetEpic = (action$) =>
  action$.pipe(
    ofType("ADD_SUBJECT_SET_REQUEST"),
    mergeMap((action) =>
      from(
        API.post(
          `admin/subject/exam-time?subjectCategoryId=${action.payload[0].id}`,
          action.payload
        )
      ).pipe(
        mergeMap((response) =>
          of({
            type: "ADD_SUBJECT_SET_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "ADD_SUBJECT_SET_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const addSubSubjectSetEpic = (action$) =>
  action$.pipe(
    ofType("ADD_SUB_SUBJECT_SET_REQUEST"),
    mergeMap((action) =>
      from(
        API.post(
          `admin/sub-subject/exam-time?subjectSubCategoryId=${action.payload[0].id}`,
          action.payload
        )
      ).pipe(
        mergeMap((response) =>
          of({
            type: "ADD_SUB_SUBJECT_SET_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "ADD_SUB_SUBJECT_SET_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const getSubjectSetsEpic = (action$) =>
  action$.pipe(
    ofType("GET_SUBJECT_SETS_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(
          `admin/subject/exam-time?subjectCategoryId=${action.payload.id}`
        )
      ).pipe(
        mergeMap((response) =>
          of({
            type: "GET_SUBJECT_SETS_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "GET_SUBJECT_SET_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const getSubSubjectSetsEpic = (action$) =>
  action$.pipe(
    ofType("GET_SUB_SUBJECT_SETS_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(
          `admin/sub-subject/exam-time?subjectSubCategoryId=${action.payload.id}`
        )
      ).pipe(
        mergeMap((response) =>
          of({
            type: "GET_SUB_SUBJECT_SETS_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "GET_SUB_SUBJECT_SETS_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const addQuestionEpic = (action$) =>
  action$.pipe(
    ofType("ADD_QUESTION_REQUEST"),
    mergeMap((action) =>
      from(API.post(`admin/exam/question`, action.payload)).pipe(
        mergeMap((response) =>
          of({
            type: "ADD_QUESTION_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "ADD_QUESTION_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const getSubjectQuestionEpic = (action$) =>
  action$.pipe(
    ofType("GET_SUBJECT_QUESTION_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(
          `admin/subject/exam-question/list?subjectCategoryId=${action.payload.id}`
        )
      ).pipe(
        mergeMap((response) =>
          of({
            type: "GET_SUBJECT_QUESTION_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "GET_SUBJECT_QUESTION_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const deleteQuestionEpic = (action$) =>
  action$.pipe(
    ofType("DELETE_QUESTION_REQUEST"),
    mergeMap((action) =>
      from(
        API.delete(`admin/exam/question?examQuestionId=${action.payload.id}`)
      ).pipe(
        mergeMap((response) =>
          of({
            type: "DELETE_QUESTION_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "DELETE_QUESTION_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const getSubSubjectQuestionEpic = (action$) =>
  action$.pipe(
    ofType("GET_SUB_SUBJECT_QUESTION_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(
          `admin/sub-subject/exam-question/list?subjectSubCategoryId=${action.payload.id}`
        )
      ).pipe(
        mergeMap((response) =>
          of({
            type: "GET_SUBJECT_QUESTION_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "GET_SUBJECT_QUESTION_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const deleteQuestionSetEpic = (action$) =>
  action$.pipe(
    ofType("DELETE_QUESTION_SET_REQUEST"),
    mergeMap((action) =>
      from(API.delete(`admin/exam-time?examTimeId=${action.payload.id}`)).pipe(
        mergeMap((response) =>
          of({
            type: "DELETE_QUESTION_SET_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "DELETE_QUESTION_SET_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );

export const updateSubjectQuestionSetEpic = (action$) =>
  action$.pipe(
    ofType("UPDATE_SUBJECT_QUESTION_SET_REQUEST"),
    mergeMap((action) =>
      from(
        API.put(
          `admin/subject/exam-time?subjectCategoryId=${action.payload[0].id}`,
          action.payload
        )
      ).pipe(
        mergeMap((response) =>
          of({
            type: "UPDATE_SUBJECT_QUESTION_SET_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "UPDATE_SUBJECT_QUESTION_SET_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const updateSubSubjectQuestionSetEpic = (action$) =>
  action$.pipe(
    ofType("UPDATE_SUB_SUBJECT_QUESTION_SET_REQUEST"),
    mergeMap((action) =>
      from(
        API.put(
          `admin/sub-subject/exam-time?subjectSubCategoryId=${action.payload[0].id}`,
          action.payload
        )
      ).pipe(
        mergeMap((response) =>
          of({
            type: "UPDATE_SUB_SUBJECT_QUESTION_SET_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "UPDATE_SUB_SUBJECT_QUESTION_SET_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const getReportReasonEpic = (action$) =>
  action$.pipe(
    ofType("GET_REPORT_REASON_REQUEST"),
    mergeMap((action) =>
      from(API.get(`admin/report-reason`)).pipe(
        mergeMap((response) =>
          of({
            type: "GET_REPORT_REASON_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "GET_REPORT_REASON_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const getSubjectListEpic = (action$) =>
  action$.pipe(
    ofType("GET_SUBJECT_LIST_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(
          `admin/subject-category/resources-list?subjectClassId=${action.payload.id}`
        )
      ).pipe(
        mergeMap((response) =>
          of({
            type: "GET_SUBJECT_LIST_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "GET_SUBJECT_LIST_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const getDirectoryListEpic = (action$) =>
  action$.pipe(
    ofType("GET_DIRECTORY_LIST_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(
          `admin/resources/sub-directory/list?parentDirId=${action.payload.id}`
        )
      ).pipe(
        mergeMap((response) =>
          of({
            type: "GET_DIRECTORY_LIST_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "GET_DIRECTORY_LIST_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const uploadResourceFileEpic = (action$) =>
  action$.pipe(
    ofType("UPLOAD_RESOURCE_FILE_REQUEST"),
    mergeMap((action) =>
      from(
        API.post(
          `admin/resources/subject-directory/file?dirId=${action.payload.dirId}`,
          action.payload.formData
        )
      ).pipe(
        mergeMap((response) =>
          of({
            type: "UPLOAD_RESOURCE_FILE_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "UPLOAD_RESOURCE_FILE_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const addDirectoryEpic = (action$) =>
  action$.pipe(
    ofType("ADD_DIRECTORY_REQUEST"),
    mergeMap((action) =>
      from(API.post(`admin/resources/sub-directory`, action.payload)).pipe(
        mergeMap((response) =>
          of({
            type: "ADD_DIRECTORY_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "ADD_DIRECTORY_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const addReportReasonEpic = (action$) =>
  action$.pipe(
    ofType("ADD_REPORT_REASON_REQUEST"),
    mergeMap((action) =>
      from(API.post(`admin/report-reason`, action.payload)).pipe(
        mergeMap((response) =>
          of({
            type: "ADD_REPORT_REASON_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "ADD_REPORT_REASON_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const deleteReportReasonEpic = (action$) =>
  action$.pipe(
    ofType("DELETE_REPORT_REASON_REQUEST"),
    mergeMap((action) =>
      from(
        API.delete(`admin/report-reason?reportReasonId=${action.payload.id}`)
      ).pipe(
        mergeMap((response) =>
          of({
            type: "DELETE_REPORT_REASON_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "DELETE_REPORT_REASON_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const updateReportReasonEpic = (action$) =>
  action$.pipe(
    ofType("UPDATE_REPORT_REASON_REQUEST"),
    mergeMap((action) =>
      from(
        API.put(
          `admin/report-reason?reportReasonId=${action.payload.id}`,
          action.payload
        )
      ).pipe(
        mergeMap((response) =>
          of({
            type: "UPDATE_REPORT_REASON_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "UPDATE_REPORT_REASON_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const getReportsEpic = (action$) =>
  action$.pipe(
    ofType("GET_REPORT_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(
          `admin/report?page=${action.payload.page}&pageSize=${action.payload.pageSize}`
        )
      ).pipe(
        mergeMap((response) =>
          of({
            type: "GET_REPORT_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "GET_REPORT_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const getPaymentEpic = (action$) =>
  action$.pipe(
    ofType("GET_PAYMENT_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(
          `stripe/admin/payment-list?page=${action.payload.page}&pageSize=${action.payload.pageSize}&period=${action.payload.period}&startDate=${action.payload.startDate}&endDate=${action.payload.endDate}`
        )
      ).pipe(
        mergeMap((response) =>
          of({
            type: "GET_PAYMENT_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "GET_PAYMENT_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
