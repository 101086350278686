import React, { useEffect, useState } from "react";
import "./Navbar.css";
import { FiMenu } from "react-icons/fi";
import { useDispatch } from "react-redux";
import { Button, Spin, Avatar, Divider } from "antd";
import { connect } from "react-redux";
import {
  LogoutOutlined,
  EllipsisOutlined,
  UserOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import { IoIosArrowDown } from "react-icons/io";
import { Menu, Dropdown, Space } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { SmileOutlined } from "@ant-design/icons";
import { RiAdminFill, RiLockPasswordFill } from "react-icons/ri";
import { Link } from "react-router-dom";
import adminImage from "../../Assets/Images/g1.jpg";
import { GrUserAdmin } from "react-icons/gr";

const Navbar = ({ profile }) => {
  const [isNavToggled, setIsNavToogled] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: "GET_PROFILE_REQUEST",
    });
  }, []);

  const logoutHandler = () => {
    dispatch({
      type: "LOGOUT_ADMIN",
    });
  };
  const menu = (
    <div
      style={{
        background: "white",
        padding: "5px 15px",
        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
        marginTop: "1rem",
        color: "black",
      }}
    >
      <Link to={"/my-profile"}>
        {" "}
        <p style={{ cursor: "pointer" }}>
          <UserOutlined /> Profile
        </p>
      </Link>

      <Link to={"/change-password"}>
        {" "}
        <p style={{ cursor: "pointer" }}>
          <RiLockPasswordFill /> Change Password
        </p>
      </Link>
      <Link to={"/register-admin"}>
        {" "}
        <p style={{ cursor: "pointer" }}>
          <UserAddOutlined /> Register Admin
        </p>
      </Link>
      <Link to={"/admin-list"}>
        {" "}
        <p style={{ cursor: "pointer" }}>
          <RiAdminFill /> Admin List
        </p>
      </Link>
      <Link to={"/roles-and-permission"}>
        {" "}
        <p style={{ cursor: "pointer" }}>
          <GrUserAdmin /> Roles & Permissions
        </p>
      </Link>
      <p onClick={logoutHandler} style={{ cursor: "pointer" }}>
        <LogoutOutlined /> Log out
      </p>
    </div>
  );

  return (
    <div
      className={
        profile.isNavToggled ? "nav-container active" : "nav-container"
      }
    >
      <div></div>
      {/* <FiMenu
        className={isNavToggled && "toggled-nav"}
        onClick={() => {
          dispatch({
            type: "TOGGLE_NAV",
          });
        }}
      /> */}
      <div className="" style={{ display: "flex" }}>
        {profile.isLoading ? (
          <Spin spinning={profile.isLoading}> </Spin>
        ) : (
          <Dropdown overlay={menu}>
            <div
              className=""
              style={{
                display: "flex",
                gap: "1rem",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <img
                src={adminImage}
                alt=""
                style={{
                  width: "50px",
                  borderRadius: "50%",
                  border: "2px solid white",
                }}
              />
              {/* {profile.profile.fullName && profile.profile.fullName.charAt(0)} */}

              <div>
                <span>
                  {profile.profile.fullName && profile.profile.fullName}
                </span>
              </div>
              <IoIosArrowDown />
            </div>
          </Dropdown>
        )}

        {/* <Button
          type="primary"
          icon={<LogoutOutlined />}
          onClick={logoutHandler}
        >
          Log out
        </Button> */}
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    profile: state.profile,
  };
};

export default connect(mapStateToProps, {})(Navbar);
