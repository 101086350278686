import { openNotificationWithIcon } from "../../Components/Notification/Success";

const initialState = {
  couponCodes: [],
  isLoading: false,
  isCouponCodeChanged: false,
  coupon: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case "GET_COUPON_CODE_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_COUPON_CODE_SUCCESS":
      //   openNotificationWithIcon("success", payload.message);
      return {
        ...state,
        isLoading: false,
        couponCodes: payload,
      };
    case "GET_COUPON_CODE_FAILURE":
      openNotificationWithIcon("error", payload.data.message);
      return {
        ...state,
        isLoading: false,
      };

    case "ADD_COUPON_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "ADD_COUPON_SUCCESS":
      openNotificationWithIcon("success", payload.message);
      return {
        ...state,
        isLoading: false,
        isCouponCodeChanged: state.isCouponCodeChanged ? false : true,
      };
    case "ADD_COUPON_FAILURE":
      openNotificationWithIcon("error", payload.data.message);
      return {
        ...state,
        isLoading: false,
      };
    case "DISABLE_COUPON_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "DISABLE_COUPON_SUCCESS":
      openNotificationWithIcon("success", payload.message);
      return {
        ...state,
        isLoading: false,
        isCouponCodeChanged: state.isCouponCodeChanged ? false : true,
      };
    case "DISABLE_COUPON_FAILURE":
      openNotificationWithIcon("error", payload.data.message);
      return {
        ...state,
        isLoading: false,
      };
    case "DELETE_COUPON_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "DELETE_COUPON_SUCCESS":
      openNotificationWithIcon("success", payload.message);
      return {
        ...state,
        isLoading: false,
        isCouponCodeChanged: state.isCouponCodeChanged ? false : true,
      };
    case "DELETE_COUPON_FAILURE":
      openNotificationWithIcon("error", payload.data.message);
      return {
        ...state,
        isLoading: false,
        isCouponCodeChanged: state.isCouponCodeChanged ? false : true,
      };
    case "GET_SINGLE_COUPON_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_SINGLE_COUPON_SUCCESS":
      return {
        ...state,
        isLoading: false,
        coupon: payload,
        isCouponCodeChanged: state.isCouponCodeChanged ? false : true,
      };
    case "GET_SINGLE_COUPON_FAILURE":
      openNotificationWithIcon("error", payload.data.message);
      return {
        ...state,
        isLoading: false,
      };
    case "UPDATE_COUPON_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "UPDATE_COUPON_SUCCESS":
      openNotificationWithIcon("success", payload.message);
      return {
        ...state,
        isLoading: false,
        isCouponCodeChanged: state.isCouponCodeChanged ? false : true,
      };
    case "UPDATE_COUPON_FAILURE":
      openNotificationWithIcon("error", payload.data.message);
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
