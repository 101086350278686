import { useEffect } from "react";
import "../Classes Management/ClassManagement.css";
import { Table, Segmented } from "antd";
import { connect, useDispatch } from "react-redux";
import { Tag } from "antd";
import { Pagination } from "antd";
import { Tooltip } from "antd";
import { MdDisabledVisible } from "react-icons/md";
import TableSkeleton from "../../Components/Table Skeleton/TableSkeleton";
import SiderDemo from "../Layout/SiderDemo";

const AdminList = ({ profile }) => {
  const dispatch = useDispatch();

  const enableAdmin = (id) => {
    dispatch({
      type: "ENABLE_ADMIN_REQUEST",
      payload: {
        id: id,
      },
    });
  };
  const disableAdmin = (id) => {
    dispatch({
      type: "DISABLE_ADMIN_REQUEST",
      payload: {
        id: id,
      },
    });
  };

  useEffect(() => {
    dispatch({
      type: "GET_ADMIN_REQUEST",
      payload: {
        page: 1,
        pageSize: 10,
      },
    });
  }, [profile.isProfileChanged]);

  const columns = [
    {
      title: "Full Name",
      dataIndex: "fullName",

      width: 300,
      align: "center",
    },

    {
      title: "Email",
      dataIndex: "email",

      width: 250,
      align: "center",
    },
    {
      title: "Phone No",
      dataIndex: "phoneNo",

      width: 250,
      align: "center",
    },
    {
      title: "Date Of Birth",
      dataIndex: "dateOfBirth",

      width: 250,
      align: "center",
    },
    {
      title: "Admin Role",
      dataIndex: "adminRole",

      width: 250,
      align: "center",
    },
    {
      title: "Active",
      dataIndex: "disabled",

      width: 150,
      align: "center",
    },

    {
      title: "Actions",
      dataIndex: "actions",

      align: "center",
      width: 350,
    },
  ];

  const data =
    profile.admins.data &&
    profile.admins.data.map((admin) => {
      return {
        fullName: admin.fullName,
        email: admin.email,
        phoneNo: admin.phoneNo,
        disabled: !admin.isActive ? (
          <Tag color="red">Not Active</Tag>
        ) : (
          <Tag color="green">Active</Tag>
        ),
        adminRole: admin.adminRole && admin.adminRole.name,
        dateOfBirth: admin.dateOfBirth,

        actions: (
          <div className="action">
            {" "}
            {!admin.isActive ? (
              <Tooltip title="Enable Admin">
                <MdDisabledVisible
                  className="icon"
                  onClick={() => {
                    enableAdmin(admin.id);
                  }}
                />
              </Tooltip>
            ) : (
              <Tooltip title="Disable Admin">
                <MdDisabledVisible
                  className="icon"
                  onClick={() => {
                    disableAdmin(admin.id);
                  }}
                />
              </Tooltip>
            )}
          </div>
        ),
      };
    });
  function onShowSizeChange(current, pageSize) {
    window.scrollTo(0, 0);
    dispatch({
      type: "GET_ADMIN_REQUEST",
      payload: {
        page: current,
        pageSize: pageSize,
      },
    });
  }

  return (
    <>
      <div className="container">
        <SiderDemo>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: "2rem",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {" "}
                <h2 className="title-course">Admin List</h2>
                <Segmented style={{ height: "30px", fontSize: "11px" }} />
              </div>
            </div>

            {profile.isLoading ? (
              <TableSkeleton />
            ) : (
              <Table
                pagination={false}
                columns={columns}
                dataSource={data}
                bordered
                className="table-course"
              />
            )}
            {profile.admins.totalPage && (
              <Pagination
                style={{
                  marginTop: "1rem",
                  marginBottom: "1rem",
                  alignSelf: "flex-end",
                }}
                showSizeChanger
                onShowSizeChange={onShowSizeChange}
                defaultCurrent={1}
                onChange={onShowSizeChange}
                total={profile.admins.totalPage * 10}
              />
            )}
          </div>
        </SiderDemo>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    profile: state.profile,
  };
};

export default connect(mapStateToProps, {})(AdminList);
