import { toast } from "react-toastify";
import history from "../../Helpers/history";
import { openNotificationWithIcon } from "../../Components/Notification/Success";

import {
  getCookie,
  removeCookie,
  setCookie,
  setLocalStorage,
} from "../../Helpers/FrontendHelper";

const initialState = {
  isLoggedIn: false,
  profile: {},
  loading: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case "AUTHENTICATE_USER_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "AUTHENTICATE_USER_SUCCESS":
      setCookie("token", payload.accessToken);
      setLocalStorage("profile", {
        userName: payload.userName,
        fullName: payload.fullName,
      });
      window.location.replace("/dashboard");

      return {
        ...state,
        isLoggedIn: true,
        loading: false,
      };
    case "AUTHENTICATE_USER_FAILURE":
      openNotificationWithIcon("error", payload.data.message);
      return {
        ...state,
        loading: false,
        isLoggedIn: false,
      };
    case "LOGOUT_ADMIN":
      removeCookie("token");
      setTimeout(() => {
        window.location.replace("/");
      }, [500]);
      return {
        ...state,
        isLoggedIn: false,
        loading: false,
      };

    default:
      return state;
  }
}
