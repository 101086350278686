import { openNotificationWithIcon } from "../../Components/Notification/Success";

const initialState = {
  classes: [],
  isLoading: false,
  subjects: [],
  subjectSubCategory: [],
  isClassChanged: false,
  class: {},
  isExamChanged: false,
  subjectSets: [],
  subSubjectSets: [],
  subjectQuestionList: [],
  isExamChanged: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case "GET_SUBJECT_SETS_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_SUBJECT_SETS_SUCCESS":
      return {
        ...state,
        isLoading: false,
        subjectSets: payload,
      };
    case "GET_SUBJECT_SETS_FAILURE":
      return {
        ...state,
        isLoading: false,
      };
    case "GET_SUB_SUBJECT_SETS_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_SUB_SUBJECT_SETS_SUCCESS":
      return {
        ...state,
        isLoading: false,
        subSubjectSets: payload,
      };
    case "GET_SUB_SUBJECT_SETS_FAILURE":
      return {
        ...state,
        isLoading: false,
      };
    case "ADD_SUBJECT_SET_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "ADD_SUBJECT_SET_SUCCESS":
      openNotificationWithIcon("success", payload.message);
      return {
        ...state,
        isLoading: false,
        isExamChanged: state.isExamChanged ? false : true,
      };
    case "ADD_SUBJECT_SET_FAILURE":
      openNotificationWithIcon("error", payload.data.message);
      return {
        ...state,
        isLoading: false,
      };
    case "ADD_SUB_SUBJECT_SET_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "ADD_SUB_SUBJECT_SET_SUCCESS":
      openNotificationWithIcon("success", payload.message);
      return {
        ...state,
        isLoading: false,
        isExamChanged: state.isExamChanged ? false : true,
      };
    case "ADD_SUB_SUBJECT_SET_FAILURE":
      openNotificationWithIcon("error", payload.data.message);
      return {
        ...state,
        isLoading: false,
      };
    case "ADD_QUESTION_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "ADD_QUESTION_SUCCESS":
      openNotificationWithIcon("success", payload.message);
      return {
        ...state,
        isLoading: false,
      };
    case "ADD_QUESTION_FAILURE":
      openNotificationWithIcon("error", payload.data.message);
      return {
        ...state,
        isLoading: false,
      };
    case "GET_SUB_SUBJECT_QUESTION_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_SUBJECT_QUESTION_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_SUBJECT_QUESTION_SUCCESS":
      return {
        ...state,
        isLoading: false,
        subjectQuestionList: payload,
      };
    case "GET_SUBJECT_QUESTION_FAILURE":
      return {
        ...state,
        isLoading: false,
      };

    case "DELETE_QUESTION_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "DELETE_QUESTION_SUCCESS":
      openNotificationWithIcon("success", payload.message);
      return {
        ...state,
        isLoading: false,
        isExamChanged: state.isExamChanged ? false : true,
      };
    case "DELETE_QUESTION_FAILURE":
      openNotificationWithIcon("error", payload.data.message);
      return {
        ...state,
        isLoading: false,
      };
    case "DELETE_QUESTION_SET_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "DELETE_QUESTION_SET_SUCCESS":
      openNotificationWithIcon("success", payload.message);
      return {
        ...state,
        isLoading: false,
        isExamChanged: state.isExamChanged ? false : true,
      };
    case "DELETE_QUESTION_SET_FAILURE":
      openNotificationWithIcon("error", payload.data.message);
      return {
        ...state,
        isLoading: false,
      };
    case "UPDATE_SUBJECT_QUESTION_SET_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "UPDATE_SUBJECT_QUESTION_SET_SUCCESS":
      openNotificationWithIcon("success", payload.message);
      setTimeout(() => {
        window.location.reload();
      }, [500]);
      return {
        ...state,
        isLoading: false,
        isExamChanged: state.isExamChanged ? false : true,
      };
    case "UPDATE_SUBJECT_QUESTION_SET_FAILURE":
      openNotificationWithIcon("error", payload.data.message);
      return {
        ...state,
        isLoading: false,
      };
    case "UPDATE_SUB_SUBJECT_QUESTION_SET_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "UPDATE_SUB_SUBJECT_QUESTION_SET_SUCCESS":
      openNotificationWithIcon("success", payload.message);
      setTimeout(() => {
        window.location.reload();
      }, [500]);
      return {
        ...state,
        isLoading: false,
        isExamChanged: state.isExamChanged ? false : true,
      };
    case "UPDATE_SUB_SUBJECT_QUESTION_SET_FAILURE":
      openNotificationWithIcon("error", payload.data.message);
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
