import { useEffect, useState } from "react";
import "../Classes Management/ClassManagement.css";
import { Table, Segmented } from "antd";
import { connect, useDispatch } from "react-redux";
import { Tag, Modal, Spin, Form, DatePicker, Button } from "antd";
import { Pagination } from "antd";
import TableSkeleton from "../../Components/Table Skeleton/TableSkeleton";
import SiderDemo from "../Layout/SiderDemo";
import moment from "moment";

const { RangePicker } = DatePicker;

const Payment = ({ ReportReason }) => {
  const dispatch = useDispatch();
  const [status, setStatus] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const onFinish = (values) => {
    dispatch({
      type: "GET_PAYMENT_REQUEST",
      payload: {
        page: 1,
        pageSize: 10,
        startDate: values.dateRange[0].format("YYYY-MM-DD HH:mm:ss"),
        endDate: values.dateRange[1].format("YYYY-MM-DD HH:mm:ss"),
        period: "CUSTOM",
      },
    });

    setIsModalVisible(false);
    form.resetFields();
  };

  useEffect(() => {
    dispatch({
      type: "GET_PAYMENT_REQUEST",
      payload: {
        page: page,
        pageSize: pageSize,
      },
    });
  }, [ReportReason.isReportChanged]);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      align: "center",
    },
    {
      title: "Amount",
      dataIndex: "price",

      width: 300,
      align: "center",
    },

    {
      title: "Discount",
      dataIndex: "discount",
      width: 250,
      align: "center",
    },
    {
      title: "GST",
      dataIndex: "gst",
      width: 250,
      align: "center",
    },
    {
      title: "Insurance Fee",
      dataIndex: "insuranceFee",
      width: 250,
      align: "center",
    },
    {
      title: "Service Fee",
      dataIndex: "serviceFee",
      width: 250,
      align: "center",
    },
    {
      title: "User Type",
      dataIndex: "userType",
      width: 250,
      align: "center",
    },
    {
      title: "Credit To User",
      dataIndex: "creditToUser",
      width: 250,
      align: "center",
    },
    {
      title: "Payment Completed",
      dataIndex: "paymentComplete",
      width: 250,
      align: "center",
    },
    {
      title: "Payment Cancelled",
      dataIndex: "paymentCancelled",
      width: 250,
      align: "center",
    },
  ];

  const data =
    ReportReason.payments.data &&
    ReportReason.payments.data.map((payment) => {
      return {
        name: payment.userFullName,
        price: "$" + payment.price,
        discount: "$" + payment.discount,
        gst: "$" + payment.gst,
        insuranceFee: "$" + payment.insurance,

        serviceFee: "$" + payment.serviceFee,
        userType: payment.userType,
        creditToUser: !payment.creditedToUser ? (
          <Tag color="red">No</Tag>
        ) : (
          <Tag color="green">Yes</Tag>
        ),
        paymentComplete: !payment.paymentComplete ? (
          <Tag color="red">No</Tag>
        ) : (
          <Tag color="green">Yes</Tag>
        ),
        paymentCancelled: !payment.paymentCancelled ? (
          <Tag color="red">No</Tag>
        ) : (
          <Tag color="green">Yes</Tag>
        ),
      };
    });
  function onShowSizeChange(current, pageSize) {
    window.scrollTo(0, 0);
    dispatch({
      type: "GET_PAYMENT_REQUEST",
      payload: {
        page: current,
        pageSize: pageSize,
      },
    });
  }
  const onStatusChangeHandler = (e) => {
    if (e == "All") {
      dispatch({
        type: "GET_PAYMENT_REQUEST",
        payload: {
          page: 1,
          pageSize: 10,
          period: "",
        },
      });
    } else if (e == "Custom") {
      dispatch({
        type: "CLEAR_PAYMENT",
      });
      setIsModalVisible(true);
    } else {
      dispatch({
        type: "GET_PAYMENT_REQUEST",
        payload: {
          page: 1,
          pageSize: 10,
          period: e.toUpperCase(),
        },
      });
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <div className="container">
        <Modal
          title="Select Custom Date"
          visible={isModalVisible}
          onCancel={handleCancel}
          footer={null}
        >
          <Form
            name="form"
            form={form}
            className="login-form"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
          >
            <Form.Item
              label="Date Range:"
              name="dateRange"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                width: "100%",
              }}
              rules={[
                {
                  required: true,
                  message: "Please input dateRange!",
                },
              ]}
            >
              <RangePicker
                showTime={{ format: "HH:mm" }}
                format="YYYY-MM-DD HH:mm:ss"
              />
            </Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ margin: "auto", width: "100%" }}
              className="login-form-button"
            >
              Submit
            </Button>
          </Form>
        </Modal>
        <SiderDemo>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: "2rem",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {" "}
                <h2 className="title-course">Payments</h2>
                <Segmented
                  style={{ height: "30px", fontSize: "11px" }}
                  options={["All", "Today", "Custom"]}
                  onChange={(e) => {
                    setStatus(e);
                    onStatusChangeHandler(e);
                  }}
                />
                {status == "Custom" &&
                  ReportReason.payments.object?.endDate && (
                    <div>
                      Start Date:{" "}
                      {moment(ReportReason.payments.object?.startDate).format(
                        "YYYY-MM-DD HH:mm:ss"
                      )}
                    </div>
                  )}
                {status == "Custom" &&
                  ReportReason.payments.object?.endDate && (
                    <div>
                      End Date Date:{" "}
                      {moment(ReportReason.payments.object?.endDate).format(
                        "YYYY-MM-DD HH:mm:ss"
                      )}
                    </div>
                  )}
              </div>
            </div>

            {ReportReason.isLoading ? (
              <TableSkeleton />
            ) : (
              <Table
                pagination={false}
                columns={columns}
                dataSource={data}
                bordered
                className="table-course"
              />
            )}
            {ReportReason.payments.totalPage && (
              <Pagination
                style={{
                  marginTop: "1rem",
                  marginBottom: "1rem",
                  alignSelf: "flex-end",
                }}
                showSizeChanger
                onShowSizeChange={onShowSizeChange}
                defaultCurrent={1}
                onChange={onShowSizeChange}
                total={ReportReason.payments.totalPage * 10}
              />
            )}
          </div>
        </SiderDemo>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    ReportReason: state.ReportReason,
  };
};

export default connect(mapStateToProps, {})(Payment);
