import React, { useEffect, useState } from "react";
import Breadcrump from "../../Components/Breadcrump/Breadcrump";
import "../Classes Management/ClassManagement.css";
import { Table, Popconfirm } from "antd";
import Sidebar from "../../Components/Sidebar/Sidebar";
import { connect, useDispatch } from "react-redux";
import { Tag, Spin, Segmented } from "antd";
import { AiOutlineEdit } from "react-icons/ai";
import { AiOutlineDelete, AiOutlineEye } from "react-icons/ai";
import history from "../../Helpers/history";
import { useNavigate, useParams } from "react-router-dom";
import { Drawer, Button, Modal } from "antd";

import { AiFillPlusCircle } from "react-icons/ai";
import { AiTwotonePlusCircle } from "react-icons/ai";
import { Form, Input, Checkbox, Pagination } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { MdOutlineSubject } from "react-icons/md";
import { HiOutlineAcademicCap } from "react-icons/hi";
import { Select, Tooltip } from "antd";
import { MdDisabledVisible } from "react-icons/md";

import TableSkeleton from "../../Components/Table Skeleton/TableSkeleton";
import { PlusOutlined } from "@ant-design/icons";
import Navbar from "../../Components/Navbar/Navbar";
import SiderDemo from "../Layout/SiderDemo";
import moment from "moment";

const { Search } = Input;
const { Option } = Select;

const QuestionListSubSubject = ({ Class, Exam }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [classId, setClassId] = useState();
  const [form] = Form.useForm();
  const [searchValue, setSearchValue] = useState("");
  const [visible, setVisible] = useState(false);
  const [size, setSize] = useState("large");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { id } = useParams();

  const onFinish = (values) => {
    console.log("Received values of form: ", values);
    dispatch({
      type: "ADD_CLASS_REQUEST",
      payload: values,
    });
    setIsModalVisible(false);
    form.resetFields();
  };
  const enableDisableClassHandler = (id) => {
    dispatch({
      type: "ENABLE_DISABLE_CLASS_REQUEST",
      payload: {
        id: id,
      },
    });
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onClose = () => {
    setVisible(false);
  };

  useEffect(() => {
    dispatch({
      type: "GET_CLASSES_REQUEST",
      payload: {
        page: 1,
        pageSize: 10,
      },
    });
    dispatch({
      type: "GET_SUB_SUBJECT_QUESTION_REQUEST",
      payload: {
        id: id,
      },
    });
  }, [Exam.isExamChanged]);

  const deleteQuestionHandler = (id) => {
    dispatch({
      type: "DELETE_QUESTION_REQUEST",
      payload: {
        id: id,
      },
    });
  };
  const detailClickHandler = (id, name) => {
    navigate(`/class-detail/${id}`, { state: name });
  };

  const sharedOnCell = (_, index) => {
    if (index === 4) {
      return { colSpan: 0 };
    }
  };
  function handleChange(value) {
    console.log(`selected ${value}`);
  }

  const columns = [
    {
      title: "Question",
      dataIndex: "question",

      onCell: sharedOnCell,
      width: 300,
      align: "center",
    },

    {
      title: "Options",
      dataIndex: "options",
      onCell: sharedOnCell,
      width: 250,
      align: "center",
    },
    {
      title: "Correct Answer",
      dataIndex: "correctAnswer",
      onCell: sharedOnCell,
      width: 150,
      align: "center",
    },
    {
      title: "Correct Answer Details",
      dataIndex: "correctAnswerDetail",
      onCell: sharedOnCell,
      width: 250,
      align: "center",
    },

    {
      title: "Actions",
      dataIndex: "actions",
      onCell: sharedOnCell,
      align: "center",
    },
  ];

  const data =
    Exam.subjectQuestionList &&
    Exam.subjectQuestionList.map((el) => {
      return {
        question: (
          <div
            dangerouslySetInnerHTML={{
              __html: el.examQuestionResponse?.question,
            }}
          />
        ),
        options: el.examQuestionResponse?.examAnswerResponses.map((el) => {
          return <p>{el.answer}</p>;
        }),
        correctAnswer: el.examQuestionResponse?.examAnswerResponses.filter(
          (el) => {
            return el.isCorrect == true;
          }
        )[0].answer,
        correctAnswerDetail:
          el.examQuestionResponse?.examAnswerResponses.filter((el) => {
            return el.isCorrect == true;
          })[0].correctAnswerDetail,

        actions: (
          <div className="action">
            {" "}
            {/* <Tooltip title="Edit Class">
              <Tag
                color="#2db7f5"
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  setVisible(true);
                  setClassId(cl.subjectClassId);
                }}
              >
                <AiOutlineEdit className="icon" />
              </Tag>
            </Tooltip> */}
            <Tooltip title="Delete Question">
              {" "}
              <Popconfirm
                title="Are you sure want to delete this question?"
                onConfirm={() =>
                  deleteQuestionHandler(el.examQuestionResponse?.questionId)
                }
              >
                <AiOutlineDelete className="icon" />
              </Popconfirm>
            </Tooltip>
          </div>
        ),
      };
    });
  function onShowSizeChange(current, pageSize) {
    window.scrollTo(0, 0);
    dispatch({
      type: "GET_CLASSES_REQUEST",
      payload: {
        page: current,
        pageSize: pageSize,
      },
    });
  }
  const onSearch = (e) => {
    dispatch({
      type: "SEARCH_CLASSES_REQUEST",
      payload: {
        pageNo: 1,
        pageSize: 10,
        keyword: e,
      },
    });
  };
  return (
    <>
      <div className="container">
        <Modal
          title="New Class"
          visible={isModalVisible}
          onCancel={handleCancel}
          footer={null}
        >
          <Spin spinning={Class.isLoading}>
            <Form
              name="form"
              form={form}
              className="login-form"
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
            >
              <Form.Item
                label="Class Standard"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
                name="classStandard"
                rules={[
                  {
                    required: true,
                    message: "Please input class name!",
                  },
                ]}
              >
                <Input placeholder="Class Name" />
              </Form.Item>
              <Form.Item
                label="Class Type"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
                name="classType"
                rules={[
                  {
                    required: true,
                    message: "Please input class type!",
                  },
                ]}
              >
                <Select placeholder="Select Class Type">
                  <Option value="Academic">Academic</Option>
                  <Option value="Non_Academic">Non-Academic</Option>
                </Select>
              </Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{ margin: "auto", width: "100%" }}
                className="login-form-button"
              >
                Add
              </Button>
            </Form>
          </Spin>
        </Modal>
        <SiderDemo>
          <Segmented
            style={{ height: "0px", fontSize: "11px", display: "none" }}
          />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: "2rem",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {" "}
                <h2 className="title-course">Question List</h2>
              </div>
            </div>

            {/* <nav class="menu"></nav> */}
            {Exam.isLoading ? (
              <TableSkeleton />
            ) : (
              <Table
                pagination={false}
                columns={columns}
                dataSource={data}
                bordered
                className="table-course"
              />
            )}
            {/* {Class.classes.totalPage && (
              <Pagination
                style={{
                  marginTop: "1rem",
                  marginBottom: "1rem",
                  alignSelf: "flex-end",
                }}
                showSizeChanger
                onShowSizeChange={onShowSizeChange}
                defaultCurrent={1}
                onChange={onShowSizeChange}
                total={Class.classes.totalPage * 10}
              />
            )} */}
          </div>
        </SiderDemo>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    Class: state.Class,
    Exam: state.Exam,
  };
};

export default connect(mapStateToProps, {})(QuestionListSubSubject);
