import { openNotificationWithIcon } from "../../Components/Notification/Success";

const initialState = {
  isLoading: false,
  isTermsAndPrivacyChanged: false,
  termsAndCondition: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case "ADD_PRIVACY_TERMS_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "ADD_PRIVACY_TERMS_SUCCESS":
      openNotificationWithIcon("success", payload.message);
      return {
        ...state,
        isLoading: false,
        isTermsAndPrivacyChanged: state.isTermsAndPrivacyChanged ? false : true,
      };
    case "ADD_PRIVACY_TERMS_FAILURE":
      openNotificationWithIcon("error", payload.data.message);
      return {
        ...state,
        isLoading: false,
      };

    case "GET_TERMS_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_TERMS_SUCCESS":
      //   openNotificationWithIcon("success", payload.message);
      return {
        ...state,
        isLoading: false,
        termsAndCondition: payload,
        isTermsAndPrivacyChanged: "fetched",
      };
    case "GET_TERMS_FAILURE":
      openNotificationWithIcon("error", payload.data.message);
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
}
