import React, { useEffect, useState } from "react";
import "../Advertisement/Advertisement.css";
import Breadcrump from "../../Components/Breadcrump/Breadcrump";
import "../Classes Management/ClassManagement.css";
import {
  Table,
  Popconfirm,
  Form,
  Input,
  Button,
  Spin,
  Card,
  Tag,
  Select,
  Tooltip,
  Modal,
} from "antd";
import Sidebar from "../../Components/Sidebar/Sidebar";
import { connect, useDispatch } from "react-redux";
import ReactQuill from "react-quill";
import { PlusOutlined } from "@ant-design/icons";
import "react-quill/dist/quill.snow.css";
import Navbar from "../../Components/Navbar/Navbar";
import "./RolesPermission.css";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import SiderDemo from "../Layout/SiderDemo";
import { Empty } from "antd";
import { Checkbox, Row, Col } from "antd";

const { TextArea } = Input;
const { Option } = Select;

const RolesPermission = ({ profile }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisiblePermission, setIsModalVisiblePermission] =
    useState(false);
  const [value, setValue] = useState(profile.defaultValue);
  const [role, setRole] = useState(1);
  const [permission, setPermission] = useState(
    profile.role.permissions && profile.role.permissions.map((el) => el.id)
  );

  function onChange(checkedValues) {
    console.log("checked = ", checkedValues);
    setPermission(checkedValues);
  }

  const onFinish = (values) => {
    console.log(values);
    dispatch({
      type: "ADD_ROLE_REQUEST",
      payload: values,
    });
    form.resetFields();
    setIsModalVisible(false);
  };
  const onFinishPermission = (values) => {
    console.log(role);
    dispatch({
      type: "UPDATE_PERMISSION_REQUEST",
      payload: {
        roleId: role,
        permissions: permission,
      },
    });
    setIsModalVisiblePermission(false);
  };

  useEffect(() => {
    dispatch({
      type: "GET_ROLES_REQUEST",
    });
    dispatch({
      type: "GET_ROLE_LIST_REQUEST",
    });
    dispatch({
      type: "GET_ROLE_REQUEST",
      payload: {
        id: role,
      },
    });
    dispatch({
      type: "GET_PERMISSION_LIST_REQUEST",
    });
  }, [profile.isRoleAdded, profile.isPermissionAdded]);

  useEffect(() => {
    if (profile.role.permissions) {
      setPermission(profile.role.permissions.map((el) => el.id));
    }
  }, [profile.role, profile.isPermissionAdded]);

  const firstRole = profile.roleList && profile.roleList[0];

  const roleChangeHandler = (e) => {
    setValue(e);
    dispatch({
      type: "GET_ROLE_REQUEST",
      payload: {
        id: e,
      },
    });
  };
  const showModal = () => {
    setIsModalVisible(true);
  };
  const showModalPermission = () => {
    setIsModalVisiblePermission(true);
  };
  const handleCancelPermission = () => {
    setIsModalVisiblePermission(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const defaultPermissions = () => {
    const permissions =
      profile.role.permissions && profile.role.permissions.map((el) => el.id);
    return permissions;
  };

  console.log(role, permission);
  return (
    <>
      <div className="container">
        <Modal
          style={{ marginTop: "-3rem" }}
          title="Update Permission"
          visible={isModalVisiblePermission}
          onCancel={handleCancelPermission}
          footer={null}
        >
          <Spin spinning={profile.isLoading}>
            <Form
              name="form"
              encType="multipart/form-data"
              form={form}
              className="login-form"
              initialValues={{
                remember: true,
              }}
              fields={[
                {
                  name: ["checkbox"],
                  value: permission,
                },
              ]}
              onFinish={onFinishPermission}
            >
              <Form.Item
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  width: "100%",
                }}
                name="checkbox"
              >
                <Checkbox.Group
                  style={{ width: "100%" }}
                  onChange={onChange}
                  // defaultValue={defaultPermissions()}
                >
                  <Row>
                    {profile.permissions &&
                      profile.permissions.map((per) => (
                        <Col key={per.id} span={12}>
                          <Checkbox value={per.id}>{per.name}</Checkbox>
                        </Col>
                      ))}
                  </Row>
                </Checkbox.Group>
              </Form.Item>

              <Button
                type="primary"
                htmlType="submit"
                style={{ margin: "auto", width: "100%" }}
                className="login-form-button"
              >
                Update
              </Button>
            </Form>
          </Spin>
        </Modal>
        <Modal
          style={{ marginTop: "-3rem" }}
          title="New Role"
          visible={isModalVisible}
          onCancel={handleCancel}
          footer={null}
        >
          <Spin spinning={profile.isLoading}>
            <Form
              name="form"
              encType="multipart/form-data"
              form={form}
              className="login-form"
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
            >
              <Form.Item
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  width: "100%",
                }}
                label="Role Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Please input role name!",
                  },
                ]}
              >
                <Input placeholder="Role Name" />
              </Form.Item>
              <Form.Item
                label="Active"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  width: "100%",
                }}
                name="isActive"
                rules={[
                  {
                    required: true,
                    message: "Please input active status!",
                  },
                ]}
              >
                <Select placeholder="Active">
                  <Option value={true}>Yes</Option>
                  <Option value={false}>No</Option>
                </Select>
              </Form.Item>

              <Button
                type="primary"
                htmlType="submit"
                style={{ margin: "auto", width: "100%" }}
                className="login-form-button"
              >
                Add
              </Button>
            </Form>
          </Spin>
        </Modal>
        <SiderDemo>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Card style={{ width: 600 }}>
              <div
                style={{
                  background: "#5D3BED",
                  height: "50px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  paddingLeft: "20px",
                  color: "white",
                  fontWeight: "normal",
                }}
              >
                Manage Roles And Permission
              </div>
              <div className="roles-and-permission-container">
                <div className="top">
                  {" "}
                  <span>Roles</span>
                  <Tooltip title="Add Role">
                    <PlusOutlined className="icon" onClick={showModal} />
                  </Tooltip>
                </div>
                <Select
                  placeholder="Role"
                  className="select"
                  onChange={(e) => {
                    roleChangeHandler(e);
                    setRole(e);
                  }}
                  defaultValue={1}
                >
                  {profile.roles &&
                    profile.roles.map((role) => {
                      return <Option value={role.id}>{role.name}</Option>;
                    })}
                </Select>

                <div className="top permission">
                  {" "}
                  <span>Permissions</span>
                  <Tooltip title="Add Permission">
                    <PlusOutlined
                      className="icon"
                      onClick={showModalPermission}
                    />
                  </Tooltip>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    marginTop: "10px",
                  }}
                >
                  {profile.role.permissions &&
                  profile.role.permissions.length > 0 ? (
                    profile.role.permissions.map((per) => (
                      <Tag
                        style={{
                          marginRight: "1rem",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          padding: "5px",
                        }}
                        color="blue"
                      >
                        {per.name}
                        {/* <div className="action" style={{ display: "flex" }}>
                          {" "}
                          <Tooltip title="Edit">
                            <AiOutlineEdit className="icon" />
                          </Tooltip>
                          <Popconfirm
                            title="Are you sure want to delete?"
                            onConfirm={() => {
                              // dispatch({
                              //   type: "DELETE_SUB_SUBJECT_REQUEST",
                              //   payload: {
                              //     id: subject.subjectSubCategoryDetails.filter(
                              //       (el) => el.subjectSubCategoryName === sub
                              //     )[0].subjectSubCategoryId,
                              //   },
                              // });
                            }}
                          >
                            <Tooltip title="Delete">
                              <AiOutlineDelete
                                className="icon"
                                style={{ color: "red" }}
                              />
                            </Tooltip>
                          </Popconfirm>
                        </div> */}
                      </Tag>
                    ))
                  ) : (
                    <Empty description="No Permissions of this role!" />
                  )}
                </div>
              </div>
            </Card>
          </div>
        </SiderDemo>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    profile: state.profile,
  };
};

export default connect(mapStateToProps, {})(RolesPermission);
