import { openNotificationWithIcon } from "../../Components/Notification/Success";

const initialState = {
  profile: {},
  isLoading: false,
  roles: [],
  admins: [],
  isProfileChanged: false,
  isNavToggled: false,
  dashboardData: {},
  role: {},
  roleList: [],
  defaultValue: "",
  permissions: [],
  isPermissionFetched: false,
  isRoleAdded: false,
  isPermissionAdded: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case "GET_PROFILE_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_PROFILE_SUCCESS":
      return {
        ...state,
        isLoading: false,
        profile: payload,
      };
    case "GET_PROFILE_FAILURE":
      return {
        ...state,
        isLoading: false,
      };
    case "CHANGE_PASSWORD_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "CHANGE_PASSWORD_SUCCESS":
      openNotificationWithIcon("success", payload.message);
      return {
        ...state,
        isLoading: false,
      };
    case "CHANGE_PASSWORD_FAILURE":
      openNotificationWithIcon("error", payload.data.message);
      return {
        ...state,
        isLoading: false,
      };
    case "GET_ROLES_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_ROLES_SUCCESS":
      return {
        ...state,
        isLoading: false,
        roles: payload,
      };
    case "GET_ROLES_FAILURE":
      return {
        ...state,
        isLoading: false,
      };
    case "GET_ROLE_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_ROLE_SUCCESS":
      console.log(payload);
      return {
        ...state,
        isLoading: false,
        role: payload,
        isProfileChanged: true,
      };
    case "GET_ROLE_FAILURE":
      return {
        ...state,
        isLoading: false,
      };
    case "GET_ROLE_LIST_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_ROLE_LIST_SUCCESS":
      return {
        ...state,
        isLoading: false,
        defaultValue: payload[0].id,
        roleList: payload,
        isProfileChanged: "profile-changed",
      };
    case "GET_ROLE_LIST_FAILURE":
      return {
        ...state,
        isLoading: false,
      };
    case "GET_PERMISSION_LIST_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_PERMISSION_LIST_SUCCESS":
      return {
        ...state,
        isLoading: false,
        permissions: payload,
        isPermissionFetched: true,
      };
    case "GET_PERMISSION_LIST_FAILURE":
      return {
        ...state,
        isLoading: false,
      };
    case "ADD_ROLE_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "ADD_ROLE_SUCCESS":
      openNotificationWithIcon("success", payload.message);
      return {
        ...state,
        isLoading: false,
        isProfileChanged: state.isProfileChanged ? false : true,
        isRoleAdded: true,
      };
    case "ADD_ROLE_FAILURE":
      openNotificationWithIcon("error", payload.data.message);
      return {
        ...state,
        isLoading: false,
      };
    case "UPDATE_PERMISSION_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "UPDATE_PERMISSION_SUCCESS":
      openNotificationWithIcon("success", payload.message);
      return {
        ...state,
        isLoading: false,
        isPermissionAdded: state.isPermissionAdded ? false : true,
      };
    case "UPDATE_PERMISSION_FAILURE":
      openNotificationWithIcon("error", payload.data.message);
      return {
        ...state,
        isLoading: false,
      };
    case "REGISTER_ADMIN_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "REGISTER_ADMIN_SUCCESS":
      openNotificationWithIcon("success", payload.message);
      return {
        ...state,
        isLoading: false,
      };
    case "REGISTER_ADMIN_FAILURE":
      openNotificationWithIcon("error", payload.data.message);
      return {
        ...state,
        isLoading: false,
      };
    case "GET_ADMIN_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_ADMIN_SUCCESS":
      return {
        ...state,
        isLoading: false,
        admins: payload,
      };
    case "GET_ADMIN_FAILURE":
      return {
        ...state,
        isLoading: false,
      };
    case "ENABLE_ADMIN_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "ENABLE_ADMIN_FAILURE":
      openNotificationWithIcon("error", payload.data.message);
      return {
        ...state,
        isLoading: false,
      };
    case "ENABLE_ADMIN_SUCCESS":
      openNotificationWithIcon("success", payload.message);

      return {
        ...state,
        isLoading: false,
        isProfileChanged: state.isProfileChanged ? false : true,
      };
    case "DISABLE_ADMIN_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "DISABLE_ADMIN_FAILURE":
      openNotificationWithIcon("error", payload.data.message);
      return {
        ...state,
        isLoading: false,
      };
    case "DISABLE_ADMIN_SUCCESS":
      openNotificationWithIcon("success", payload.message);

      return {
        ...state,
        isLoading: false,
        isProfileChanged: state.isProfileChanged ? false : true,
      };
    case "UPDATE_PROFILE_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "UPDATE_PROFILE_FAILURE":
      openNotificationWithIcon("error", payload.data.message);
      return {
        ...state,
        isLoading: false,
      };
    case "UPDATE_PROFILE_SUCCESS":
      openNotificationWithIcon("success", payload.message);

      return {
        ...state,
        isLoading: false,
        isProfileChanged: state.isProfileChanged ? false : true,
      };
    case "GET_DASHBOARD_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_DASHBOARD_SUCCESS":
      return {
        ...state,
        isLoading: false,
        dashboardData: payload,
      };
    case "GET_DASHBOARD_FAILURE":
      return {
        ...state,
        isLoading: false,
      };

    // case "TOGGLE_NAV":
    //   return {
    //     ...state,
    //     isNavToggled: state.isNavToggled ? false : true,
    //   };
    default:
      return state;
  }
}
