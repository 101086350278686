import { getRoles } from "@testing-library/react";
import { combineEpics } from "redux-observable";
import {
  activateAdminEpic,
  addAdvertisementEpic,
  addClassEpic,
  addCommissionEpic,
  addCouponEpic,
  addLoyaltyEpic,
  addNotificationEpic,
  addPrivacyTermsEpic,
  addQuestionEpic,
  addRoleEpic,
  addSubjectEpic,
  addSubSubjectEpic,
  authenticateUserEpic,
  changePasswordEpic,
  deleteClassEpic,
  deleteCouponEpic,
  deleteSubjectEpic,
  deleteSubSubjectEpic,
  disableAdminEpic,
  disableAdvertisementEpic,
  disableClientEpic,
  disableCouponEpic,
  enableDisableClassEpic,
  getAdminEpic,
  getAdvertisementEpic,
  getClassEpic,
  getClassesEpic,
  getClientEpic,
  getCommissionEpic,
  getCouponCodeEpic,
  getDashboardEpic,
  getLoyaltyEpic,
  getPermissionEpic,
  getProfileEpic,
  getRoleEpic,
  getRolesEpic,
  getRolesListEpic,
  getSingleAdEpic,
  getSingleCouponEpic,
  getSubjectEpic,
  getSubjectQuestionEpic,
  getSubjectSetsEpic,
  getSubSubjectSetsEpic,
  getSupportEpic,
  getTermsEpic,
  getTutoringDetailEpic,
  getTutoringEpic,
  markSupportEpic,
  registerAdminEpic,
  searchAdvertisementEpic,
  searchClassesEpic,
  searchClientEpic,
  searchCouponCodeEpic,
  updateAdminEpic,
  updateAdvertisementEpic,
  updateClassEpic,
  updateCouponEpic,
  updatePermissionEpic,
  updateSubjectEpic,
  updateSubSubjectEpic,
  deleteQuestionEpic,
  getSubSubjectQuestionEpic,
  addSubjectSetEpic,
  addSubSubjectSetEpic,
  deleteQuestionSetEpic,
  updateSubjectQuestionSetEpic,
  updateSubSubjectQuestionSetEpic,
  getReportReasonEpic,
  getSubjectListEpic,
  getDirectoryListEpic,
  uploadResourceFileEpic,
  addDirectoryEpic,
  addReportReasonEpic,
  deleteReportReasonEpic,
  updateReportReasonEpic,
  getReportsEpic,
  getPaymentEpic,

  // registerUserEpic,
} from "./myEpics";

export const rootEpic = combineEpics(
  updateClassEpic,
  authenticateUserEpic,
  // registerUserEpic,
  getProfileEpic,
  getClassesEpic,
  getClassEpic,
  getSubjectEpic,
  deleteClassEpic,
  updateSubjectEpic,
  updateSubSubjectEpic,
  deleteSubjectEpic,
  deleteSubSubjectEpic,
  addClassEpic,
  enableDisableClassEpic,
  getAdvertisementEpic,
  getSingleAdEpic,
  addAdvertisementEpic,
  updateAdvertisementEpic,
  disableAdvertisementEpic,
  getLoyaltyEpic,
  addLoyaltyEpic,
  getCommissionEpic,
  addCommissionEpic,
  addSubjectEpic,
  addSubSubjectEpic,
  getCouponCodeEpic,
  addCouponEpic,
  disableCouponEpic,
  deleteCouponEpic,
  getSingleCouponEpic,
  updateCouponEpic,
  getClientEpic,
  disableClientEpic,
  addNotificationEpic,
  getTermsEpic,
  addPrivacyTermsEpic,
  changePasswordEpic,
  getRolesEpic,
  registerAdminEpic,
  getAdminEpic,
  activateAdminEpic,
  disableAdminEpic,
  searchClassesEpic,
  searchAdvertisementEpic,
  searchCouponCodeEpic,
  updateAdminEpic,
  getDashboardEpic,
  getSupportEpic,
  markSupportEpic,
  getTutoringEpic,
  getTutoringDetailEpic,
  getRoleEpic,
  addRoleEpic,
  getRolesListEpic,
  searchClientEpic,
  getPermissionEpic,
  updatePermissionEpic,
  getSubjectSetsEpic,
  getSubSubjectSetsEpic,
  addQuestionEpic,
  getSubjectQuestionEpic,
  deleteQuestionEpic,
  getSubSubjectQuestionEpic,
  addSubjectSetEpic,
  addSubSubjectSetEpic,
  deleteQuestionSetEpic,
  updateSubjectQuestionSetEpic,
  updateSubSubjectQuestionSetEpic,
  getReportReasonEpic,
  getSubjectListEpic,
  getDirectoryListEpic,
  uploadResourceFileEpic,
  addDirectoryEpic,
  addReportReasonEpic,
  deleteReportReasonEpic,
  updateReportReasonEpic,
  getReportsEpic,
  getPaymentEpic
);
