import React, { useEffect, useState } from "react";
import "../Advertisement/Advertisement.css";
import "../Classes Management/ClassManagement.css";
import { Button, Card, Tooltip, Segmented, Empty } from "antd";
import { connect, useDispatch } from "react-redux";
import SiderDemo from "../Layout/SiderDemo";
import { Link, useParams } from "react-router-dom";
import "../Exam Management/ExamSubject.css";
import { AiFillEye } from "react-icons/ai";

const ExamSubject = ({ Exam, Class, Resource }) => {
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch({
      type: "GET_SUBJECT_LIST_REQUEST",
      payload: {
        id: id,
      },
    });
  }, [Class.isClassChanged, Exam.isExamChanged]);

  return (
    <>
      <div className="container">
        <SiderDemo>
          <Segmented
            style={{ height: "0px", fontSize: "11px", display: "none" }}
          />
          <div>
            <Card
              className="card"
              style={{ width: "100%", padding: "5px" }}
              loading={Resource.isLoading}
            >
              {" "}
              <div
                style={{
                  background: "#5D3BED",
                  height: "50px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  paddingLeft: "20px",
                  color: "white",
                  fontWeight: "normal",
                }}
              >
                Subject Lists
              </div>
              <div
                grid={{
                  xs: 1,
                  sm: 2,
                  md: 4,
                  lg: 4,
                  xl: 6,
                  xxl: 3,
                }}
                style={{
                  display: "flex",
                  width: "100%",
                  gap: "1rem",
                  marginBottom: "1rem",
                  flexWrap: "wrap",
                }}
              >
                {Resource.subjectLists?.length > 0 ? (
                  Resource.subjectLists?.map((subject, index) => {
                    return (
                      <Card
                        className="start-exam"
                        style={{ background: "white" }}
                      >
                        <div className="top-subject-title">
                          <span>{subject.subjectName}</span>
                          <div style={{ display: "flex", gap: "5px" }}>
                            <Tooltip title="See Directory List">
                              <Link to={`/directory-list/${subject.dirId}`}>
                                <Button
                                  style={{
                                    border: "none",
                                    background: "#FF618C",

                                    color: "white",
                                    borderRadius: "50%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                  icon={<AiFillEye />}
                                ></Button>
                              </Link>
                            </Tooltip>
                          </div>
                        </div>
                      </Card>
                    );
                  })
                ) : (
                  <Empty description="No Subjects in this class !"></Empty>
                )}
              </div>
            </Card>
          </div>
        </SiderDemo>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    MassNotification: state.MassNotification,
    TermsAndPrivacy: state.TermsAndPrivacy,
    Exam: state.Exam,
    Class: state.Class,
    Resource: state.Resource,
  };
};

export default connect(mapStateToProps, {})(ExamSubject);
