import { openNotificationWithIcon } from "../../Components/Notification/Success";

const initialState = {
  reportReasons: [],
  isLoading: false,
  isReportChanged: false,
  reports: [],
  payments: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case "CLEAR_PAYMENT":
      return {
        ...state,
        payments: [],
      };
    case "GET_PAYMENT_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_PAYMENT_SUCCESS":
      //   openNotificationWithIcon("success", payload.message);
      return {
        ...state,
        isLoading: false,
        payments: payload,
      };
    case "GET_PAYMENT_FAILURE":
      //   openNotificationWithIcon("error", payload.data.message);
      return {
        ...state,
        isLoading: false,
      };
    case "GET_REPORT_REASON_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_REPORT_REASON_SUCCESS":
      //   openNotificationWithIcon("success", payload.message);
      return {
        ...state,
        isLoading: false,
        reportReasons: payload,
      };
    case "GET_REPORT_REASON_FAILURE":
      //   openNotificationWithIcon("error", payload.data.message);
      return {
        ...state,
        isLoading: false,
      };
    case "GET_REPORT_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_REPORT_SUCCESS":
      //   openNotificationWithIcon("success", payload.message);
      return {
        ...state,
        isLoading: false,
        reports: payload,
      };
    case "GET_REPORT_FAILURE":
      //   openNotificationWithIcon("error", payload.data.message);
      return {
        ...state,
        isLoading: false,
      };

    case "ADD_REPORT_REASON_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "ADD_REPORT_REASON_SUCCESS":
      openNotificationWithIcon("success", payload.message);
      return {
        ...state,
        isLoading: false,
        isReportChanged: state.isReportChanged ? false : true,
      };
    case "ADD_REPORT_REASON_FAILURE":
      openNotificationWithIcon("error", payload.data.message);
      return {
        ...state,
        isLoading: false,
      };
    case "DELETE_REPORT_REASON_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "DELETE_REPORT_REASON_SUCCESS":
      openNotificationWithIcon("success", payload.message);
      return {
        ...state,
        isLoading: false,
        isReportChanged: state.isReportChanged ? false : true,
      };
    case "DELETE_REPORT_REASON_FAILURE":
      openNotificationWithIcon("error", payload.data.message);
      return {
        ...state,
        isLoading: false,
      };
    case "UPDATE_REPORT_REASON_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "UPDATE_REPORT_REASON_SUCCESS":
      openNotificationWithIcon("success", payload.message);
      return {
        ...state,
        isLoading: false,
        isReportChanged: state.isReportChanged ? false : true,
      };
    case "UPDATE_REPORT_REASON_FAILURE":
      openNotificationWithIcon("error", payload.data.message);
      return {
        ...state,
        isLoading: false,
      };
    case "DISABLE_CLIENT_SUCCESS":
      return {
        ...state,
        isLoading: false,
        isReportChanged: state.isReportChanged ? false : true,
      };

    default:
      return state;
  }
}
