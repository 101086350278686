import React, { useEffect, useState } from "react";
import "../Advertisement/Advertisement.css";
import Breadcrump from "../../Components/Breadcrump/Breadcrump";
import "../Classes Management/ClassManagement.css";
import { Table, Popconfirm, Form, Input, Button, Spin, Card, Tag } from "antd";
import Sidebar from "../../Components/Sidebar/Sidebar";
import { connect, useDispatch } from "react-redux";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Navbar from "../../Components/Navbar/Navbar";
import "./MyProfile.css";
import SiderDemo from "../Layout/SiderDemo";

const { TextArea } = Input;

const ChangePassword = ({ profile }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const onFinish = (values) => {
    console.log(values);
    dispatch({
      type: "CHANGE_PASSWORD_REQUEST",
      payload: values,
    });
    form.resetFields();
  };

  useEffect(() => {
    dispatch({
      type: "GET_PROFILE_REQUEST",
    });
  }, []);

  return (
    <>
      <div className="container">
        <SiderDemo>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Card style={{ width: 450 }} loading={profile.isLoading}>
              <div
                style={{
                  background: "#5D3BED",
                  height: "50px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  paddingLeft: "20px",
                  color: "white",
                  fontWeight: "normal",
                }}
              >
                Change Password
              </div>
              <Form
                name="form"
                encType="multipart/form-data"
                form={form}
                name="normal_login"
                className="login-form"
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
              >
                <Form.Item
                  label="Current Password"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    width: "100%",
                  }}
                  name="currentPassword"
                  rules={[
                    {
                      required: true,
                      message: "Please input current password!",
                    },
                  ]}
                >
                  <Input placeholder="current-password" type="password" />
                </Form.Item>
                <Form.Item
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    width: "100%",
                  }}
                  label="New Password"
                  name="newPassword"
                  rules={[
                    {
                      required: true,
                      message: "Please input new password!",
                    },
                  ]}
                >
                  <Input placeholder="new-password" type="password" />
                </Form.Item>
                <Form.Item
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    width: "100%",
                  }}
                  label="Confirm Password"
                  name="confirmPassword"
                  rules={[
                    {
                      required: true,
                      message: "Please input confirm password!",
                    },
                  ]}
                >
                  <Input placeholder="confirm-password" type="password" />
                </Form.Item>

                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ margin: "auto", width: "100%" }}
                  className="login-form-button"
                >
                  Change Password
                </Button>
              </Form>
            </Card>
          </div>
        </SiderDemo>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    profile: state.profile,
  };
};

export default connect(mapStateToProps, {})(ChangePassword);
