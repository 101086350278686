import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import Sidebar from "../../Components/Sidebar/Sidebar";
import Breadcrump from "../../Components/Breadcrump/Breadcrump";
import { Descriptions, Badge, Spin, Tag, Segmented, Card } from "antd";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import { Collapse } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import Navbar from "../../Components/Navbar/Navbar";
import SiderDemo from "../Layout/SiderDemo";
import moment from "moment";
const { Panel } = Collapse;

const ClassDetail = ({ Class }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch({
      type: "GET_CLASS_REQUEST",
      payload: {
        id: id,
      },
    });

    dispatch({
      type: "GET_SUBJECT_CATEGORY_REQUEST",
      payload: {
        id: id,
      },
    });
  }, []);
  return (
    <div className="container">
      <SiderDemo>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Segmented style={{ height: "0px", fontSize: "11px" }} />
          <Card style={{ width: 900 }} loading={Class.isLoading}>
            <div
              style={{
                background: "#F0F2F5",
                height: "40px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingLeft: "20px",
                color: "black",

                fontSize: "17px",
              }}
            >
              Class Details
            </div>
            <Descriptions
              layout="vertical"
              bordered
              className="description-container"
            >
              <Descriptions.Item label="Class Name">
                {Class.class.classStandard}
              </Descriptions.Item>
              <Descriptions.Item label="Class Type">
                {Class.class.classType}
              </Descriptions.Item>
              <Descriptions.Item label="Status">
                {Class.class.disabled ? (
                  <Tag color="red">Not Active</Tag>
                ) : (
                  <Tag color="green">Active</Tag>
                )}
              </Descriptions.Item>
              <Descriptions.Item
                style={{ width: "400px" }}
                label="Subjects"
                span={3}
              >
                <div
                  grid={{
                    xs: 1,
                    sm: 2,
                    md: 4,
                    lg: 4,
                    xl: 6,
                    xxl: 3,
                  }}
                  style={{
                    display: "flex",
                    width: "100%",
                    gap: "1rem",

                    flexWrap: "wrap",
                  }}
                >
                  {Class.subjects.length > 0 ? (
                    Class.subjects.map((subject, index) => {
                      return (
                        <Card
                          className="start-exam class-detail-card"
                          title={
                            <div className="top-subject-title">
                              <span>{subject.categoryName}</span>
                            </div>
                          }
                          style={{ background: "white" }}
                        >
                          {Class.subjectSubCategory[index].length > 0
                            ? Class.subjectSubCategory[index].map((sub) => (
                                <div className="top-subject-title">
                                  <span>{sub}</span>
                                </div>
                              ))
                            : "No Sub Categories!"}
                        </Card>
                      );
                    })
                  ) : (
                    <p>No Subjects in this class.</p>
                  )}
                </div>
              </Descriptions.Item>

              <Descriptions.Item label="Added By">
                {Class.class.addedAdminName}
              </Descriptions.Item>
              <Descriptions.Item label="Added At">
                {moment(Class.class.addedAt).format("YYYY-MM-DD")}
              </Descriptions.Item>
              <Descriptions.Item label="Updated By">
                {Class.class.updatedAdminName}
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </div>
      </SiderDemo>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    Class: state.Class,
  };
};

export default connect(mapStateToProps, {})(ClassDetail);
