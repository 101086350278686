import { openNotificationWithIcon } from "../../Components/Notification/Success";

const initialState = {
  advertisements: [],
  isLoading: false,
  isAdvertisementChanged: false,
  advertisement: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case "GET_ADVERTISEMENT_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_ADVERTISEMENT_SUCCESS":
      //   openNotificationWithIcon("success", payload.message);
      return {
        ...state,
        isLoading: false,
        advertisements: payload,
      };
    case "GET_ADVERTISEMENT_FAILURE":
      openNotificationWithIcon("error", payload.data.message);
      return {
        ...state,
        isLoading: false,
      };
    case "ADD_ADVERTISEMENT_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "ADD_ADVERTISEMENT_SUCCESS":
      openNotificationWithIcon("success", payload.message);
      return {
        ...state,
        isLoading: false,
        isAdvertisementChanged: state.isAdvertisementChanged ? false : true,
      };
    case "ADD_ADVERTISEMENT_FAILURE":
      openNotificationWithIcon("error", payload.data.message);
      return {
        ...state,
        isLoading: false,
      };
    case "GET_SINGLE_AD_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_SINGLE_AD_SUCCESS":
      return {
        ...state,
        isLoading: false,
        advertisement: payload,
        isAdvertisementChanged: "Lax",
      };
    case "GET_SINGLE_AD_FAILURE":
      openNotificationWithIcon("error", payload.data.message);
      return {
        ...state,
        isLoading: false,
      };
    case "UPDATE_ADVERTISEMENT_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "UPDATE_ADVERTISEMENT_SUCCESS":
      openNotificationWithIcon("success", payload.message);
      return {
        ...state,
        isLoading: false,
        isAdvertisementChanged: state.isAdvertisementChanged ? false : true,
      };
    case "UPDATE_ADVERTISEMENT_FAILURE":
      openNotificationWithIcon("error", payload.data.message);
      return {
        ...state,
        isLoading: false,
      };
    case "DISABLE_AD_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "DISABLE_AD_SUCCESS":
      openNotificationWithIcon("success", payload.message);
      return {
        ...state,
        isLoading: false,
        isAdvertisementChanged: state.isAdvertisementChanged ? false : true,
      };
    case "DISABLE_AD_FAILURE":
      openNotificationWithIcon("error", payload.data.message);
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
