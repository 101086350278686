import React, { useEffect, useState } from "react";
import Breadcrump from "../../Components/Breadcrump/Breadcrump";
import "./ClassManagement.css";
import { Table, Popconfirm } from "antd";
import Sidebar from "../../Components/Sidebar/Sidebar";
import { connect, useDispatch } from "react-redux";
import { Tag, Spin } from "antd";
import { AiOutlineEdit } from "react-icons/ai";
import { AiOutlineDelete, AiOutlineEye } from "react-icons/ai";
import history from "../../Helpers/history";
import { useNavigate } from "react-router-dom";
import { Drawer, Button, Modal } from "antd";
import EditClass from "./EditClass";
import { AiFillPlusCircle } from "react-icons/ai";
import { AiTwotonePlusCircle } from "react-icons/ai";
import { Form, Input, Checkbox, Pagination } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { MdOutlineSubject } from "react-icons/md";
import { HiOutlineAcademicCap } from "react-icons/hi";
import { Select, Tooltip } from "antd";
import { MdDisabledVisible } from "react-icons/md";

import TableSkeleton from "../../Components/Table Skeleton/TableSkeleton";
import { PlusOutlined } from "@ant-design/icons";
import Navbar from "../../Components/Navbar/Navbar";
import SiderDemo from "../Layout/SiderDemo";
import moment from "moment";

const { Search } = Input;
const { Option } = Select;

const ClassManagement = ({ Class }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [classId, setClassId] = useState();
  const [form] = Form.useForm();
  const [searchValue, setSearchValue] = useState("");
  const [visible, setVisible] = useState(false);
  const [size, setSize] = useState("large");
  const [isModalVisible, setIsModalVisible] = useState(false);

  const onFinish = (values) => {
    console.log("Received values of form: ", values);
    dispatch({
      type: "ADD_CLASS_REQUEST",
      payload: values,
    });
    setIsModalVisible(false);
    form.resetFields();
  };
  const enableDisableClassHandler = (id) => {
    dispatch({
      type: "ENABLE_DISABLE_CLASS_REQUEST",
      payload: {
        id: id,
      },
    });
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onClose = () => {
    setVisible(false);
  };

  useEffect(() => {
    dispatch({
      type: "GET_CLASSES_REQUEST",
      payload: {
        page: 1,
        pageSize: 10,
      },
    });
  }, [Class.isClassChanged]);

  const deleteClassHandler = (id) => {
    dispatch({
      type: "DELETE_CLASS_REQUEST",
      payload: {
        id: id,
      },
    });
  };
  const detailClickHandler = (id, name) => {
    navigate(`/class-detail/${id}`, { state: name });
  };

  function handleChange(value) {
    console.log(`selected ${value}`);
  }

  const columns = [
    {
      title: "Name",
      dataIndex: "classStandard",

      width: 300,
      align: "center",
    },

    {
      title: "Class Type",
      dataIndex: "classType",

      width: 250,
      align: "center",
    },
    {
      title: "Active",
      dataIndex: "disabled",

      width: 150,
      align: "center",
    },
    {
      title: "Added By",
      dataIndex: "addedAdminName",

      width: 250,
      align: "center",
    },

    {
      title: "Added Date",
      dataIndex: "addedDate",

      width: 250,
      align: "center",
    },
    {
      title: "Actions",
      dataIndex: "actions",

      align: "center",
      width: 450,
    },
  ];

  const data =
    Class.classes.data &&
    Class.classes.data.map((cl) => {
      return {
        classStandard: cl.classStandard,
        classType: cl.classType,
        disabled: cl.disabled ? (
          <Tag color="red">Not Active</Tag>
        ) : (
          <Tag color="green">Active</Tag>
        ),
        addedAdminName: cl.addedAdminName,
        addedDate: moment(cl.addedDate).format("YYYY-MM-DD"),
        actions: cl.actions,
        subjectCategory: 1,
        actions: (
          <div className="action">
            {" "}
            <Tooltip title="Edit Class">
              <AiOutlineEdit
                className="icon"
                onClick={() => {
                  setVisible(true);
                  setClassId(cl.subjectClassId);
                }}
              />
            </Tooltip>
            <Tooltip title="Delete Class">
              {" "}
              <Popconfirm
                title="Are you sure want to delete?"
                onConfirm={() => deleteClassHandler(cl.subjectClassId)}
              >
                <AiOutlineDelete className="icon" />
              </Popconfirm>
            </Tooltip>
            <Tooltip title="See Details">
              <AiOutlineEye
                className="icon"
                onClick={() => {
                  detailClickHandler(cl.subjectClassId, cl.classStandard);
                }}
              />
            </Tooltip>
            {cl.disabled ? (
              <Tooltip title="Enable Class">
                <MdDisabledVisible
                  className="icon"
                  onClick={() => {
                    enableDisableClassHandler(cl.subjectClassId);
                  }}
                />
              </Tooltip>
            ) : (
              <Tooltip title="Disable Class">
                <MdDisabledVisible
                  className="icon"
                  onClick={() => {
                    enableDisableClassHandler(cl.subjectClassId);
                  }}
                />
              </Tooltip>
            )}
          </div>
        ),
      };
    });
  function onShowSizeChange(current, pageSize) {
    window.scrollTo(0, 0);
    dispatch({
      type: "GET_CLASSES_REQUEST",
      payload: {
        page: current,
        pageSize: pageSize,
      },
    });
  }
  const onSearch = (e) => {
    dispatch({
      type: "SEARCH_CLASSES_REQUEST",
      payload: {
        pageNo: 1,
        pageSize: 10,
        keyword: e,
      },
    });
  };
  return (
    <>
      <div className="container">
        <Modal
          title="New Class"
          visible={isModalVisible}
          onCancel={handleCancel}
          footer={null}
        >
          <Spin spinning={Class.isLoading}>
            <Form
              name="form"
              form={form}
              className="login-form"
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
            >
              <Form.Item
                label="Class Standard"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
                name="classStandard"
                rules={[
                  {
                    required: true,
                    message: "Please input class name!",
                  },
                ]}
              >
                <Input placeholder="Class Name" />
              </Form.Item>
              <Form.Item
                label="Class Type"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
                name="classType"
                rules={[
                  {
                    required: true,
                    message: "Please input class type!",
                  },
                ]}
              >
                <Select placeholder="Select Class Type">
                  <Option value="Academic">Academic</Option>
                  <Option value="Non_Academic">Non-Academic</Option>
                </Select>
              </Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{ margin: "auto", width: "100%" }}
                className="login-form-button"
              >
                Add
              </Button>
            </Form>
          </Spin>
        </Modal>

        <SiderDemo>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: "2rem",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {" "}
                <h2 className="title-course">Courses</h2>
                <Button
                  type="primary"
                  style={{ background: "#FF4D4F" }}
                  danger
                  onClick={showModal}
                >
                  Add
                </Button>
              </div>
              <Search
                className="s"
                style={{ width: "35%", borderRadius: "10px" }}
                placeholder="Search for courses."
                allowClear
                size="large"
                onChange={(e) => onSearch(e.target.value)}
              />
            </div>

            {/* <nav class="menu"></nav> */}
            {Class.isLoading ? (
              <TableSkeleton />
            ) : (
              <Table
                pagination={false}
                columns={columns}
                dataSource={data}
                bordered
                className="table-course"
              />
            )}
            {Class.classes.totalPage && (
              <Pagination
                style={{
                  marginTop: "1rem",
                  marginBottom: "1rem",
                  alignSelf: "flex-end",
                }}
                showSizeChanger
                onShowSizeChange={onShowSizeChange}
                defaultCurrent={1}
                onChange={onShowSizeChange}
                total={Class.classes.totalPage * 10}
              />
            )}

            <Drawer
              title={<span style={{}}>Edit Class</span>}
              placement="right"
              width={"85%"}
              size={size}
              onClose={onClose}
              visible={visible}
            >
              <EditClass id={classId} onClose={onClose} />
            </Drawer>
          </div>
        </SiderDemo>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    Class: state.Class,
  };
};

export default connect(mapStateToProps, {})(ClassManagement);
