import React, { useEffect, useState } from "react";
import "../Classes Management/ClassManagement.css";
import { Table } from "antd";
import { connect, useDispatch } from "react-redux";
import { Tag } from "antd";
import { Pagination, Segmented } from "antd";
import { Input } from "antd";
import { Tooltip } from "antd";
import { MdDisabledVisible } from "react-icons/md";
import TableSkeleton from "../../Components/Table Skeleton/TableSkeleton";
import SiderDemo from "../Layout/SiderDemo";
const { Search } = Input;

const ClientManagement = ({ Client }) => {
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState("");
  const [userType, setUserType] = useState("Both");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const DisableClientHandler = (id) => {
    dispatch({
      type: "DISABLE_CLIENT_REQUEST",
      payload: {
        id: id,
      },
    });
  };

  const onClientChangeHandler = (e) => {
    dispatch({
      type: "GET_CLIENT_REQUEST",
      payload: {
        pageNo: 1,
        pageSize: 10,
        type: e.toUpperCase(),
      },
    });
  };
  const onSearch = (e) => {
    dispatch({
      type: "SEARCH_CLIENT_REQUEST",
      payload: {
        pageNo: 1,
        pageSize: 10,
        keyword: e,
        type: userType.toUpperCase(),
      },
    });
  };
  useEffect(() => {
    dispatch({
      type: "GET_CLIENT_REQUEST",
      payload: {
        pageNo: page,
        pageSize: pageSize,
        type: "BOTH",
      },
    });
  }, [Client.isClientChanged]);

  const columns = [
    {
      title: "Full Name",
      dataIndex: "fullName",

      width: 300,
      align: "center",
    },

    {
      title: "Client Type",
      dataIndex: "userType",

      width: 250,
      align: "center",
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",

      width: 150,
      align: "center",
    },
    {
      title: "Location",
      dataIndex: "location",

      width: 350,
      align: "center",
    },

    {
      title: "Loyalty Points",
      dataIndex: "loyaltyPoints",

      width: 250,
      align: "center",
    },
    {
      title: "Active",
      dataIndex: "disabled",

      width: 250,
      align: "center",
    },
    // {
    //   title: "Socket ID",
    //   dataIndex: "socketId",
    //
    //   width: 450,
    //   align: "center",
    // },
    {
      title: "Email ID",
      dataIndex: "emailId",

      width: 100,
      align: "center",
    },
    {
      title: "Referral Code",
      dataIndex: "referralCode",

      width: 250,
      align: "center",
    },

    {
      title: "Actions",
      dataIndex: "actions",

      align: "center",
      width: 250,
    },
  ];

  const data =
    Client.clients.data &&
    Client.clients.data.map((el) => {
      return {
        fullName: el.fullName,
        userType: el.userType,
        phoneNumber: el.phoneNumber,
        location: el.location,
        gender: el.gender,
        loyaltyPoints: el.loyaltyPoints,
        socketId: el.socketId,
        emailId: el.emailId,
        referralCode: el.referralCode,
        disabled: el.disabled ? (
          <Tag color="red">Not Active</Tag>
        ) : (
          <Tag color="green">Active</Tag>
        ),
        actions: (
          <div className="action">
            {" "}
            {el.disabled ? (
              <Tooltip title="Enable Client">
                <MdDisabledVisible
                  className="icon"
                  onClick={() => {
                    DisableClientHandler(el.clientId);
                  }}
                />
              </Tooltip>
            ) : (
              <Tooltip title="Disable Client">
                <MdDisabledVisible
                  className="icon"
                  onClick={() => {
                    DisableClientHandler(el.clientId);
                  }}
                />
              </Tooltip>
            )}
          </div>
        ),
      };
    });

  function onShowSizeChange(current, pageSize) {
    window.scrollTo(0, 0);
    setPage(current);
    setPageSize(pageSize);
    dispatch({
      type: "GET_CLIENT_REQUEST",
      payload: {
        pageNo: current,
        pageSize: pageSize,
        type: userType.toUpperCase(),
      },
    });
  }

  return (
    <>
      <div className="container">
        <SiderDemo>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: "2rem",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {" "}
                <h2 className="title-course">Client Management</h2>
                <Segmented
                  style={{ height: "30px", fontSize: "11px" }}
                  options={["Both", "Student", "Teacher"]}
                  onChange={(e) => {
                    setUserType(e);
                    onClientChangeHandler(e);
                  }}
                />
              </div>
              <Search
                className="s"
                style={{ width: "35%", borderRadius: "10px" }}
                placeholder="Search for clients."
                allowClear
                size="large"
                value={searchValue}
                onChange={(e) => {
                  onSearch(e.target.value), setSearchValue(e.target.value);
                }}
              />
            </div>
            {Client.isLoading ? (
              <TableSkeleton />
            ) : (
              <Table
                columns={columns}
                dataSource={data}
                pagination={false}
                bordered
                className="table-course"
              />
            )}
            {Client.clients.totalPage && (
              <Pagination
                style={{
                  marginTop: "1rem",
                  marginBottom: "1rem",
                  alignSelf: "flex-end",
                }}
                showSizeChanger
                onShowSizeChange={onShowSizeChange}
                onChange={onShowSizeChange}
                total={Client.clients.totalPage * 10}
              />
            )}
          </div>
        </SiderDemo>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    Client: state.Client,
  };
};

export default connect(mapStateToProps, {})(ClientManagement);
