import React, { useEffect, useState } from "react";
import "../Advertisement/Advertisement.css";
import Breadcrump from "../../Components/Breadcrump/Breadcrump";
import "../Classes Management/ClassManagement.css";
import {
  Table,
  Popconfirm,
  Form,
  Input,
  Button,
  Spin,
  Card,
  Segmented,
  Pagination,
} from "antd";
import Sidebar from "../../Components/Sidebar/Sidebar";
import { connect, useDispatch } from "react-redux";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Navbar from "../../Components/Navbar/Navbar";
import SiderDemo from "../Layout/SiderDemo";
import { List, Typography, Divider } from "antd";
import { Link } from "react-router-dom";
import "./ExamSubject.css";
import { GrNext } from "react-icons/gr";

const { TextArea } = Input;

const { Search } = Input;
const ExamManagement = ({ TermsAndPrivacy, Exam, Class }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  console.log(TermsAndPrivacy.termsAndCondition.termsAndCondition);
  const [value, setValue] = useState(
    TermsAndPrivacy.termsAndCondition.termsAndCondition
  );

  useEffect(() => {
    dispatch({
      type: "GET_CLASSES_REQUEST",
      payload: {
        page: page,
        pageSize: pageSize,
      },
    });
  }, [Class.isClassChanged, Exam.isExamChanged]);

  const onFinish = (values) => {
    console.log("Recieved Values of form", values);
    dispatch({
      type: "ADD_MASS_NOTIFICATION_REQUEST",
      payload: values,
    });
    form.resetFields();
  };
  const updateTermsHandler = () => {
    dispatch({
      type: "ADD_PRIVACY_TERMS_REQUEST",
      payload: {
        text: value,
        textFor: "TERMS",
      },
    });
  };

  const data =
    Class.classes.data &&
    Class.classes.data.map((classItem) => {
      return { cl: classItem.classStandard, id: classItem.subjectClassId };
    });
  function onShowSizeChange(current, pageSize) {
    window.scrollTo(0, 0);
    dispatch({
      type: "GET_CLASSES_REQUEST",
      payload: {
        page: current,
        pageSize: pageSize,
      },
    });
  }

  const onSearch = (e) => {
    dispatch({
      type: "SEARCH_CLASSES_REQUEST",
      payload: {
        pageNo: 1,
        pageSize: 10,
        keyword: e,
      },
    });
  };

  console.log(value);
  return (
    <>
      <div className="container">
        {" "}
        <SiderDemo>
          <Segmented
            style={{ height: "0px", fontSize: "11px", display: "none" }}
          />
          <div>
            <Card
              className="card"
              style={{ width: "100%", padding: "5px" }}
              loading={TermsAndPrivacy.isLoading}
            >
              <div
                style={{
                  background: "#5D3BED",
                  height: "50px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  paddingLeft: "20px",
                  color: "white",
                  fontWeight: "normal",
                }}
              >
                Exam Management
              </div>
              <div
                style={{
                  display: "flex",
                  margin: "1rem",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <span style={{ fontSize: "16px" }}>Please Select Class</span>
                <Search
                  style={{ width: "35%", borderRadius: "10px" }}
                  placeholder="Search for classes."
                  allowClear
                  size="large"
                  onChange={(e) => onSearch(e.target.value)}
                />
              </div>
              <div className="class-card-container">
                {" "}
                {data &&
                  data.map((item) => {
                    return (
                      <Card
                        className="start-exam"
                        style={{
                          display: "flex",
                          marginBottom: "1rem",
                          justifyContent: "space-between",
                          width: "48%",
                          position: "relative",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                            height: "100%",
                            alignItems: "center",
                          }}
                        >
                          {" "}
                          {item.cl}{" "}
                          <Link to={`/exam-management/${item.id}`}>
                            <Button style={{ border: "1px solid #FF4D50" }}>
                              Continue{" "}
                            </Button>
                          </Link>
                        </div>
                      </Card>
                    );
                  })}
              </div>

              {/* <ReactQuill
                style={{ background: "white" }}
                modules={{
                  toolbar: {
                    container: [
                      [{ header: [1, 2, 3, 4, 5, 6, false] }],
                      ["bold", "italic", "underline"],
                      [{ list: "ordered" }, { list: "bullet" }],
                      [{ align: [] }],
                      ["link", "image"],
                      ["clean"],
                      [{ color: [] }],
                    ],
                  },
                }}
                theme="snow"
                value={value ? value : ""}
                onChange={(e) => {
                  setValue(e);
                }}
              /> */}
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {" "}
                {/* <Button
                  type="primary"
                  htmlType="submit"
                  onClick={updateTermsHandler}
                  style={{ width: "30%", marginTop: "0.5rem" }}
                  className="login-form-button "
                >
                  Update Terms & Condition
                </Button> */}
              </div>
            </Card>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              {Class.classes.totalPage && (
                <Pagination
                  style={{
                    marginTop: "1rem",
                    marginBottom: "1rem",
                    alignSelf: "flex-end",
                  }}
                  showSizeChanger
                  onShowSizeChange={onShowSizeChange}
                  defaultCurrent={1}
                  onChange={onShowSizeChange}
                  total={Class.classes.totalPage * 10}
                />
              )}
            </div>
          </div>
        </SiderDemo>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    MassNotification: state.MassNotification,
    TermsAndPrivacy: state.TermsAndPrivacy,
    Exam: state.Exam,
    Class: state.Class,
  };
};

export default connect(mapStateToProps, {})(ExamManagement);
