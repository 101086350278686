import { openNotificationWithIcon } from "../../Components/Notification/Success";

const initialState = {
  commissions: [],
  isLoading: false,
  isCommissionChanged: false,
  commission: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case "GET_COMMISSION_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_COMMISSION_SUCCESS":
      //   openNotificationWithIcon("success", payload.message);
      return {
        ...state,
        isLoading: false,
        commissions: payload,
      };
    case "GET_COMMISSION_FAILURE":
      openNotificationWithIcon("error", payload.data.message);
      return {
        ...state,
        isLoading: false,
      };

    case "ADD_COMMISSION_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "ADD_COMMISSION_SUCCESS":
      openNotificationWithIcon("success", payload.message);
      return {
        ...state,
        isLoading: false,
        isCommissionChanged: state.isCommissionChanged ? false : true,
      };
    case "ADD_COMMISSION_FAILURE":
      openNotificationWithIcon("error", payload.data.message);
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
}
