import React, { useEffect, useState } from "react";
import Breadcrump from "../../Components/Breadcrump/Breadcrump";
import "../Classes Management/ClassManagement.css";
import { Table, Popconfirm } from "antd";
import Sidebar from "../../Components/Sidebar/Sidebar";
import { connect, useDispatch } from "react-redux";
import { Tag, Spin } from "antd";
import { AiOutlineEdit } from "react-icons/ai";
import { AiOutlineDelete, AiOutlineEye } from "react-icons/ai";
import history from "../../Helpers/history";
import { useNavigate } from "react-router-dom";
import { Drawer, Button, Modal } from "antd";
import { AiFillPlusCircle } from "react-icons/ai";
import { AiTwotonePlusCircle } from "react-icons/ai";
import { Form, Input, Checkbox, Pagination, Segmented } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { MdOutlineSubject } from "react-icons/md";
import { HiOutlineAcademicCap } from "react-icons/hi";
import { Select, Tooltip } from "antd";
import { MdDisabledVisible } from "react-icons/md";

import TableSkeleton from "../../Components/Table Skeleton/TableSkeleton";
import { PlusOutlined } from "@ant-design/icons";
import Navbar from "../../Components/Navbar/Navbar";
import SiderDemo from "../Layout/SiderDemo";

const { Search } = Input;
const { Option } = Select;

const TutoringManagement = ({ Class, Tutoring }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [status, setStatus] = useState("All");
  const [classId, setClassId] = useState();
  const [form] = Form.useForm();
  const [searchValue, setSearchValue] = useState("");
  const [visible, setVisible] = useState(false);
  const [size, setSize] = useState("large");
  const [isModalVisible, setIsModalVisible] = useState(false);

  const onFinish = (values) => {
    console.log("Received values of form: ", values);
    dispatch({
      type: "ADD_CLASS_REQUEST",
      payload: values,
    });
    setIsModalVisible(false);
    form.resetFields();
  };
  const enableDisableClassHandler = (id) => {
    dispatch({
      type: "ENABLE_DISABLE_CLASS_REQUEST",
      payload: {
        id: id,
      },
    });
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onClose = () => {
    setVisible(false);
  };

  useEffect(() => {
    dispatch({
      type: "GET_TUTORING_REQUEST",
      payload: {
        page: 1,
        pageSize: 10,
        status: status,
      },
    });
  }, []);

  const deleteClassHandler = (id) => {
    dispatch({
      type: "DELETE_CLASS_REQUEST",
      payload: {
        id: id,
      },
    });
  };

  function handleChange(value) {
    console.log(`selected ${value}`);
  }

  const columns = [
    {
      title: "Subject Name",
      dataIndex: "subjectName",
      width: 250,
      align: "center",
    },
    {
      title: "Student Name",
      dataIndex: "studentName",

      width: 300,
      align: "center",
    },

    {
      title: "Teacher Name",
      dataIndex: "teacherName",
      width: 250,
      align: "center",
    },

    {
      title: "Start Date",
      dataIndex: "startDate",
      width: 250,
      align: "center",
    },

    {
      title: "Status",
      dataIndex: "tutoringStatus",
      width: 250,
      align: "center",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      align: "center",
      width: 350,
    },
  ];

  const data =
    Tutoring.tutorings.data &&
    Tutoring.tutorings.data.map((tut) => {
      return {
        subjectName: tut.subjectName,
        studentName: tut.studentName,
        teacherName: tut.teacherName,
        startDate: tut.startDate,
        tutoringStatus:
          tut.tutoringStatus == "PENDING" ? (
            <Tag color="red">Pending</Tag>
          ) : (
            <Tag color="green">Approved</Tag>
          ),
        actions: (
          <div className="action">
            {" "}
            <Tooltip title="See Details">
              <AiOutlineEye
                className="icon"
                onClick={() => {
                  detailClickHandler(tut.tutoringId);
                }}
              />
            </Tooltip>
          </div>
        ),
      };
    });
  function onShowSizeChange(current, pageSize) {
    window.scrollTo(0, 0);
    dispatch({
      type: "GET_TUTORING_REQUEST",
      payload: {
        page: current,
        pageSize: pageSize,
        status: status.toUpperCase(),
      },
    });
  }

  const onStatusChangeHandler = (e) => {
    dispatch({
      type: "GET_TUTORING_REQUEST",
      payload: {
        page: 1,
        pageSize: 10,
        status: e.toUpperCase(),
      },
    });
  };
  const detailClickHandler = (id) => {
    navigate(`/tutoring-detail/${id}`);
  };

  return (
    <>
      <div className="container">
        <Modal
          title="New Class"
          visible={isModalVisible}
          onCancel={handleCancel}
          footer={null}
        >
          <Spin spinning={Class.isLoading}>
            <Form
              name="form"
              form={form}
              className="login-form"
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
            >
              <Form.Item
                label="Class Standard"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
                name="classStandard"
                rules={[
                  {
                    required: true,
                    message: "Please input class name!",
                  },
                ]}
              >
                <Input placeholder="Class Name" />
              </Form.Item>
              <Form.Item
                label="Class Type"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
                name="classType"
                rules={[
                  {
                    required: true,
                    message: "Please input class type!",
                  },
                ]}
              >
                <Select placeholder="Select Class Type">
                  <Option value="Academic">Academic</Option>
                  <Option value="Non_Academic">Non-Academic</Option>
                </Select>
              </Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{ margin: "auto", width: "100%" }}
                className="login-form-button"
              >
                Add
              </Button>
            </Form>
          </Spin>
        </Modal>
        <SiderDemo>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: "2rem",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {" "}
                <h2 className="title-course">Tutoring Management</h2>
                <Segmented
                  style={{ height: "30px", fontSize: "11px" }}
                  options={[
                    "All",
                    "Pending",
                    "Approved",
                    "Completed",
                    "Declined",
                  ]}
                  onChange={(e) => {
                    setStatus(e);
                    onStatusChangeHandler(e);
                  }}
                />
              </div>
            </div>

            {/* <nav class="menu"></nav> */}
            {Tutoring.isLoading ? (
              <TableSkeleton />
            ) : (
              <Table
                pagination={false}
                columns={columns}
                dataSource={data}
                bordered
                scroll={true}
                className="table-course"
              />
            )}
            {Tutoring.tutorings.totalPage && (
              <Pagination
                style={{
                  marginTop: "1rem",
                  marginBottom: "1rem",
                  alignSelf: "flex-end",
                }}
                showSizeChanger
                onShowSizeChange={onShowSizeChange}
                defaultCurrent={1}
                onChange={onShowSizeChange}
                total={Tutoring.tutorings.totalPage * 10}
              />
            )}
          </div>
        </SiderDemo>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    Class: state.Class,
    Tutoring: state.Tutoring,
  };
};

export default connect(mapStateToProps, {})(TutoringManagement);
