import { openNotificationWithIcon } from "../../Components/Notification/Success";

const initialState = {
  subjectLists: [],
  isLoading: false,
  isResourceChanged: false,
  directories: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case "GET_SUBJECT_LIST_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_SUBJECT_LIST_SUCCESS":
      //   openNotificationWithIcon("success", payload.message);
      return {
        ...state,
        isLoading: false,
        subjectLists: payload,
      };
    case "GET_SUBJECT_LIST_FAILURE":
      //   openNotificationWithIcon("error", payload.data.message);
      return {
        ...state,
        isLoading: false,
      };
    case "GET_DIRECTORY_LIST_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_DIRECTORY_LIST_SUCCESS":
      //   openNotificationWithIcon("success", payload.message);
      return {
        ...state,
        isLoading: false,
        directories: payload,
      };
    case "GET_DIRECTORY_LIST_FAILURE":
      //   openNotificationWithIcon("error", payload.data.message);
      return {
        ...state,
        isLoading: false,
      };
    case "UPLOAD_RESOURCE_FILE_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "UPLOAD_RESOURCE_FILE_SUCCESS":
      openNotificationWithIcon("success", payload.message);
      return {
        ...state,
        isLoading: false,
        isResourceChanged: state.isResourceChanged ? false : true,
      };
    case "UPLOAD_RESOURCE_FILE_FAILURE":
      openNotificationWithIcon("error", payload.data.message);
      return {
        ...state,
        isLoading: false,
      };

    case "ADD_DIRECTORY_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "ADD_DIRECTORY_SUCCESS":
      openNotificationWithIcon("success", payload.message);
      return {
        ...state,
        isLoading: false,
        isResourceChanged: state.isResourceChanged ? false : true,
      };
    case "ADD_DIRECTORY_FAILURE":
      openNotificationWithIcon("error", payload.data.message);
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
}
