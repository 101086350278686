import { useLocation, useSearchParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "../Advertisement/Advertisement.css";
import Breadcrump from "../../Components/Breadcrump/Breadcrump";
import "../Classes Management/ClassManagement.css";
import {
  Table,
  Popconfirm,
  Form,
  Input,
  Button,
  Spin,
  Card,
  Tooltip,
  Modal,
  Radio,
} from "antd";
import Sidebar from "../../Components/Sidebar/Sidebar";
import { connect, useDispatch } from "react-redux";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Space } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import SiderDemo from "../Layout/SiderDemo";
import { openNotificationWithIcon } from "../../Components/Notification/Success";

const { TextArea } = Input;

const EditQuestion = ({ TermsAndPrivacy, Exam, Class, data }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const subjectId = searchParams.get("subjectId");
  const setId = searchParams.get("setId");
  const subSubjectId = searchParams.get("subSubjectId");
  const [value, setValue] = useState(data.examQuestionResponse.question);
  const [options, setOptions] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [checkedValue, setCheckedValue] = useState(null);
  const [description, setDescription] = useState("");
  const [answers, setAnswers] = useState(
    data.examQuestionResponse.examAnswerResponses
  );

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onFinish = (values) => {
    if (!value) {
      return openNotificationWithIcon("error", "Please add question !!");
    }

    const newOptions = values.examAnswers?.map((answer) => {
      return { ...answer, isCorrect: false };
    });
    console.log(newOptions);
    setOptions(newOptions);

    // if (newOptions == undefined || newOptions?.length < 2) {
    //   openNotificationWithIcon("error", "Please add atleast two options");

    //   return;
    // }
    // if (!value) {
    //   openNotificationWithIcon("error", "Please add question");
    //   return;
    // }

    setTimeout(() => {
      setIsModalVisible(true);
    }, [100]);
  };

  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    setCheckedValue(e.target.value);
  };
  const addQuestionHandler = () => {
    if (options == undefined || options?.length < 2) {
      openNotificationWithIcon("error", "Please add atleast two options");
      return;
    }
    if (subjectId) {
      options[checkedValue].isCorrect = true;
      options[checkedValue].correctAnswerDetail = description;
      dispatch({
        type: "ADD_QUESTION_REQUEST",
        payload: {
          subjectCategoryId: subjectId,
          examSetId: setId,
          question: value,
          examAnswers: options,
        },
      });
      setCheckedValue("");
      setDescription("");
      setIsModalVisible(false);
      setValue("");
    } else {
      options[checkedValue].isCorrect = true;
      options[checkedValue].correctAnswerDetail = description;
      dispatch({
        type: "ADD_QUESTION_REQUEST",
        payload: {
          subjectSubCategoryId: subSubjectId,
          examSetId: setId,
          question: value,
          examAnswers: options,
        },
      });
      setCheckedValue("");
      setDescription("");
      setIsModalVisible(false);
      setValue("");
    }
  };

  useEffect(() => {
    setValue(data.examQuestionResponse.question);
    setAnswers(data.examQuestionResponse.examAnswerResponses);
  }, [data]);

  return (
    <>
      <div className="container" style={{ width: "100%" }}>
        <Modal
          title="Select Correct Answer"
          footer={null}
          visible={isModalVisible}
          onCancel={handleCancel}
        >
          <Radio.Group onChange={onChange} value={checkedValue}>
            <Space direction="vertical">
              {options?.map((option, index) => {
                return <Radio value={index}>{option.answer}</Radio>;
              })}
            </Space>
          </Radio.Group>
          <p htmlFor="" style={{ marginTop: "1rem", fontSize: "17px" }}>
            Description:
          </p>
          <TextArea
            rows={3}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          <Button
            onClick={addQuestionHandler}
            type="primary"
            htmlType="submit"
            style={{ width: "30%", marginTop: "1rem" }}
          >
            Add Question
          </Button>
        </Modal>

        <div style={{ width: "100%" }}>
          <Card
            className="card"
            style={{ width: "100%", padding: "5px" }}
            loading={Exam.isLoading}
          >
            <Form
              name="dynamic_form_nest_item"
              onFinish={onFinish}
              autoComplete="off"
              onFinishFailed={() => {
                setIsModalVisible;
              }}
            >
              <Form.Item
                label={
                  <span style={{ fontWeight: "bolder", fontSize: "17px" }}>
                    Question
                  </span>
                }
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  marginTop: "0.5rem",
                }}
              >
                <ReactQuill
                  style={{
                    background: "white",
                    marginTop: "1rem",
                    marginBottom: "2rem",
                    height: "100px",
                  }}
                  modules={{
                    toolbar: {
                      container: [
                        [{ header: [1, 2, 3, 4, 5, 6, false] }],
                        ["bold", "italic", "underline"],
                        [{ list: "ordered" }, { list: "bullet" }],
                        [{ align: [] }],
                        ["link", "image"],
                        ["clean"],
                        [{ color: [] }],
                      ],
                    },
                  }}
                  theme="snow"
                  value={value ? value : ""}
                  onChange={(e) => {
                    setValue(e);
                  }}
                />
              </Form.Item>
              <p
                style={{
                  fontWeight: "bolder",
                  fontSize: "17px",
                  marginTop: "1rem",
                }}
              >
                Add Answers:
              </p>
              <Form.List name="examAnswers" style={{ display: "flex" }}>
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <Space
                        key={key}
                        style={{
                          display: "flex",
                          marginBottom: 8,
                          width: "100%",
                        }}
                        align="baseline"
                      >
                        {console.log(fields)}
                        <Form.Item
                          style={{ width: "100%" }}
                          {...restField}
                          label="Answer"
                          name={[name, "answer"]}
                          rules={[
                            { required: true, message: "Missing answer !" },
                          ]}
                        >
                          <TextArea placeholder="Enter Answer" />
                        </Form.Item>
                        <div style={{ display: "flex", gap: "1rem" }}>
                          {" "}
                          <Tooltip title="Remove">
                            <MinusCircleOutlined onClick={() => remove(name)} />
                          </Tooltip>
                        </div>
                      </Space>
                    ))}
                    <Form.Item>
                      <Button
                        style={{ border: "1px solid #FF4D50", width: "15%" }}
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                      >
                        Add
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {" "}
                <Button
                  onClick={onFinish}
                  type="primary"
                  htmlType="submit"
                  style={{ width: "30%", marginTop: "0.5rem" }}
                  className="login-form-button "
                >
                  Continue
                </Button>
              </div>
            </Form>
          </Card>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    MassNotification: state.MassNotification,
    TermsAndPrivacy: state.TermsAndPrivacy,
    Exam: state.Exam,
    Class: state.Class,
  };
};

export default connect(mapStateToProps, {})(EditQuestion);
