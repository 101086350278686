import React, { useEffect, useState } from "react";
import "../Advertisement/Advertisement.css";
import Breadcrump from "../../Components/Breadcrump/Breadcrump";
import "../Classes Management/ClassManagement.css";
import {
  Table,
  Popconfirm,
  Form,
  Input,
  Button,
  Spin,
  Card,
  Tag,
  Drawer,
} from "antd";
import Sidebar from "../../Components/Sidebar/Sidebar";
import { connect, useDispatch } from "react-redux";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Navbar from "../../Components/Navbar/Navbar";
import "./MyProfile.css";
import UpdateProfile from "./UpdateProfile";
import SiderDemo from "../Layout/SiderDemo";

const { TextArea } = Input;

const MyProfile = ({ profile }) => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [size, setSize] = useState("large");

  useEffect(() => {
    dispatch({
      type: "GET_PROFILE_REQUEST",
    });
  }, [profile.isProfileChanged]);
  const onClose = () => {
    setVisible(false);
  };

  return (
    <>
      <div className="container">
        <Drawer
          title={<span style={{}}>Update Profile</span>}
          placement="right"
          width={"85%"}
          size={size}
          onClose={onClose}
          visible={visible}
        >
          <UpdateProfile data={profile.profile} onClose={onClose} />
        </Drawer>
        <SiderDemo>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Card style={{ width: 450 }} loading={profile.isLoading}>
              <div
                style={{
                  background: "#5D3BED",
                  height: "50px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  paddingLeft: "20px",
                  color: "white",
                  fontWeight: "normal",
                }}
              >
                Admin Profile
              </div>
              <div
                style={{
                  marginTop: "1rem",
                  width: "100%",
                  padding: "0px 20px",
                }}
              >
                <div className="key-value">
                  <p className="key">Full Name:</p>
                  <p className="value">{profile.profile.fullName}</p>
                </div>
                <div className="key-value">
                  <p className="key">Email:</p>
                  <p className="value">{profile.profile.userName}</p>
                </div>{" "}
                <div className="key-value">
                  <p className="key">Phone No:</p>
                  <p className="value">{profile.profile.phoneNo}</p>
                </div>
                <div className="key-value">
                  <p className="key">Date of Birth:</p>
                  <p className="value">{profile.profile.dateOfBirth}</p>
                </div>
                <div className="key-value">
                  <p className="key">Status:</p>
                  <p className="value">
                    {profile.profile.isActive ? (
                      <Tag style={{ background: "green", color: "white" }}>
                        Active
                      </Tag>
                    ) : (
                      <Tag style={{ background: "red", color: "white" }}>
                        Not Active
                      </Tag>
                    )}
                  </p>
                </div>
                <div className="key-value">
                  <p className="key">Admin Role:</p>
                  <p className="value">
                    {profile.profile.adminRole &&
                      profile.profile.adminRole.name}
                  </p>
                </div>
                <div className="key-value">
                  <p className="key">Gender:</p>
                  <p className="value">{profile.profile.gender}</p>
                </div>
                <div className="key-value">
                  <p className="key">User Type:</p>
                  <p className="value">{profile.profile.userType}</p>
                </div>
                <div className="key-value">
                  <p className="key">Registeration Status:</p>
                  <p className="value" style={{ marginLeft: "1rem" }}>
                    <Tag
                      style={{
                        background: "green",
                        color: "white",
                        marginLeft: "1rem",
                      }}
                    >
                      {profile.profile.registrationStatus}
                    </Tag>
                  </p>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                }}
              >
                <Button
                  onClick={() => {
                    setVisible(true);
                  }}
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%", marginTop: "1rem" }}
                  className="login-form-button notification-button"
                >
                  Update
                </Button>
              </div>
            </Card>
          </div>
        </SiderDemo>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    profile: state.profile,
  };
};

export default connect(mapStateToProps, {})(MyProfile);
