import { toast } from "react-toastify";
import { getCookie, setLocalStorage } from "../../Helpers/FrontendHelper";

const initialState = {
  token: null,
  profile: {},
  isRegistered: getCookie("token") ? true : false,
  isLoading: false,
  isOTPSent: false,
  roles: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case "GET_ROLE_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_ROLE_SUCCESS":
      return {
        ...state,
        isLoading: false,
        roles: payload,
      };
    case "GET_ROLE_FAILURE":
      return {
        ...state,
        isLoading: false,
      };

    case "REGISTER_USER_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "OTP_SEND_SUCCESSFULL":
      toast.success(payload.data.message, { toastId: 8 });
      setLocalStorage("email", payload.email);
      return {
        ...state,
        isLoading: false,
        isOTPSent: true,
      };
    case "CLEAR_OTP":
      return {
        ...state,
        isOTPSent: false,
      };
    case "REGISTER_USER_FAILURE":
      toast.error(payload.data.message, { toastId: 5000 });
      return {
        ...state,
        isLoading: false,
        isRegistered: false,
      };
    case "VERIFY_OTP_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "VERIFY_OTP_SUCCESS":
      toast.success(payload.message, { toastId: 10 });
      setTimeout(() => {
        window.location.replace("/login");
      }, [2000]);
      return {
        ...state,
        isLoading: false,
      };
    case "VERIFY_OTP_FAILURE":
      for (const key in payload.data.errors) {
        toast.error(payload.data.errors[key], { toastId: 9 });
      }
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
}
