import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Modal as M, Input, Spin } from "antd";
import { connect } from "react-redux";

const Modal = (props) => {
  const dispatch = useDispatch();
  const [category, setCategory] = useState(props.categoryName);
  const [classId, setClassId] = useState(props.classId);
  const [categoryId, setCategoryId] = useState(props.categoryId);
  const [subjectSubCategory, setSubjectSubCategory] = useState(
    props.subCategory
  );
  const [subCategoryId, setSubCategoryId] = useState(props.subjectCategoryId);
  const handleOk = () => {
    if (subjectSubCategory) {
      dispatch({
        type: "UPDATE_SUB_SUBJECT_REQUEST",
        payload: {
          subjectCategoryId: categoryId,
          categoryName: subjectSubCategory,
          subjectSubCategoryId: subCategoryId,
        },
      });
      props.setIsModalVisible(false);
    } else {
      dispatch({
        type: "UPDATE_SUBJECT_REQUEST",
        payload: {
          subjectCategoryId: categoryId,
          subjectClassId: classId,
          categoryName: category,
        },
      });
      props.setIsModalVisible(false);
    }
  };
  useEffect(() => {
    setCategory(props.categoryName);
    setClassId(props.classId);
    setCategoryId(props.categoryId);
    setSubjectSubCategory(props.subCategory);
    setSubCategoryId(props.subjectCategoryId);
  }, [
    props.categoryName,
    props.classId,
    props.categoryId,
    props.subCategory,
    props.subCategoryId,
    props.subjectCategoryId,
  ]);

  console.log(subCategoryId);

  return (
    <M
      title="Edit"
      okText="Edit"
      visible={props.isModalVisible}
      onOk={handleOk}
      onCancel={props.handleCancel}
    >
      <Spin spinning={props.Class.isLoading}>
        {category ? (
          <Input
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          />
        ) : (
          <Input
            value={subjectSubCategory}
            onChange={(e) => setSubjectSubCategory(e.target.value)}
          />
        )}
      </Spin>
    </M>
  );
};
const mapStateToProps = (state) => {
  return {
    Class: state.Class,
  };
};

export default connect(mapStateToProps, {})(Modal);
