import React, { useState } from "react";
import { Form, Input, Button, Checkbox } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import adminLogo from "../../Assets/Images/admin-logo.png";
import Spinner from "../../Components/Spinner/Spinner";
import "./Login.css";
import { Card, Image, Typography, Spin } from "antd";
import { Link } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { notification } from "antd";
const { Text, Title } = Typography;

const Login = ({ login }) => {
  const dispatch = useDispatch();
  const [userData, setUserData] = useState({
    email: "",
    password: "",
  });
  const { email, password } = userData;

  const onChangeHandler = (e) => {
    setUserData((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };
  const submitHandler = () => {
    if (!email || !password) {
      return;
    }
    dispatch({
      type: "AUTHENTICATE_USER_REQUEST",
      payload: userData,
    });
  };

  return (
    <div className="login-container">
      <Card
        title={<Title level={4}>Admin Login</Title>}
        bordered={true}
        className="card"
        hoverable={true}
      >
        <Spin spinning={login.loading}>
          <div className="image-container">
            <Image
              src={adminLogo}
              width={300}
              className="logo-image"
              mask={false}
              preview={false}
            />
          </div>

          <Form
            onFinish={submitHandler}
            name="normal_login"
            className="login-form"
            initialValues={{
              remember: true,
            }}
          >
            <Form.Item
              name="username"
              rules={[
                {
                  required: true,
                  message: "Please input your Username!",
                },
                {
                  type: "email",
                  message: "Please enter valid email!",
                },
              ]}
            >
              <Input
                className="input"
                style={{ borderRadius: "10px" }}
                name="email"
                size="large"
                value={email}
                onChange={(e) => onChangeHandler(e)}
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Username"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your Password!",
                },
              ]}
            >
              <Input.Password
                name="password"
                style={{ borderRadius: "10px" }}
                value={password}
                onChange={(e) => onChangeHandler(e)}
                size="large"
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Password"
              />
            </Form.Item>
            {/* <Form.Item>
              <Form.Item name="remember" valuePropName="checked" noStyle>
                <Checkbox>Remember me</Checkbox>
              </Form.Item>
            </Form.Item> */}

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                style={{ background: "#5D3BED" }}
              >
                <Title level={5} className="login-text">
                  Login
                </Title>
              </Button>
            </Form.Item>
            {/* <Card bordered={true} size="small">
              <Typography>
                Register new Admin?
                <Link to={"/register-admin"}>Register</Link>{" "}
              </Typography>
            </Card> */}
          </Form>
        </Spin>
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    login: state.login,
  };
};

export default connect(mapStateToProps, {})(Login);
