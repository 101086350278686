import { openNotificationWithIcon } from "../../Components/Notification/Success";

const initialState = {
  clients: [],
  isLoading: false,
  isClientChanged: false,
  client: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case "GET_CLIENT_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_CLIENT_SUCCESS":
      //   openNotificationWithIcon("success", payload.message);
      return {
        ...state,
        isLoading: false,
        clients: payload,
      };
    case "GET_CLIENT_FAILURE":
      openNotificationWithIcon("error", payload.data.message);
      return {
        ...state,
        isLoading: false,
      };

    case "DISABLE_CLIENT_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "DISABLE_CLIENT_SUCCESS":
      openNotificationWithIcon("success", payload.message);
      return {
        ...state,
        isLoading: false,
        isClientChanged: state.isClientChanged ? false : true,
      };
    case "DISABLE_CLIENT_FAILURE":
      openNotificationWithIcon("error", payload.data.message);
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
