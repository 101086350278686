import React, { useEffect, useState } from "react";
import "../Advertisement/Advertisement.css";
import "../Classes Management/ClassManagement.css";
import { Form, Input, Button, Card, Modal, Segmented, Empty } from "antd";
import { connect, useDispatch } from "react-redux";
import SiderDemo from "../Layout/SiderDemo";
import { Link, useParams } from "react-router-dom";
import "../Exam Management/ExamSubject.css";

const DirectoryList = ({ Exam, Class, Resource }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { id } = useParams();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const onFinish = (values) => {
    console.log("Recieved Values of form", values);
    dispatch({
      type: "ADD_DIRECTORY_REQUEST",
      payload: { ...values, parentDirId: id },
    });
    form.resetFields();

    setIsModalVisible(false);
  };

  useEffect(() => {
    dispatch({
      type: "GET_DIRECTORY_LIST_REQUEST",
      payload: {
        id: id,
      },
    });
  }, [
    Class.isClassChanged,
    Exam.isExamChanged,
    Resource.isResourceChanged,
    id,
  ]);

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const showModal = () => {
    setIsModalVisible(true);
  };

  const data =
    Resource.directories &&
    Resource.directories.directoryDetails?.map((directory) => {
      return {
        dirName: directory.dirName,
        status: directory.dirDisabledStatus,
        id: directory.dirId,
      };
    });

  const uploadResourceFileHandler = (e, dirId) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    dispatch({
      type: "UPLOAD_RESOURCE_FILE_REQUEST",
      payload: { formData: formData, dirId: id },
    });
  };

  return (
    <>
      <div className="container">
        <Modal
          title="New Directory"
          visible={isModalVisible}
          footer={null}
          onCancel={handleCancel}
        >
          <Form name="form" form={form} onFinish={onFinish}>
            <Form.Item
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                width: "100%",
              }}
              name="directoryName"
              label="Directory Name"
              rules={[
                { required: true, message: "Please input Directory Name !!" },
              ]}
            >
              <Input placeholder="Directory Name" />
            </Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ margin: "auto", width: "100%" }}
              className="login-form-button"
            >
              Add
            </Button>
          </Form>
        </Modal>

        <SiderDemo>
          <Segmented
            style={{ height: "0px", fontSize: "11px", display: "none" }}
          />
          <div>
            <Card
              className="card"
              style={{ width: "100%", padding: "5px" }}
              loading={Resource.isLoading}
            >
              {" "}
              <div
                style={{
                  background: "#5D3BED",
                  height: "50px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  paddingLeft: "20px",
                  color: "white",
                  fontWeight: "normal",
                  paddingRight: "20px",
                }}
              >
                Directory Lists
                <div
                  style={{ display: "flex", alignItems: "center", gap: "1rem" }}
                >
                  <Button style={{ color: "white" }} onClick={showModal}>
                    Add Directory
                  </Button>
                  <div class="upload-btn-wrapper" style={{ height: "40px" }}>
                    <button class="btn">Upload Resource</button>
                    <input
                      type="file"
                      name="myfile"
                      onChange={(e) => uploadResourceFileHandler(e)}
                    />
                  </div>
                </div>
              </div>
              <div
                grid={{
                  xs: 1,
                  sm: 2,
                  md: 4,
                  lg: 4,
                  xl: 6,
                  xxl: 3,
                }}
                style={{
                  display: "flex",
                  width: "100%",
                  gap: "1rem",
                  marginBottom: "1rem",
                  flexWrap: "wrap",
                }}
              >
                {data?.length > 0 &&
                  data?.map((directory, index) => {
                    return (
                      <Card
                        className="start-exam small-card"
                        style={{
                          background: "white",
                          marginTop: "0.5rem",
                        }}
                      >
                        <Link
                          to={`/directory-list/${directory.id}`}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAABmJLR0QA/wD/AP+gvaeTAAADrklEQVRoge2Xy2oUQRSG/+rbXDLTE0zIQmKiYFCUKIKIoEvFVdz5ACLoQ4jg1qUuFXyB4MaVm+DGlcSFlyCCcZGFEMGowUSc7q7jontS1d2nuntiJgj2gWGGmqm/vr/qnNM1QB111FFHHf9zCNMXm8vXLlqQjwHMlQh8jGBd988uvthzugrBGthcXphEIFYATFXU+WoJnOycf7q+d2jVwuIG5Za8J4NwSgYhKr4mgn745MfzK0f32wB7AhtLl78D6O0zCxdrRPLWxKWlZ6YfONyg7If/AjwAzAB4mLyzwRsIglEB7SYOFX1pMBD+9ap2sw1/bh6u30OcqRS/iAAARJQbA0iNE3a+D9fOEcVjPwnytjd7/8FIDVieh/ETZ2A3WyCKEsA0FAeKGFMzpRmOP3cg6A6AMgO7TCEBeP44/GOnYHkuSAYJaB4qDzrA1MaAnc+azoa+ZPkJCIHe8XmMTc/Ccpy0WCYNdCiSAQ+VO4W0EdYwtJQjelvBgDqBsZkj6MweTkFRKne53asAxekkm1KoQ3hXwYA6gebkJCgaGKKSBdTuGnavmk5ihNeRr0sNUBipH7TbIBmWLAC1QyZQBqpUR0+5RMcWqJBCYXwCwrFht7wkdbgF0gA86E4HyUFV1lEpt92YP/0JWCwzEJ9Aw/dBFCYaRR2EA1VzWFA95fKgrI4ErQhxV5aeACUn4HSS9DEWGQeah8qDluhkUkerq1T6GA0MTsDptnMFbF4gW6wZUGZOqY5eG0QgyKoG4hPwOq1cARdBmUEBMFBFxZoaU+NvKhkYdCG3244LeERQhQ9ERseWlHoGmA1EEeymB8sTmRpQYizoLqBKn9Jqznr3wssvFQ1IuH4HJMOCDsKBIlOYHOgQOpqWIOTyv9CA57eTyxhSQvwCCsAMCm3+kDqx0Vz+FxiI4PaaoCgsWSAPlQdFpgYUlGqlFXREvoUaDQCAO96MT4BdAHyr02sjM8eokzqRAh0r30KNBoQQcMe8uAYKi4wDVbtrBmV04l0x6UThr/77ygacTgOwJEhGxV2FgRrm7s/qJGtlntKrBxc+b1c24PZapX9I9uXun4yRoQMVGGik+v8ooNgHovlaMqSBrpvcgTgxBbC7PyQGHT3lsp2OJNtCjQacjp26A+3lH5JSHb3wkzFpRcOdgOWQJBlYbAfR68DYQTJjesoNey0R+DB9tb86lIHf37YeuQcaNy0LohgUqZ3kQdWcIa8lkgivgOiGEINJddRRRx111JGOPxu6cCKSl0emAAAAAElFTkSuQmCC" />
                          <span style={{ fontSize: "12px" }}>
                            {directory.dirName}
                          </span>
                        </Link>
                      </Card>
                    );
                  })}

                {Resource.directories.fileDetails?.length <= 0 &&
                  data?.length <= 0 && (
                    <Empty
                      style={{ margin: "auto", marginTop: "1rem" }}
                      description="No Directories!"
                    />
                  )}
                {Resource.directories.fileDetails?.map((file) => {
                  return (
                    <Card
                      onClick={() => {
                        window.location.replace(file.fileUrl);
                      }}
                      className="start-exam small-card"
                      style={{
                        background: "white",
                        marginTop: "0.5rem",
                      }}
                    >
                      <div
                        className="top-subject-title"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                        }}
                      >
                        {file.fileName.includes(".pdf") && (
                          <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAABmJLR0QA/wD/AP+gvaeTAAAC0klEQVRoge2ZTUhUURiGnxnHwsZsNLOckhiVodCgv0WhKynKjRCEoRTRJugH3SRZQasW2kaKijQihAiUCtJ2GSaUYEGCVBKWVvQjESakqc3PbfExDDXk6L3neCeYd3O+Odxz3u+Z7957DudCUklZkiPeBca+fEOLs0Gjo3243uo0ThW5zFdGVi44OGlU5jdYncsWgFDtZYzlXiUQtgCQuZJQzSUlEPYAgDII+wBACYS9AGAZwn4AsASRGABgGiJxAMAURGIBQAxEvMtdC5FTjGlNibK5Eq8C81QSwG7pAdhQCk1dcGMAKg5rsYhID8CRRsj1QZobquoga5UWG9ABkO6JJvyqDxxO8BUpt4lIPcD0JIRDEmdkKZ/+b6kHCAbgw2uJVxdIOzaq3CYiPc9A731pHU4IheDzsBYb0AXQ3Q6BGYnfvYSZKS02oAvgx3d40StxKAiOuIcfpqUHwJkCvmKJ/ZthR5UWG9AFULQdPCvkgQaoroecPC1WegBKK6TtbIFnD2RBO90Ky7KVW6nfTi/NhG3lshY8bIOpCVmV1xRCXTO0NYE3H7w+cC2CcBiGnsOTDnlj2Q6w+yAsXgL93fDtE+T5YbBPAAo3wpnW2DE7q6G4BK6cmLedWoA0N+w6ILHbAxcf/XnvBwPgSo3+/jIifXl+qZqtAK5U2FsreyEA/yZpP76RavR3w1A/lB+CPccENtcXHT/41JSt9dNphxPKKiWpbK/0TU1Cbyd03YKRl7Fj0j2wpQzWrpfn4P0gPO6AmZ+x07cNz5qjtQp4C+Doebm3I3o7AOf2C8S/NDEOPXctWUdkHmDdVqi7Bu6MaF8wAM31syevWObWAWcKHG+S5Me/ghGW/tsXojvRBZK5CrhSZaUF8ORI23MH7l1VlNbcZa4Cv6bh+ln598dG4WYDtJwCQ8/XqNlk3zeyOSreWyh5rGK3kgB2678HSCopi/oNdqPUmdYNR/YAAAAASUVORK5CYII=" />
                        )}
                        {file.fileName.includes(".doc") && (
                          <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAABmJLR0QA/wD/AP+gvaeTAAACoUlEQVRoge2YTU8TURSGn2krsYpWJODwZazBUlNCMJpCIhCiG40bXBn/gStimuDGjTG6YEFi4sqf4M7EaOOe0gUhRgmdCFETsBTEmlAhhdIyLlpqP4Z20s5MAedZ3ZycOfd9556TOxkwMTExqSVCpQ92v5x9IMjCOHBaQz2liIHwaHbU8yo3aKm0msHiyew1Xhis2ADGis8gOwoj1Rg4EJgGao1poNbYiiK3Jx4i8ww4WerB2fcfSha22KyILheNF85XJbAcxQZUiFfDbjLFypd5Ghv0NaDUQlWL32M3ldKq1L4c+hk42gaGutuR/T7ePhnJxi53nEX2+5D9Pm72/uvvd0/vIvt9DHW366dWgZIGpudX2N5J0eduycYGcwQOeNqy62uXzrG9k2J6fkUHmftT0kA8kWRmYZUmhx2nmP6Ouu5pJZFMsbYezxpwig6az5xgZmGVeCKpv+ocys7A5FwYAK9LBGDQ08bHrz8JhML0u1uwWS30Z05oL9dIyhoIhJYB8HaJtDbW4xQdBKUIQSlCvf0YvReb8HaJeblGUnyRFRAIhZFl6HO3MJhpmaC0TOT3JpCeA69LRJbTuYUI0U2NJedT1kA0toW0FOVqZzM3ejsAmApFiMbiJJIphns6uNLZjLQUJRrb0lWsEqrugcm5MMfrbNwfdhOObvDj1x/iiSSfvq1xx+vEXmerSf+DSgOBuXRvn7LXMZXT50Epgs1qycsxGtUnsEdQiuSslxVzjKT4t8qtCVnLDXrcA1qW4/OLvjzNR/tb6DCgZGBDs+IW/d+P0j3wGHgO1FdT2CJY5AarY2tjcXG7mjrlqPjfqHPktabDrpbvb+6ZQ3ygMA3Umv/aQEwzFepZLwxUYUAYUyqoI+sIjBm4n4mJiYkK/gKMMsGwKgOREAAAAABJRU5ErkJggg==" />
                        )}
                        {file.fileName.includes(".xls") && (
                          <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAABmJLR0QA/wD/AP+gvaeTAAADMklEQVRoge2YTUgUYRjHf+/szq5b2oeCSmqaZHSoKEKQMiHLQwgViQQFBUF7KCyIOgWV1KGD0alQy5NdIgiCyuzrkFRG9nUohQrFjygqiqgoWefpYJo6s+vOOu4qzf8yw8P7zvv7zzzv+z7zgitXrlwlUspuh/zGYDlQJ5AVy4CpC2LqBkKvUgTbio82jQxr9p9DbazwE5IiW6BubNi2ASDbAZxYlTM2EIuBKSXXQKI17Q147XYIffoeVTvl0VA+L1pAB832ah21TAYyT24sF1QdIlmIgAjy94rAr29fGI4zGBvZZuiq0NADM/GlzsKTEpg0ExZfQNWGg/93HxkeAUMGGPj5nQw9lSTdj+bXAeju7HPUgGkOiEj2ROGH7kNGCEQwQoaj0BENOAUPf+NDz4ynAcfgJ487vAErIJ/HS2tVAx+PNbNnVcVwPGdOBu+qb9BX3UTu3Ewz/CS++bAGrN7871A/1TfPA7BvzVaSfQFEhMNlu/B7dWruNtL1+Z0JXhJjwDptrrff587rx6TNmM3uos0sycxny7JS3n7q5cy9i9bwcTBgWkYj5fyRplpK8lewt7iSdQWFKODA5VP8DvVbwycuhawnbMeHLhparzAnkEJR3lIuPb9Ny9unYeHjkULmjWyc1SbZHxhuqiAyvEDnw2doAR3NNzjUr7yZjhowr0IR4FcvWM72lRto637Fg84XVK4oo7SgMCx8glLIGt7v0anZtB+lFMdv1HPk6llEhNMVhwjofkt4icNGEHEjG7knHCzdwaL0XG51tHLvzVOedLdz7WUL81MzObR+pzV8Ir6AFfzi9DyqSrZiiMGJ5nPDkNXXagkZA1St3caSeQtN8PGYxKYaN+3wOhm3PIiQ86PgBZJ6+h2dxF9P3B3FHFUpESv81CglJgCfqFKi1yl4NYn/AUOyKCWMIEI9SPZE4fUvBmgKNSJrk7p+OGrA9o9qWvnSqPNCeTQ0n4bSvSiPMwcg7y88GsVs+1RC80fXRcHg2/doqHieSownzeeJvrFSg/BqChlQXhsG4qBpfzL3XxrodZwiWil6xobsGxAVJBEmFD1KtGDcx3XlypWriPoDaDYyTud/6fcAAAAASUVORK5CYII=" />
                        )}

                        <span style={{ fontSize: "12px" }}>
                          {file.fileName}
                        </span>
                      </div>
                    </Card>
                  );
                })}
              </div>
            </Card>
          </div>
        </SiderDemo>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    MassNotification: state.MassNotification,
    TermsAndPrivacy: state.TermsAndPrivacy,
    Exam: state.Exam,
    Class: state.Class,
    Resource: state.Resource,
  };
};

export default connect(mapStateToProps, {})(DirectoryList);
