import { openNotificationWithIcon } from "../../Components/Notification/Success";

const initialState = {
  classes: [],
  isLoading: false,
  subjects: [],
  subjectSubCategory: [],
  isClassChanged: false,
  class: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case "GET_CLASSES_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_CLASSES_SUCCESS":
      return {
        ...state,
        isLoading: false,
        classes: payload,
      };
    case "GET_CLASSES_FAILURE":
      return {
        ...state,
        isLoading: false,
      };
    case "GET_CLASS_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_CLASS_SUCCESS":
      return {
        ...state,
        isLoading: false,

        class: payload,
      };
    case "GET_CLASS_FAILURE":
      return {
        ...state,
        isLoading: false,
      };
    case "UPDATE_CLASS_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "UPDATE_CLASS_SUCCESS":
      openNotificationWithIcon("success", payload.message);

      return {
        ...state,
        isLoading: false,

        isClassChanged: state.isClassChanged ? false : true,
      };
    case "UPDATE_CLASS_FAILURE":
      openNotificationWithIcon("error", payload.data.message);
      return {
        ...state,
        isLoading: state.isClassChanged ? false : true,
      };
    case "GET_SUBJECT_CATEGORY_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_SUBJECT_CATEGORY_SUCCESS":
      const sub = payload.map((subject) => {
        return subject.subjectSubCategoryDetails.map(
          (sub) => sub.subjectSubCategoryName
        );
      });

      return {
        ...state,
        subjects: payload,
        isLoading: false,
        subjectSubCategory: sub,
      };
    case "GET_SUBJECT_CATEGORY_FAILURE":
      return {
        ...state,
        isLoading: false,
      };
    case "DELETE_CLASS_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "DELETE_CLASS_SUCCESS":
      return {
        ...state,
        isLoading: false,
        isClassChanged: state.isClassChanged ? false : true,
      };
    case "DELETE_CLASS_FAILURE":
      openNotificationWithIcon("error", payload.data.message);
      return {
        ...state,
        isLoading: false,
        isClassChanged: false,
      };
    case "UPDATE_SUBJECT_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "UPDATE_SUBJECT_FAILURE":
      openNotificationWithIcon("error", payload.data.message);
      return {
        ...state,
        isLoading: false,
      };
    case "UPDATE_SUBJECT_SUCCESS":
      openNotificationWithIcon("success", payload.message);

      return {
        ...state,
        isLoading: false,
        isClassChanged: state.isClassChanged ? false : true,
      };
    case "UPDATE_SUB_SUBJECT_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "UPDATE_SUB_SUBJECT_FAILURE":
      openNotificationWithIcon("error", payload.data.message);
      return {
        ...state,
        isLoading: false,
      };
    case "UPDATE_SUB_SUBJECT_SUCCESS":
      openNotificationWithIcon("success", payload.message);

      return {
        ...state,
        isLoading: false,
        isClassChanged: state.isClassChanged ? false : true,
      };
    case "DELETE_SUBJECT_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "DELETE_SUBJECT_FAILURE":
      openNotificationWithIcon("error", payload.data.message);
      return {
        ...state,
        isLoading: false,
      };
    case "DELETE_SUBJECT_SUCCESS":
      openNotificationWithIcon("success", payload.message);

      return {
        ...state,
        isLoading: false,
        isClassChanged: state.isClassChanged ? false : true,
      };
    case "DELETE_SUB_SUBJECT_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "DELETE_SUB_SUBJECT_FAILURE":
      openNotificationWithIcon("error", payload.data.message);
      return {
        ...state,
        isLoading: false,
      };
    case "DELETE_SUB_SUBJECT_SUCCESS":
      openNotificationWithIcon("success", payload.message);

      return {
        ...state,
        isLoading: false,
        isClassChanged: state.isClassChanged ? false : true,
      };
    case "ADD_CLASS_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "ADD_CLASS_FAILURE":
      openNotificationWithIcon("error", payload.data.message);
      return {
        ...state,
        isLoading: false,
      };
    case "ADD_CLASS_SUCCESS":
      openNotificationWithIcon("success", payload.message);

      return {
        ...state,
        isLoading: false,
        isClassChanged: state.isClassChanged ? false : true,
      };
    case "ADD_SUBJECT_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "ADD_SUBJECT_FAILURE":
      openNotificationWithIcon("error", payload.data.message);
      return {
        ...state,
        isLoading: false,
      };
    case "ADD_SUBJECT_SUCCESS":
      openNotificationWithIcon("success", payload.message);

      return {
        ...state,
        isLoading: false,
        isClassChanged: state.isClassChanged ? false : true,
      };
    case "ADD_SUB_SUBJECT_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "ADD_SUB_SUBJECT_FAILURE":
      openNotificationWithIcon("error", payload.data.message);
      return {
        ...state,
        isLoading: false,
      };
    case "ADD_SUB_SUBJECT_SUCCESS":
      openNotificationWithIcon("success", payload.message);

      return {
        ...state,
        isLoading: false,
        isClassChanged: state.isClassChanged ? false : true,
      };
    case "ENABLE_DISABLE_CLASS_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "ENABLE_DISABLE_CLASS_FAILURE":
      openNotificationWithIcon("error", payload.data.message);
      return {
        ...state,
        isLoading: false,
      };
    case "ENABLE_DISABLE_CLASS_SUCCESS":
      openNotificationWithIcon("success", payload.message);

      return {
        ...state,
        isLoading: false,
        isClassChanged: state.isClassChanged ? false : true,
      };
    default:
      return state;
  }
}
