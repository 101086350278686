import { Input, Collapse } from "antd";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import "../Classes Management/ClassManagement.css";
import { Select } from "antd";
import { List, Tag, Popconfirm, Typography, Spin } from "antd";
import { CaretRightOutlined, PlusOutlined } from "@ant-design/icons";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import { Tooltip, Button } from "antd";
import { Upload, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { Form, Checkbox } from "antd";

const { Option } = Select;
const { Panel } = Collapse;
const { Paragraph } = Typography;

const EditLoyaltyPoints = (props) => {
  const dispatch = useDispatch();
  const [activeData, setActiveData] = useState();
  const [form] = Form.useForm();
  const onFinish = (values) => {
    dispatch({
      type: "UPDATE_REPORT_REASON_REQUEST",
      payload: { ...values, id: props.data.reportReasonId },
    });
    form.resetFields();
    props.onClose();
  };

  useEffect(() => {
    setActiveData(props.data);
  }, [props.data]);
  //   let rateType;
  //   const exactData = {
  //     ...props.commissions.map((com) => {
  //       rateType = com.rateType;
  //       return { rateType: {} };
  //     }),
  //   };

  return (
    <div style={{ width: "50%", margin: "auto" }}>
      <Spin spinning={props.ReportReason.isLoading}>
        <Form
          name="form"
          fields={[
            {
              name: ["reportReason"],
              value: props.data.reportReason,
            },
          ]}
          encType="multipart/form-data"
          form={form}
          className="login-form"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
        >
          <Form.Item
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              width: "100%",
            }}
            label="Report Reason"
            name="reportReason"
            rules={[
              {
                required: true,
                message: "Please input report reason!",
              },
            ]}
          >
            <Input placeholder="Report Reason" />
          </Form.Item>

          <Button
            type="primary"
            htmlType="submit"
            style={{ margin: "auto", width: "100%" }}
            className="login-form-button"
          >
            Update
          </Button>
        </Form>
      </Spin>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    Class: state.Class,
    Advertisement: state.Advertisement,
    LoyaltyPoints: state.LoyaltyPoints,
  };
};

export default connect(mapStateToProps, {})(EditLoyaltyPoints);
