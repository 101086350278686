import React, { useState, useEffect } from "react";
import "antd/dist/antd.css";
import { Layout, Menu } from "antd";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  VideoCameraOutlined,
  UploadOutlined,
  QuestionCircleFilled,
} from "@ant-design/icons";
import adminLogo from "../../Assets/Images/admin-logo.png";
import Dashboard from "../Dashboard/Dashboard";
import "./Layout.css";
import { connect } from "react-redux";
import { Button, Spin, Avatar } from "antd";
import { Divider, Drawer } from "antd";
import {
  LogoutOutlined,
  EllipsisOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import { MdReport, MdReportOff, MdReportProblem } from "react-icons/md";
import { IoIosArrowDown } from "react-icons/io";
import { Dropdown, Space } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { SmileOutlined } from "@ant-design/icons";
import { RiAdminFill, RiLockPasswordFill } from "react-icons/ri";
import adminImage from "../../Assets/Images/g1.jpg";
import { GrUserAdmin } from "react-icons/gr";
import { AiOutlineDashboard } from "react-icons/ai";
import { UsergroupAddOutlined } from "@ant-design/icons";
import { FaChalkboardTeacher } from "react-icons/fa";
import { IoMdSchool } from "react-icons/io";
import { RiAdvertisementFill } from "react-icons/ri";
import { BsCoin } from "react-icons/bs";
import { BsCashStack } from "react-icons/bs";
import { MdLocalOffer } from "react-icons/md";
import { MdNotificationsActive } from "react-icons/md";
import { RiGitRepositoryPrivateFill } from "react-icons/ri";
import { MdPrivacyTip } from "react-icons/md";
import { MdContactSupport } from "react-icons/md";
import { useLocation } from "react-router-dom";
import { SiBookstack } from "react-icons/si";
import { MdPayment } from "react-icons/md";
const { Header, Sider, Content, Footer } = Layout;

const SiderDemo = (props) => {
  const location = useLocation();
  const [current, setCurrent] = useState(
    location.pathname === "/" || location.pathname === ""
      ? "/dashboard"
      : location.pathname
  );
  const [collapsed, setCollapsed] = useState(false);
  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: "GET_PROFILE_REQUEST",
    });
  }, []);

  const logoutHandler = () => {
    dispatch({
      type: "LOGOUT_ADMIN",
    });
  };
  const menu = (
    <div
      style={{
        background: "white",
        padding: "5px 15px",
        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",

        color: "black",
      }}
    >
      <Link to={"/my-profile"}>
        {" "}
        <p style={{ cursor: "pointer" }}>
          <UserOutlined /> Profile
        </p>
      </Link>

      <Link to={"/change-password"}>
        {" "}
        <p style={{ cursor: "pointer" }}>
          <RiLockPasswordFill /> Change Password
        </p>
      </Link>
      <Link to={"/register-admin"}>
        {" "}
        <p style={{ cursor: "pointer" }}>
          <UserAddOutlined /> Register Admin
        </p>
      </Link>
      <Link to={"/admin-list"}>
        {" "}
        <p style={{ cursor: "pointer" }}>
          <RiAdminFill /> Admin List
        </p>
      </Link>
      <Link to={"/roles-and-permission"}>
        {" "}
        <p style={{ cursor: "pointer" }}>
          <GrUserAdmin /> Roles & Permissions
        </p>
      </Link>
      <p onClick={logoutHandler} style={{ cursor: "pointer" }}>
        <LogoutOutlined /> Log out
      </p>
    </div>
  );
  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const { profile } = props;
  useEffect(() => {
    if (location) {
      if (current !== location.pathname) {
        setCurrent(location.pathname);
      }
    }
  }, [location, current]);

  function handleClick(e) {
    setCurrent(e.key);
  }

  return (
    <Layout style={{ display: "flex" }}>
      <Sider
        className="sider"
        width={256}
        trigger={null}
        collapsible
        collapsed={collapsed}
        style={{
          background: "white",
          height: "100vh",

          position: "sticky",
          overflow: "scroll",
          backgroundColor: "#1D1C40",
          overflowX: "hidden",
          top: 0,
          left: 0,
        }}
      >
        <div
          className="logo"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <img
            src={adminLogo}
            alt="logo"
            style={{ height: "60px", margin: "10px 0px" }}
          />
        </div>
        <Divider
          style={{
            margin: "0",
            height: "15px",
            borderTop: "1.5px solid rgb(221, 218, 218)",
          }}
        />
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={["1"]}
          onClick={handleClick}
          selectedKeys={[current]}
          items={[
            {
              key: "/dashboard",
              icon: <AiOutlineDashboard />,
              label: <Link to={"/dashboard"}>Dashboard</Link>,
            },
            {
              key: "/tutoring-management",
              icon: <FaChalkboardTeacher />,
              label: (
                <Link to={"/tutoring-management"}>Tutoring Management</Link>
              ),
            },
            {
              key: "/client-management",
              icon: <UsergroupAddOutlined />,
              label: <Link to={"/client-management"}>Client Management</Link>,
            },
            {
              key: "/classes-management",
              icon: <IoMdSchool />,
              label: <Link to={"/classes-management"}>Course Management</Link>,
            },
            {
              key: "/advertisement",
              icon: <RiAdvertisementFill />,
              label: <Link to={"/advertisement"}>Advertisement</Link>,
            },
            {
              key: "/commission",
              icon: <BsCashStack />,
              label: <Link to={"/commission"}>Commission and Services</Link>,
            },
            {
              key: "/loyalty-points",
              icon: <BsCoin />,
              label: <Link to={"/loyalty-points"}>Loyalty Points</Link>,
            },
            {
              key: "/coupon-code",
              icon: <MdLocalOffer />,
              label: <Link to={"/coupon-code"}>Coupon Code</Link>,
            },
            {
              key: "/mass-notification",
              icon: <MdNotificationsActive />,
              label: (
                <Link to={"/mass-notification"}>Mass Notification System</Link>
              ),
            },
            {
              key: "/terms-and-condition",
              icon: <RiGitRepositoryPrivateFill />,
              label: (
                <Link to={"/terms-and-condition"}>Terms and Condition</Link>
              ),
            },
            {
              key: "/privacy-policy",
              icon: <MdPrivacyTip />,
              label: <Link to={"/privacy-policy"}>Privacy Policy</Link>,
            },
            {
              key: "/customer-support",
              icon: <MdContactSupport />,
              label: <Link to={"/customer-support"}>Customer Support</Link>,
            },
            {
              key: "/exam-management",
              icon: <QuestionCircleFilled />,
              label: <Link to={"/exam-management"}>Exam Management</Link>,
            },
            {
              key: "/resources-management",
              icon: <SiBookstack />,
              label: (
                <Link to={"/resources-management"}>Resources Management</Link>
              ),
            },
            {
              key: "/report-reason",
              icon: <MdReport />,
              label: <Link to={"/report-reason"}>Report Reason</Link>,
            },
            {
              key: "/reports",
              icon: <MdReportProblem />,
              label: <Link to={"/reports"}>Report</Link>,
            },
            {
              key: "/payment",
              icon: <MdPayment />,
              label: <Link to={"/payment"}>Payment List</Link>,
            },
          ]}
        />
      </Sider>
      <Drawer
        title={
          <div
            className="logo"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <img src={adminLogo} alt="logo" style={{ height: "50px" }} />
          </div>
        }
        placement="left"
        onClose={onClose}
        visible={visible}
        width="70vw"
        style={{ display: "flex", justifyContent: "flex-start" }}
      >
        <Menu
          theme="dark"
          mode="inline"
          style={{ marginLeft: "-2rem" }}
          defaultSelectedKeys={["1"]}
          onClick={handleClick}
          selectedKeys={[current]}
          items={[
            {
              key: "/dashboard",
              icon: <AiOutlineDashboard />,
              label: <Link to={"/dashboard"}>Dashboard</Link>,
            },
            {
              key: "/tutoring-management",
              icon: <FaChalkboardTeacher />,
              label: (
                <Link to={"/tutoring-management"}>Tutoring Management</Link>
              ),
            },
            {
              key: "/client-management",
              icon: <UsergroupAddOutlined />,
              label: <Link to={"/client-management"}>Client Management</Link>,
            },
            {
              key: "/classes-management",
              icon: <IoMdSchool />,
              label: <Link to={"/classes-management"}>Course Management</Link>,
            },
            {
              key: "/advertisement",
              icon: <RiAdvertisementFill />,
              label: <Link to={"/advertisement"}>Advertisement</Link>,
            },
            {
              key: "/commission",
              icon: <BsCashStack />,
              label: <Link to={"/commission"}>Commission and Services</Link>,
            },
            {
              key: "/loyalty-points",
              icon: <BsCoin />,
              label: <Link to={"/loyalty-points"}>Loyalty Points</Link>,
            },
            {
              key: "/coupon-code",
              icon: <MdLocalOffer />,
              label: <Link to={"/coupon-code"}>Coupon Code</Link>,
            },
            {
              key: "/mass-notification",
              icon: <MdNotificationsActive />,
              label: (
                <Link to={"/mass-notification"}>Mass Notification System</Link>
              ),
            },
            {
              key: "/terms-and-condition",
              icon: <RiGitRepositoryPrivateFill />,
              label: (
                <Link to={"/terms-and-condition"}>Terms and Condition</Link>
              ),
            },
            {
              key: "/privacy-policy",
              icon: <MdPrivacyTip />,
              label: <Link to={"/privacy-policy"}>Privacy Policy</Link>,
            },
            {
              key: "/customer-support",
              icon: <MdContactSupport />,
              label: <Link to={"/customer-support"}>Customer Support</Link>,
            },
            {
              key: "/exam-management",
              icon: <QuestionCircleFilled />,
              label: <Link to={"/exam-management"}>Exam Management</Link>,
            },
            {
              key: "/resources-management",
              icon: <SiBookstack />,
              label: (
                <Link to={"/resources-management"}>Resources Management</Link>
              ),
            },
            {
              key: "/report-reason",
              icon: <MdReport />,
              label: <Link to={"/report-reason"}>Report Reason</Link>,
            },
            {
              key: "/reports",
              icon: <MdReportOff />,
              label: <Link to={"/reports"}>Report</Link>,
            },
            {
              key: "/payment",
              icon: <MdPayment />,
              label: <Link to={"/payment"}>Payment List</Link>,
            },
          ]}
        />
      </Drawer>
      <Layout className="site-layout" style={{ display: "flex" }}>
        <Header
          className="site-layout-background"
          style={{
            padding: "0 20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            color: "white",
            background: "white",
          }}
        >
          {React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: "trigger",
              onClick: toggleCollapsed,
            }
          )}
          <MenuUnfoldOutlined className="on-mobile" onClick={showDrawer} />
          {profile.isLoading ? (
            <Spin spinning={profile.isLoading}> </Spin>
          ) : (
            <Dropdown overlay={menu}>
              <div
                className=""
                style={{
                  display: "flex",
                  gap: "1rem",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <img
                  src={adminImage}
                  alt=""
                  style={{
                    width: "50px",
                    borderRadius: "50%",
                    border: "2px solid black",
                  }}
                />
                {/* {profile.profile.fullName && profile.profile.fullName.charAt(0)} */}

                <div style={{ color: "black" }}>
                  <span>
                    {profile.profile.fullName && profile.profile.fullName}
                  </span>
                </div>
                <IoIosArrowDown style={{ color: "black" }} />
              </div>
            </Dropdown>
          )}
        </Header>
        <Content
          className="site-layout-background"
          style={{
            padding: 24,
          }}
        >
          {props.children}
        </Content>
        <Footer
          style={{
            display: "flex",
            alignItems: "center",
            background: "white",
            justifyContent: "center",

            margin: "0px 20px 20px 20px",
            height: "70px",
            borderRadius: "10px",
          }}
        >
          <img src={adminLogo} alt="logo" style={{ height: "50px" }} />
          <span style={{ marginTop: "10px" }}>
            {" "}
            Online Tutor ©2022 Created by AusNep IT
          </span>
        </Footer>
      </Layout>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    profile: state.profile,
  };
};

export default connect(mapStateToProps, {})(SiderDemo);
