import { useEffect, useState } from "react";
import "../Classes Management/ClassManagement.css";
import { Table, Segmented, Modal } from "antd";
import { connect, useDispatch } from "react-redux";
import { Descriptions } from "antd";
import { Pagination } from "antd";
import { Tooltip } from "antd";
import { MdDisabledVisible } from "react-icons/md";
import TableSkeleton from "../../Components/Table Skeleton/TableSkeleton";
import SiderDemo from "../Layout/SiderDemo";
import moment from "moment";
import { AiOutlineEye } from "react-icons/ai";

const Report = ({ ReportReason }) => {
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [activeData, setActiveData] = useState("");

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    dispatch({
      type: "GET_REPORT_REQUEST",
      payload: {
        page: page,
        pageSize: pageSize,
      },
    });
  }, [ReportReason.isReportChanged]);

  const columns = [
    {
      title: "Message",
      dataIndex: "reportMessage",

      width: 300,
      align: "center",
    },

    {
      title: "Reason",
      dataIndex: "reportReason",

      width: 250,
      align: "center",
    },
    {
      title: "Reported By",
      dataIndex: "reportedBy",

      width: 250,
      align: "center",
    },
    {
      title: "Reported To",
      dataIndex: "reportedTo",

      width: 250,
      align: "center",
    },
    {
      title: "Reported At",
      dataIndex: "reportedAt",

      width: 250,
      align: "center",
    },

    {
      title: "Actions",
      dataIndex: "actions",

      align: "center",
      width: 200,
    },
  ];

  const disableClient = (id) => {
    dispatch({
      type: "DISABLE_CLIENT_REQUEST",
      payload: {
        id: id,
      },
    });
  };

  const data =
    ReportReason.reports.data &&
    ReportReason.reports.data.map((report) => {
      return {
        reportReason: report.reportReason,
        reportMessage: report.reportMessage,
        reportedBy: report.reportedBy.fullName,
        reportedTo: report.reportedTo.fullName,

        reportedAt: moment(report.addedDate).format("YYYY-MM-DD"),

        actions: (
          <div className="action">
            {!report.reportedTo.disabled && (
              <Tooltip title="Disable Client">
                <MdDisabledVisible
                  className="icon"
                  onClick={() => {
                    disableClient(report.reportedTo.clientId);
                  }}
                />
              </Tooltip>
            )}

            <Tooltip title="See Details">
              <AiOutlineEye
                className="icon"
                onClick={() => {
                  setActiveData(report);
                  setIsModalVisible(true);
                }}
              />
            </Tooltip>
          </div>
        ),
      };
    });
  function onShowSizeChange(current, pageSize) {
    window.scrollTo(0, 0);
    dispatch({
      type: "GET_REPORT_REQUEST",
      payload: {
        page: current,
        pageSize: pageSize,
      },
    });
  }

  return (
    <>
      <div className="container">
        <Modal
          title="Report Details"
          visible={isModalVisible}
          footer={null}
          onCancel={handleCancel}
        >
          <div
            style={{
              background: "#F0F2F5",
              height: "40px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              paddingLeft: "20px",
              color: "black",

              fontSize: "17px",
            }}
          >
            Reported By
          </div>

          <Descriptions>
            <Descriptions.Item
              label="Full Name"
              style={{ padding: "10px" }}
              span={1.5}
            >
              {activeData.reportedBy?.fullName}
            </Descriptions.Item>
            <Descriptions.Item
              label="Phone No"
              style={{ padding: "10px" }}
              span={1.5}
            >
              {activeData.reportedBy?.phoneNumber}
            </Descriptions.Item>
            <Descriptions.Item
              label="Email"
              style={{ padding: "10px" }}
              span={1.5}
            >
              {activeData.reportedBy?.emailId}
            </Descriptions.Item>
            <Descriptions.Item
              label="Location"
              style={{ padding: "10px" }}
              span={1.5}
            >
              {activeData.reportedBy?.location}
            </Descriptions.Item>
            <Descriptions.Item
              span={1.5}
              label="User Type"
              style={{ padding: "10px" }}
            >
              {activeData.reportedBy?.userType}
            </Descriptions.Item>
            <Descriptions.Item
              label="Gender"
              style={{ padding: "10px" }}
              span={1.5}
            >
              {activeData.reportedBy?.gender}
            </Descriptions.Item>
          </Descriptions>
          <div
            style={{
              background: "#F0F2F5",
              height: "40px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              paddingLeft: "20px",
              color: "black",

              fontSize: "17px",
            }}
          >
            Reported To
          </div>

          <Descriptions>
            <Descriptions.Item
              label="Full Name"
              style={{ padding: "10px" }}
              span={1.5}
            >
              {activeData.reportedTo?.fullName}
            </Descriptions.Item>
            <Descriptions.Item
              label="Phone No"
              style={{ padding: "10px" }}
              span={1.5}
            >
              {activeData.reportedTo?.phoneNumber}
            </Descriptions.Item>
            <Descriptions.Item
              label="Email"
              style={{ padding: "10px" }}
              span={1.5}
            >
              {activeData.reportedTo?.emailId}
            </Descriptions.Item>
            <Descriptions.Item
              label="Location"
              style={{ padding: "10px" }}
              span={1.5}
            >
              {activeData.reportedTo?.location}
            </Descriptions.Item>
            <Descriptions.Item
              label="User Type"
              style={{ padding: "10px" }}
              span={1.5}
            >
              {activeData.reportedTo?.userType}
            </Descriptions.Item>
            <Descriptions.Item
              label="Gender"
              style={{ padding: "10px" }}
              span={1.5}
            >
              {activeData.reportedTo?.gender}
            </Descriptions.Item>
          </Descriptions>
        </Modal>
        <SiderDemo>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: "2rem",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {" "}
                <h2 className="title-course">Reports</h2>
                <Segmented style={{ height: "0px", fontSize: "11px" }} />
              </div>
            </div>

            {ReportReason.isLoading ? (
              <TableSkeleton />
            ) : (
              <Table
                pagination={false}
                columns={columns}
                dataSource={data}
                bordered
                className="table-course"
              />
            )}
            {ReportReason.reports.totalPage && (
              <Pagination
                style={{
                  marginTop: "1rem",
                  marginBottom: "1rem",
                  alignSelf: "flex-end",
                }}
                showSizeChanger
                onShowSizeChange={onShowSizeChange}
                defaultCurrent={1}
                onChange={onShowSizeChange}
                total={ReportReason.reports.totalPage * 10}
              />
            )}
          </div>
        </SiderDemo>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    ReportReason: state.ReportReason,
  };
};

export default connect(mapStateToProps, {})(Report);
