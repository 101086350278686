import { combineReducers } from "redux";
import login from "./login";
import register from "./register";
import profile from "./profile";
import Class from "./Class";
import Advertisement from "./Advertisement";
import LoyaltyPoints from "./LoyaltyPoints";
import Commission from "./Commission";
import CouponCode from "./CouponCode";
import Client from "./Client";
import MassNotification from "./MassNotification";
import TermsAndPrivacy from "./Terms And Privacy";
import Support from "./Support";
import Tutoring from "./Tutoring";
import Exam from "./Exam";
import ReportReason from "./ReportReason";
import Resource from "./Resource";
export const rootReducer = combineReducers({
  login: login,
  register: register,
  profile: profile,
  Class: Class,
  Advertisement: Advertisement,
  LoyaltyPoints: LoyaltyPoints,
  Commission: Commission,
  CouponCode: CouponCode,
  Client: Client,
  MassNotification: MassNotification,
  TermsAndPrivacy: TermsAndPrivacy,
  Support: Support,
  Tutoring: Tutoring,
  Exam: Exam,
  ReportReason: ReportReason,
  Resource: Resource,
});
