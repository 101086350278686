import { useEffect, useState } from "react";
import "../Classes Management/ClassManagement.css";
import { Table, Segmented, Modal, Form, Input } from "antd";
import { connect, useDispatch } from "react-redux";
import { Popconfirm, Drawer } from "antd";
import { Button } from "antd";
import { Tooltip } from "antd";
import TableSkeleton from "../../Components/Table Skeleton/TableSkeleton";
import SiderDemo from "../Layout/SiderDemo";
import moment from "moment";
import { AiOutlineEdit } from "react-icons/ai";
import { AiOutlineDelete } from "react-icons/ai";
import EditReportReason from "./EditReportReason";

const ReportReason = ({ profile, ReportReason }) => {
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const [activeData, setActiveData] = useState("");
  const [size, setSize] = useState("large");

  const onClose = () => {
    setVisible(false);
  };
  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const onFinish = (values) => {
    console.log("values", values);
    dispatch({
      type: "ADD_REPORT_REASON_REQUEST",
      payload: values,
    });
    setIsModalVisible(false);
    form.resetFields();
  };

  useEffect(() => {
    dispatch({
      type: "GET_REPORT_REASON_REQUEST",
      payload: {
        page: 1,
        pageSize: 10,
      },
    });
  }, [ReportReason.isReportChanged]);

  const columns = [
    {
      title: "Report Reason",
      dataIndex: "reportReason",

      width: 300,
      align: "center",
    },

    {
      title: "Added At",
      dataIndex: "addedDate",

      width: 250,
      align: "center",
    },

    {
      title: "Actions",
      dataIndex: "actions",

      align: "center",
      width: 200,
    },
  ];
  const deleteReportReasonHandler = (id) => {
    dispatch({
      type: "DELETE_REPORT_REASON_REQUEST",
      payload: {
        id: id,
      },
    });
  };

  const data =
    ReportReason.reportReasons &&
    ReportReason.reportReasons.map((report) => {
      return {
        reportReason: <p style={{ color: "red" }}>{report.reportReason}</p>,
        addedDate: moment(report.addedDate).format("YYYY-MM-DD"),

        actions: (
          <div className="action">
            <Tooltip title="Delete Report Reason">
              {" "}
              <Popconfirm
                title="Are you sure want to delete this report reason?"
                onConfirm={() =>
                  deleteReportReasonHandler(report.reportReasonId)
                }
              >
                <AiOutlineDelete className="icon" />
              </Popconfirm>
            </Tooltip>
            <Tooltip title="Edit Report Reason">
              <AiOutlineEdit
                className="icon"
                onClick={() => {
                  setVisible(true);

                  setActiveData(report);
                }}
              />
            </Tooltip>
          </div>
        ),
      };
    });
  // function onShowSizeChange(current, pageSize) {
  //   window.scrollTo(0, 0);
  //   dispatch({
  //     type: "GET_ADMIN_REQUEST",
  //     payload: {
  //       page: current,
  //       pageSize: pageSize,
  //     },
  //   });
  // }

  return (
    <>
      <div className="container">
        <Modal
          title="New Report Reason"
          visible={isModalVisible}
          footer={null}
          onCancel={handleCancel}
        >
          <Form name="form" form={form} onFinish={onFinish}>
            <Form.Item
              label="Report Reason"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                width: "100%",
              }}
              name="reportReason"
              rules={[
                {
                  required: true,
                  message: "Please input report reason!",
                },
              ]}
            >
              <Input placeholder="Report Reason" />
            </Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ width: "100%" }}
              className="login-form-button"
            >
              Add
            </Button>
          </Form>
        </Modal>
        <SiderDemo>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: "2rem",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {" "}
                <h2 className="title-course">Report Reasons</h2>
                <Button
                  type="primary"
                  style={{ background: "#FF4D4F" }}
                  danger
                  onClick={showModal}
                >
                  Add
                </Button>
                <Segmented style={{ height: "0px", fontSize: "11px" }} />
              </div>
            </div>

            {ReportReason.isLoading ? (
              <TableSkeleton />
            ) : (
              <Table
                pagination={false}
                columns={columns}
                dataSource={data}
                bordered
                className="table-course"
              />
            )}
            {/* {profile.admins.totalPage && (
              <Pagination
                style={{
                  marginTop: "1rem",
                  marginBottom: "1rem",
                  alignSelf: "flex-end",
                }}
                showSizeChanger
                onShowSizeChange={onShowSizeChange}
                defaultCurrent={1}
                onChange={onShowSizeChange}
                total={profile.admins.totalPage * 10}
              />
            )} */}
            <Drawer
              title={<span style={{}}>Edit Report Reason</span>}
              placement="right"
              width={"85%"}
              size={size}
              onClose={onClose}
              visible={visible}
            >
              <EditReportReason
                onClose={onClose}
                data={activeData}
                ReportReason={ReportReason}
              />
            </Drawer>
          </div>
        </SiderDemo>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    ReportReason: state.ReportReason,
  };
};

export default connect(mapStateToProps, {})(ReportReason);
